import { Query } from "react-apollo";
import React from "react";
import Spinner from "react-spinkit";
import styled from "styled-components";

import translateErrorMessage from "utils/translateErrorMessage";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  padding: 20px;

  height: 100%;
`;

export const withQuery = injectedProps => Component => {
  const queryWrapper = props => {
    const {
      noLoader,
      onError,
      ...innerProps
    } = typeof injectedProps === "function"
      ? injectedProps(props)
      : injectedProps;

    return (
      <Query {...innerProps}>
        {({
          loading,
          error,
          data,
          refetch,
          fetchMore,
          subscribeToMore
        }) => {
          if (loading) {
            return !noLoader && (
              <SpinnerContainer>
                <Spinner name="line-spin-fade-loader" color="green" />
              </SpinnerContainer>
            );
          }
          if (error) {
            props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
            if (onError) {
              onError();
            }
            return null;
          }
          if (!data) {
            return (
              <Component
                refetch={refetch}
                fetchMore={fetchMore}
                subscribeToMore={subscribeToMore}
                {...props}
              />
            );
          }

          return (
            <Component
              {...data}
              refetch={refetch}
              fetchMore={fetchMore}
              subscribeToMore={subscribeToMore}
              {...props}
            />
          );
        }}
      </Query>
    );
  };

  return withSnackbarConsumer(queryWrapper);
};
