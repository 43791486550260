import React, { Component } from "react";
import { compose } from "react-apollo";
import Table from "components/Table/Table";
import { withQuery } from "containers/withQuery";
import { format } from "date-fns";
import HUMIDITY_MEASUREMENTS_QUERY from "../graphql/queries/list/humidityMeasurementsQuery.js";
import INVENTORY_ITEMS_QUERY from "../graphql/queries/list/inventoryItemsQuery.js";

class UmidityTable extends Component {
  render() {
    const inventoryItems = this.props.inventoryItems.map(item => (
      {
        label: item.name,
        key: item.id,
        valueFormatter: (value => `${(parseFloat(value) * 100).toFixed(1)}%`)
      }));
    const headers = [
      {
        label: "Data",
        key: "time",
        valueFormatter: (value => format(value, "DD/MM/YYYY"))
      },
      ...inventoryItems,
      {
        label: "Responsável",
        key: "responsible"
      }
    ];

    const items = this.props.humidityMeasurementsTable.map((row, index) => ({
      ...row,
      id: index,
    }));

    return (
      <Table
        headers={headers}
        matchHeaders
        items={items}
      />
    );
  }
}

export default compose(
  withQuery(({ fromDateTime, toDateTime }) => (
    {
      query: HUMIDITY_MEASUREMENTS_QUERY,
      variables: {
        fromDateTime,
        toDateTime,
      }
    }
  )),
  withQuery({
    query: INVENTORY_ITEMS_QUERY,
  }),
)(UmidityTable);
