import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { AppContainer } from "react-hot-loader";
import ErrorBoundary from "./ErrorBoundary";
import App from "./src/App";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: "https://d1c461e176db4eb084bce74c4c57e988@sentry.jungsoft.com.br/19",
  });

  Sentry.configureScope(scope => {
    scope.setTag("app", "frontend");
  });
}

const render = Component => {
  ReactDOM.render(
    <ErrorBoundary>
      <AppContainer warnings={false}>
        <Component />
      </AppContainer>
    </ErrorBoundary>,
    document.getElementById("root")
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./src/App", () => {
    render(App);
  });
}
