import React from "react";
import { useGetQRCode } from "api/controller-configs";
import styled from "styled-components";

const QrCodeCard = styled.div`
  margin: none;
  padding: 0;
  background-color: transparent;
  border: none;
  align-items: center;
  text-align: center;

  img {
    width: 100%;
  }
  
  p {
    margin-top: 10px;
    font-size: 14px;
    color: black;
    font-weight: bold;
  }
`;

const AppQrCode = () => {
  const { qrCode } = useGetQRCode();
  const {
    qrCodeEncoded,
    url,
  } = qrCode;

  return (
    <QrCodeCard>
      { url && (
        <React.Fragment>
          <img src={`data:image/png;base64,${qrCodeEncoded}`} alt="QR Code" />
          <a href={url} target="_blank" rel="noreferrer">
            <p>APP AGREGADOS</p>
          </a>
        </React.Fragment>
      )}
    </QrCodeCard>
  );
};

export default AppQrCode;
