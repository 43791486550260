import React, {
  useState,
  useCallback,
} from "react";
import {
  Row,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import { useDebounce } from "use-debounce";
import Spinner from "react-spinkit";

import { httpClient } from "settings/apolloClient";
import QueryHandlerWrapper from "components/QueryHandlerWrapper";

import ShipmentReportResult from "./ShipmentReportResult";
import {
  Title,
  SearchHeader,
  SearchCard,
  LoaderContainer,
} from "./ReportComponents";
import CONCRETE_SHIPMENT_REPORT_QUERY from "./graphql/concreteShipmentReportQuery";

const ShipmentReport = () => {
  const [code, setCode] = useState("");
  const [debouncedCode, { pending }] = useDebounce(code, 1000);

  const isPending = pending();

  const handleChange = useCallback(({ target: { value } }) => {
    setCode(value);
  }, []);

  const {
    data,
    loading,
    error,
  } = useQuery(CONCRETE_SHIPMENT_REPORT_QUERY, {
    variables: {
      code: debouncedCode,
    },
    client: httpClient,
    skip: !debouncedCode,
  });

  return (
    <div className="content">
      <Container>
        <Row>
          <Col>
            <SearchCard className="mb-5">
              <SearchHeader>
                <Title>Código da carga:</Title>

                <InputGroup
                  className="no-border"
                  style={{ marginBottom: 0, marginRight: 10 }}
                >
                  <Input
                    value={code}
                    onChange={handleChange}
                    type="text"
                  />

                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="nc-icon nc-zoom-split" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>

                <LoaderContainer>
                  {
                    isPending && (
                      <React.Fragment>
                        <Spinner
                          name="chasing-dots"
                          color="green"
                          className="debounce-search"
                        />

                        <p className="ml-3">carregando...</p>
                      </React.Fragment>
                    )
                  }
                </LoaderContainer>

              </SearchHeader>
            </SearchCard>
          </Col>
        </Row>
      </Container>

      <QueryHandlerWrapper
        error={error}
        loading={loading}
      >
        <ShipmentReportResult data={data} />
      </QueryHandlerWrapper>
    </div>
  );
};

export default ShipmentReport;
