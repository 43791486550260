import React from "react";
import { compose, graphql } from "react-apollo";

import translateErrorMessage from "utils/translateErrorMessage";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { INVENTORY_ITEMS_QUERY } from "../generalRegisterQueries";
import { CREATE_INVENTORY_ITEM_MUTATION } from "../generalRegisterMutations";
import InventoryItemModal from "./InventoryItemModal";

class InventoryItemCreate extends React.Component {
  onSave = inventoryItem => {
    this.props.createInventoryItem({
      variables: {
        params: inventoryItem
      },
      refetchQueries: [{
        query: INVENTORY_ITEMS_QUERY
      }]
    })
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Item de Inventário criado.");
        this.props.toggleModal();
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const { isOpen } = this.props;
    return (
      <InventoryItemModal
        toggleModal={this.props.toggleModal}
        isOpen={isOpen}
        title="Cadastrar item de inventário"
        onSave={this.onSave}
      />
    );
  }
}

export default compose(
  graphql(CREATE_INVENTORY_ITEM_MUTATION, { name: "createInventoryItem", }),
  withSnackbarConsumer,
)(InventoryItemCreate);
