import { getIn } from "utils/nesting";

const sum = (materials, type) => materials
  .filter((material) => {
    const materialType = getIn(["name", "materialType"], material);

    return materialType === type;
  })
  .reduce(
    (result, material) => {
      const weight = getIn(["weight", "label"], material);

      return parseFloat(result) + parseFloat(weight);
    },
    0
  );

const convertResultToNaturalNumber = (result, precision) => {
  const parsedResult = parseFloat(result);

  return (Number.isNaN(parsedResult) || parsedResult === Infinity) ? 0 : parsedResult.toFixed(precision);
};

/**
 * Calculate the water/cement factor.
 *
 * SUM(water) / SUM(cement)
 */
export const calculateWcFactor = (materials) => {
  const water = sum(materials, "WATER") || 0;
  const cement = sum(materials, "CEMENT") || 0;

  const result = water / cement;
  return convertResultToNaturalNumber(result, 3);
};

/**
 * Calculate the mortar factor. (%)
 *
 * 100 * [(SUM(sand) + SUM(cement)) / (SUM(sand) + SUM(cement) + SUM(gravel))]
 */
export const calculateMortarFactor = (materials) => {
  const sand = sum(materials, "SAND") || 0;
  const cement = sum(materials, "CEMENT") || 0;
  const gravel = sum(materials, "GRAVEL") || 0;

  if (sand === 0 || cement === 0 || gravel === 0) return 0;

  const result = 100 * ((sand + cement) / (sand + cement + gravel));
  return convertResultToNaturalNumber(result, 2);
};
