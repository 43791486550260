import React from "react";
import { compose, graphql } from "react-apollo";
import { withQuery } from "containers/withQuery";

import translateErrorMessage from "utils/translateErrorMessage";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { SUPPLIER_QUERY, SUPPLIERS_QUERY } from "../generalRegisterQueries";
import SupplierModal from "./SupplierModal";
import { UPDATE_SUPPLIER_MUTATION } from "../generalRegisterMutations";

class SupplierEdit extends React.Component {
  onSave = ({ name, phone, email }) => {
    this.props.updateSupplier({
      variables: {
        id: this.props.id,
        params: {
          name,
          phone,
          email,
        }
      },
      refetchQueries: [{
        query: SUPPLIERS_QUERY
      }]
    })
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Fornecedor atualizado.");
        this.props.onFinishEdit();
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const { supplier, id } = this.props;
    return (
      <SupplierModal
        toggleModal={this.props.onFinishEdit}
        isOpen={id !== null}
        onSave={this.onSave}
        title="Editar fornecedor"
        {...supplier}
      />
    );
  }
}

export default compose(
  withQuery(({ id }) => ({
    query: SUPPLIER_QUERY,
    variables: {
      id,
    },
    noLoader: true,
  })),
  graphql(UPDATE_SUPPLIER_MUTATION, { name: "updateSupplier", }),
  withSnackbarConsumer,
)(SupplierEdit);
