/**
 * This files describes possibles values for:
 *
 * ConcreteShipment {
 *   shipmentType
 * }
 */

/**
 * When a concrete shipment is created remotely
 * using integration endpoint.
 */
export const API = "API";

/**
 * When a concrete shipment is created internally
 * using application interface.
 */
export const INTERNAL = "INTERNAL";
