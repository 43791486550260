/* eslint no-restricted-globals: 0 */
// For isNaN usage
import React from "react";
import styled from "styled-components";
import { Input as BSInput } from "reactstrap";

const Property = styled.h2`
  font-family: "Rubik";
  font-size: 28px;
  color: #8798AD;
  font-weight: 500;
  margin-top: 14px;
`;

const Input = styled(BSInput)`
  &.form-control {
    font-family: "Rubik";
    font-size: 28px;
    color: #8798AD;
    font-weight: 500;
    margin-top: 14px;
  }
`;

const Title = styled.p`
  font-family: "Rubik";
  font-size: 15px;
  color: #283745;
  font-weight: 500;
`;

const Container = styled.div`
  background-color: #F6F8FF;
  padding-top: 24px;
  padding-bottom: 14px;
  padding-left: 10px;
  padding-right: 10px;
  width: 33%;
  text-align: center;
`;

const CostBox = ({
  title, value, keyValue, isEditable, onChange, formatter
}) => (
  <Container>
    <Title>{title}</Title>
    {isEditable ? (
      <Input
        value={value}
        onChange={e => {
          const newValue = e.target.value;
          if (!isNaN(newValue) && newValue >= 0) {
            onChange(keyValue, newValue);
          }
        }
        }
      />
    ) : (
      <Property>
        {formatter ? formatter(value) : value}
      </Property>
    )}
  </Container>
);

export default CostBox;
