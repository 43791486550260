import gql from "graphql-tag";

export default gql`
  subscription SubscribeToItemShipmentQuantity($concreteShipmentId: Int!, $inventoryItemId: Int!) {
    subscribeToItemShipmentQuantity(
      inventoryItemId: $inventoryItemId,
      concreteShipmentId: $concreteShipmentId
    )
  }
`;
