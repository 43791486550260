import React from "react";
import { Card } from "reactstrap";
import FamiliesTable from "./FamiliesTable";
import FormulasTable from "./FormulasTable";

class FamiliesAndFormulas extends React.Component {
  state = {
    concreteFamiliesSelected: []
  }

  onFamilySelected = (
    familyId,
    familyName,
    selected
  ) => this.setState(({
    concreteFamiliesSelected
  }) => ({
    concreteFamiliesSelected: selected
      ? concreteFamiliesSelected.concat({ id: familyId, name: familyName })
      : concreteFamiliesSelected.filter(({ id }) => id !== familyId)
  }))

  render() {
    const { concreteFamiliesSelected } = this.state;
    return (
      <div className="content">
        <Card>
          <FamiliesTable onFamilySelected={this.onFamilySelected} />
        </Card>
        {concreteFamiliesSelected.length > 0 && (
          <Card style={{ marginTop: "35px" }}>
            <FormulasTable concreteFamiliesSelected={concreteFamiliesSelected} />
          </Card>
        )}
      </div>
    );
  }
}

export default FamiliesAndFormulas;
