import React, { Component } from "react";
import Table from "components/Table/Table";
import { withQuery } from "containers/withQuery";
import PencilButton from "components/ActionButtons/PencilButton";
import { SLUMPS_QUERY } from "../generalRegisterQueries";
import SlumpEdit from "./SlumpEdit";

const headers = [
  {
    label: "Nominal", key: "nominal",
  },
  {
    label: "Desvio", key: "deviation",
  },
  {
    label: "ABNT", key: "abntValue",
  },
  {
    label: "Especificação", key: "specification",
  },
  {
    label: "", key: "action",
  },
];

class SlumpsTable extends Component {
  state = {
    currentEditId: null,
  }

  onFinishEdit = () => this.setState({ currentEditId: null });

  render() {
    const { currentEditId } = this.state;
    return (
      <React.Fragment>
        <Table
          maxHeight={400}
          headers={headers}
          matchHeaders
          items={this.props.slumps.map(slump => ({
            ...slump,
            action: (
              <PencilButton
                onPencilEditClick={() => this.setState({
                  currentEditId: slump.id
                })}
              />
            )
          }))}
        />
        {currentEditId !== null && (
          <SlumpEdit id={currentEditId} onFinishEdit={this.onFinishEdit} />
        )}
      </React.Fragment>
    );
  }
}

export default withQuery({ query: SLUMPS_QUERY })(SlumpsTable);
