import React from "react";
import styled from "styled-components";

const Tags = styled.div`
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const TAGS_APPEARING = 5;

class TagsContainer extends React.Component {
  state = {
    step: 0,
  }

  onChangeStep = (number) => {
    const step = this.state.step + number;
    const totalTags = this.props.children.length;
    if (step + TAGS_APPEARING <= totalTags && step >= 0) {
      this.setState({ step });
    }
  }

  render() {
    const { step } = this.state;
    const { children } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", }}>
        <button type="button" style={{ border: "none" }} onClick={() => this.onChangeStep(-1)}>
          <i style={{ color: "#2C7DCE" }} className="fas fa-chevron-left" />
        </button>
        <Tags>
          {children && children.slice(step, step + TAGS_APPEARING)}
        </Tags>
        <button type="button" style={{ border: "none" }} onClick={() => this.onChangeStep(1)}>
          <i style={{ color: "#2C7DCE" }} className="fas fa-chevron-right" />
        </button>
      </div>
    );
  }
}

export default TagsContainer;
