import React from "react";
import {
  Input,
  Col,
  Row,
  Container,
} from "reactstrap";
import styled from "styled-components";

const FormContainer = styled(Container)`
  padding: 30px !important;
`;
const Text = styled.p`
  color: #283745;
  font-family: "Rubik";
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
`;

class Danfe extends React.Component {
  state = {}

  render() {
    const { showCheckbox } = this.props;
    return (
      <FormContainer>
        <Row>
          <Col md={6}>
            <Text>Número do lacre</Text>
            <Input
              placeholder="Digite..."
              type="text"
              onChange={this.props.onChangeSeal}
              value={this.props.sealNumber}
            />
          </Col>

          <Col md={6}>
            <Text>Código do carregamento</Text>
            <Input
              placeholder="Digite..."
              type="text"
              onChange={this.props.onChangeCode}
              value={this.props.code}
            />
            </Col>
        </Row>
      </FormContainer>
    );
  }
}

export default Danfe;
