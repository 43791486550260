import React from "react";
import { SimpleDataContainer, InfoContainer, InfoText } from "./confirmModalComponents";

const ConstructionData = ({
  client,
  contract: {
    code,
    identification,
    address,
    number,
    neighborhood,
    city,
    state,
    cep
  }
}) => (
  <div style={{ padding: "30px", }}>
    <SimpleDataContainer>
      <InfoContainer>
        <h3>Cliente</h3>
        <InfoText>
          {`${client.id} - ${client.name}`}
        </InfoText>
      </InfoContainer>
      <InfoContainer>
        <h3>Endereço</h3>
        <InfoText>
          {`${address}, ${number} - ${neighborhood}`}
        </InfoText>
        <InfoText>
          {`${city} - ${state}, CEP: ${cep}`}
        </InfoText>
      </InfoContainer>
    </SimpleDataContainer>
    <SimpleDataContainer style={{ marginTop: "22px" }}>
      <InfoContainer>
        <h3>Ordem de Serviço</h3>
        <InfoText>
          {`${"ID"} - ${"594523"}`}
        </InfoText>
      </InfoContainer>
      <InfoContainer>
        <h3>Contrato</h3>
        <InfoText>
          {`${code} - ${identification}`}
        </InfoText>
      </InfoContainer>
    </SimpleDataContainer>
  </div>
);

export default ConstructionData;
