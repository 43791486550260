import React from "react";
import { Button } from "reactstrap";

export default props => (
  <div style={{ width: "100%" }}>
    <Button
      style={{ width: "100%", margin: 0 }}
      color="info"
      data-dismiss="modal"
      type="button"
      onClick={props.onConfirm}
    >
      Salvar
    </Button>
  </div>
);
