import gql from "graphql-tag";

export default gql`
  subscription {
    subscribeToManualOperation {
      id
      concreteShipment {
        id
      }
    }
  }
`;
