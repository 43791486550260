import React from "react";
import { compose, graphql } from "react-apollo";

import translateErrorMessage from "utils/translateErrorMessage";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { SUPPLIERS_QUERY } from "../generalRegisterQueries";
import SupplierModal from "./SupplierModal";
import { CREATE_SUPPLIER_MUTATION } from "../generalRegisterMutations";

class SupplierCreate extends React.Component {
  onSave = ({ name, phone, email }) => {
    this.props.createSupplier({
      variables: {
        params: {
          name,
          phone,
          email,
        }
      },
      refetchQueries: [{
        query: SUPPLIERS_QUERY
      }]
    })
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Fornecedor criado.");
        this.props.toggleModal();
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const { isOpen } = this.props;
    return (
      <SupplierModal
        toggleModal={this.props.toggleModal}
        isOpen={isOpen}
        title="Cadastrar fornecedor"
        onSave={this.onSave}
      />
    );
  }
}

export default compose(
  graphql(CREATE_SUPPLIER_MUTATION, { name: "createSupplier", }),
  withSnackbarConsumer,
)(SupplierCreate);
