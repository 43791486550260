import styled from "styled-components";

export const TableContainer = styled.div`
  overflow: scroll;
  width: 100%;
  max-height: ${props => props.maxHeight ? `${props.maxHeight}px` : "800px"};
  border-bottom: 1px solid #F6F8FF;
  border-radius: 15px;
`;

export const TableEditContainer = styled.div`
  border-bottom: 1px solid #F6F8FF;
  border-radius: 15px;
`;

export const Table = styled.table`
  width: 100%;
`;

export const Header = styled.th`
  padding-top: 22px;
  padding-bottom: 22px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: ${props => props.center ? "center" : "left"};
`;

export const Row = styled.tr`
  &:nth-child(odd) {
    background-color: #F6F8FF;
  }
`;

export const Data = styled.td`
  padding: 14px 16px 14px 16px;
  font-size: 13px;
  color: #8798AD;
  text-align: ${props => props.center ? "center" : "left"};
`;
