import gql from "graphql-tag";

export const UPDATE_CONCRETE_FORMULA_MUTATION = gql`
  mutation($id: Int!, $params: ConcreteFormulaParams!) {
    updateConcreteFormula(id: $id, params: $params) {
      id
    }
  }
`;

export const CREATE_CONCRETE_FORMULA_MUTATION = gql`
  mutation($params: ConcreteFormulaParams!) {
    createConcreteFormula(params: $params) {
      id
    }
  }
`;
