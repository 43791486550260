import React, { Component } from "react";
import { compose, graphql } from "react-apollo";
import TruckCard from "components/TruckCard/TruckCard";
import { withTruckStateConsumer, TruckState } from "contexts/TruckContext/TruckStateContext.jsx";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";
import EQUIPMENT_READY_MUTATION from "../graphql/mutations/equipmentReadyMutation.js";

class Truck extends Component {
  componentDidMount() {
    const { loadingShipment, truckStateContext } = this.props;

    if (loadingShipment) {
      truckStateContext.setTruckState(loadingShipment.status);
    }
  }

  equipmentReadyMutation = () => {
    const {
      truckStateContext,
      equipmentReadyMutation,
      loadingShipment,
      snackbarContext,
    } = this.props;

    if (loadingShipment && truckStateContext.truckState === TruckState.WAITING) {
      equipmentReadyMutation()
        .then(() => truckStateContext.setTruckState(TruckState.LOADING))
        .catch(error => {
          snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
        });
    }
  }

  render() {
    const { truckStateContext } = this.props;

    return (
      <div
        onClick={this.equipmentReadyMutation}
        style={{ cursor: "pointer " }}
      >
        <TruckCard status={truckStateContext.truckState} />
      </div>
    );
  }
}

export default compose(
  withTruckStateConsumer,
  withSnackbarConsumer,
  graphql(EQUIPMENT_READY_MUTATION, { name: "equipmentReadyMutation", })
)(Truck);
