import React from "react";
import styled from "styled-components";
import ReactSVG from "react-svg";
import DeleteButtonIcon from "assets/img/blue-delete-button.svg";

const DeleteButton = styled.button`
  border: none;
  pointer-events: auto;
  cursor: pointer;
  background-color: transparent;
  &:focus {
    outline: 0;
  }
`;

export default ({ onClick }) => (
  <DeleteButton onClick={onClick} title="Excluir">
    <ReactSVG src={DeleteButtonIcon} />
  </DeleteButton>
);
