import styled from "styled-components";
import { Button, DropdownToggle } from "reactstrap";

export const NavbarButton = styled(Button)`
  margin: 0px !important;

  @media screen and (max-width: ${({ breakpoint }) => breakpoint}px) {
    margin-top: 10px!important;
  }
`;

export const NavbarDropdownToggle = styled(DropdownToggle)`
  margin: 0px !important;

  @media screen and (max-width: 380px) {
    margin-top: 10px!important;
  }
`;
