import React from "react";
import PropTypes from "prop-types";
import { ConcreteFormulaIdsProvider } from "./ConcreteFormulaIdsContext";

class ConcreteFormulaIdsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contextValue: {
        refetch: null,
        setRefetch: this.setRefetch,
        resetState: this.resetState,
      },
    };
  }

  setRefetch = (refetch) => {
    this.setState(prevState => ({
      contextValue: {
        ...prevState.contextValue,
        refetch,
      },
    }));
  };

  resetState = () => {
    this.setState(prevState => ({
      contextValue: {
        ...prevState.contextValue,
        refetch: null,
      },
    }));
  };

  render() {
    const { contextValue } = this.state;
    return (
      <ConcreteFormulaIdsProvider
        value={contextValue}
      >
        {this.props.children}
      </ConcreteFormulaIdsProvider>
    );
  }
}

ConcreteFormulaIdsContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ConcreteFormulaIdsContainer;
