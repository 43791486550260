import React, { Component } from "react";
import {
  Row,
  Col,
  Button
} from "reactstrap";
import { compose, graphql } from "react-apollo";
import styled from "styled-components";
import { firstDayOfMonth } from "utils/chartDates.js";
import { withQuery } from "containers/withQuery";
import SANDS_QUERY from "./graphql/queries/list/sandsQuery";
import HUMIDITY_MEASUREMENTS_QUERY from "./graphql/queries/list/humidityMeasurementsQuery.js";
import HUMIDITY_MEASUREMENT_MUTATION from "./graphql/mutations/create/createHumidityMeasurementMutation.js";
import INVENTORY_ITEMS_QUERY from "./graphql/queries/list/inventoryItemsQuery.js";
import SandSlider from "./SandSlider";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const ButtonSave = styled(Button)`
  width: 150px;
`;

class SlidersContainer extends Component {
  state = {
    inventoryItems: this.props.inventoryItems.map(item => ({
      inventoryItemId: item.id,
      name: item.name,
      value: 0,
    })),
  }

  onChangeSlider = (id, value) => {
    this.setState(prevState => (
      {
        inventoryItems: prevState.inventoryItems.map(
          item => ((item.inventoryItemId === id) ? { ...item, value } : item)
        )
      }
    ));
  }

  createHumidityMeasurement = () => {
    this.props.createHumidityMeasurement({
      variables: {
        paramsList: this.state.inventoryItems.map(item => ({
          value: (item.value / 100),
          time: (new Date()).toISOString(),
          inventoryItemId: item.inventoryItemId
        }))
      },
      refetchQueries: [
        {
          query: SANDS_QUERY,
          variables: {
            fromDateTime: firstDayOfMonth().toISOString(),
            toDateTime: this.props.toDateTime,
          }
        },
        {
          query: HUMIDITY_MEASUREMENTS_QUERY,
          variables: {
            fromDateTime: firstDayOfMonth().toISOString(),
            toDateTime: this.props.toDateTime,
          }
        }
      ]
    });
  }

  render() {
    const { inventoryItems } = this.state;

    return (
      <Row>
        <Col xs={12} md={10}>
          <Row>
            {
              inventoryItems.map(item => (
                <Col md={6} key={item.inventoryItemId}>
                  <SandSlider
                    value={item.value}
                    onChangeSlider={(value) => this.onChangeSlider(item.inventoryItemId, value)}
                    title={`Umidade ${item.name}`}
                    sandClass="slider-container"
                    sandName="humidityThinSand"
                  />
                </Col>
              ))
            }
          </Row>
        </Col>

        <Col xs={12} md={2}>
          <ButtonContainer>
            <ButtonSave
              color="info"
              onClick={this.createHumidityMeasurement}
            >
              Salvar
            </ButtonSave>
          </ButtonContainer>
        </Col>
      </Row>
    );
  }
}

export default compose(
  withQuery({
    query: INVENTORY_ITEMS_QUERY,
  }),
  graphql(HUMIDITY_MEASUREMENT_MUTATION, { name: "createHumidityMeasurement", })
)(SlidersContainer);
