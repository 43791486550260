import gql from "graphql-tag";

export const INVENTORY_AND_SUPPLIERS_QUERY = gql`
  query {
    inventoryItems {
      id
      name
      unit
      materialType
    }
    suppliers {
      id
      name
    }
  }
`;

export const LOCATIONS_QUERY = gql`
  query StockEntryFields($inventoryItemId: Int){
    availableInventoryLocations(inventoryItemId: $inventoryItemId) {
      id
      name
    }
  }
`;
