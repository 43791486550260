import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import "./ShipmentCreateModal.css";
import EditShipmentModal from "components/ConfirmModal/EditShipmentModal";
import { ControlMenuModal } from "./ControlMenuModal";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  top: 50px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export function ShipmentEditModal({
  refetchSequence,
  selectedCode,
  selectedFormula,
  selectedId,
  selectedQuantity,
  selectedSealNumber,
  setEditShipmentModalOpen,
}) {
  const [formula, setFormula] = useState(selectedFormula);
  const [quantity, setQuantity] = useState(selectedQuantity);
  const [disableStartShipment, setDisableStartShipment] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const isEdit = true;

  const toggleConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setEditShipmentModalOpen(false);
  };

  useEffect(() => {
    if (formula && quantity) {
      setDisableStartShipment(false);
    } else {
      setDisableStartShipment(true);
    }
  }, [formula, quantity]);

  return (
    <ModalContainer>
      {
        !isConfirmModalOpen && (
          <ControlMenuModal
            buttonTitle="EDITAR CARREGAMENTO"
            disableStartShipment={disableStartShipment}
            formula={formula}
            isEdit={isEdit}
            quantity={quantity}
            setModalOpen={setEditShipmentModalOpen}
            setFormula={setFormula}
            setIsConfirmModalOpen={setIsConfirmModalOpen}
            setQuantity={setQuantity}
          />
        )
      }
      {
        isConfirmModalOpen && (
          <EditShipmentModal
            concreteFormulaId={formula.value}
            formulaCode={formula.code}
            isOpen={isConfirmModalOpen}
            onRefetch={refetchSequence}
            quantityValue={quantity}
            selectedCode={selectedCode}
            selectedId={selectedId}
            selectedSealNumber={selectedSealNumber}
            toggleModal={toggleConfirmModal}
          />
        )
      }
    </ModalContainer>
  );
};
