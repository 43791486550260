import gql from "graphql-tag";

export default gql`
{
  inventoryItems(materialType: SAND) {
    id
    name
    lastHumidityMeasurement {
      id
      value
      time
    }
  }
}
`;
