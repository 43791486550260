import React from "react";
import styled from "styled-components";
import Input from "components/Input/Input";
import Modal from "components/Modal/Modal";
import Field from "components/Field/Field";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import Footer from "../modalComponents/Footer";
import { typeDescription } from "./materialTypes";
import Select from "./MaterialsSelect";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
`;

class InventoryItemModal extends React.Component {
  constructor(props) {
    super(props);
    const {
      code,
      name,
      materialType,
      ncm,
      partNumber,
      qrCode,
      unit,
      comments,
    } = this.props;

    this.state = {
      code: code || "",
      name: name || "",
      materialType: materialType
        ? { value: materialType, label: typeDescription[materialType] }
        : "",
      ncm: ncm || "",
      partNumber: partNumber || "",
      qrCode: qrCode || "",
      unit: unit || "",
      comments: comments || "",
    };
  }

  onSaveEntry = () => {
    const {
      name, materialType: { value }, unit, ...rest
    } = this.state;
    if (name === "" || !value || unit === "") {
      this.props.snackbarContext.setNotificationTimeOut(Color.danger, "O campo nome, tipo e unidade são obrigatórios");
      return;
    }

    this.props.onSave({
      name,
      materialType: value,
      unit,
      ...rest,
    });
  }

  onChange = (key, value) => this.setState({ [key]: value })

  render() {
    const { isOpen, toggleModal } = this.props;
    const {
      code,
      name,
      materialType,
      ncm,
      partNumber,
      qrCode,
      unit,
      comments
    } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggleModal={toggleModal}
        size="md"
        title={this.props.title}
        footer={<Footer onConfirm={this.onSaveEntry} />}
      >
        <Row>
          <Field title="Código" width="100%">
            <Input
              name="code"
              style={{ minHeight: "40px" }}
              value={code}
              onChange={this.onChange}
            />
          </Field>
        </Row>
        <Row>
          <Field title="Nome" width="48%">
            <Input
              name="name"
              style={{ minHeight: "40px" }}
              value={name}
              onChange={this.onChange}
            />
          </Field>
          <Field title="Tipo" width="48%">
            <Select
              name="materialType"
              placeholder="Escolha..."
              value={materialType}
              onChange={this.onChange}
            />
          </Field>
        </Row>
        <Row>
          <Field title="NCM" width="48%">
            <Input
              name="ncm"
              style={{ minHeight: "40px" }}
              value={ncm}
              onChange={this.onChange}
            />
          </Field>
          <Field title="Número da Parte" width="48%">
            <Input
              name="partNumber"
              style={{ minHeight: "40px" }}
              value={partNumber}
              onChange={this.onChange}
            />
          </Field>
        </Row>
        <Row>
          <Field title="QRCode" width="48%">
            <Input
              name="qrCode"
              style={{ minHeight: "40px" }}
              value={qrCode}
              onChange={this.onChange}
            />
          </Field>
          <Field title="Unidade" width="48%">
            <Input
              name="unit"
              style={{ minHeight: "40px" }}
              value={unit}
              onChange={this.onChange}
            />
          </Field>
        </Row>
        <Row>
          <Field title="Comentários" width="100%">
            <Input
              name="comments"
              style={{ minHeight: "40px" }}
              value={comments}
              onChange={this.onChange}
            />
          </Field>
        </Row>
      </Modal>
    );
  }
}

export default withSnackbarConsumer(InventoryItemModal);
