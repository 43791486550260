import gql from "graphql-tag";

export const SLUMPS_QUERY = gql`
  query {
    slumps {
      id
      nominal
      deviation
      abntValue
      specification
      real
    }
  }
`;

export const SLUMP_QUERY = gql`
  query($id: Int!) {
    slump(id: $id) {
      id
      nominal
      deviation
      abntValue
      specification
      real
    }
  }
`;

export const CEMENT_TYPES_QUERY = gql`
  query {
    cementTypes {
      id
      name
    }
  }
`;

export const CEMENT_TYPE_QUERY = gql`
  query($id: Int!) {
    cementType(id: $id) {
      id
      name
    }
  }
`;

export const SUPPLIERS_QUERY = gql`
  query {
    suppliers {
      id
      name
      phone
      email
    }
  }
`;

export const LOCATION_QUERY = gql`
  query($id: Int!) {
    inventoryLocation(id: $id) {
      id
      name
      code
      capacity
    }
  }
`;

export const LOCATIONS_QUERY = gql`
query {
  inventoryLocations {
    id
    name
    capacity
  }
}
`;

export const SUPPLIER_QUERY = gql`
  query($id: Int!) {
    supplier(id: $id) {
      id
      name
      phone
      email
    }
  }
`;

export const INVENTORY_ITEMS_QUERY = gql`
  query {
    inventoryItems {
      id
      name
      materialType
      ncm
      partNumber
      qrCode
      unit
      avgPrice
      comments
    }
  }
`;

export const INVENTORY_ITEM_QUERY = gql`
  query($id: Int!) {
    inventoryItem(id: $id) {
      id
      name
      code
      ncm
      materialType
      partNumber
      qrCode
      unit
      comments
    }
  }
`;

export const MATERIAL_TYPES_QUERY = gql`
  query {
    __type(name: "MaterialTypeEnum") {
      states: enumValues {
        name
      }
    }
  }
`;
