import gql from "graphql-tag";

export default gql`
  query concreteFormula($id: Int!) {
    concreteFormula(id: $id) {
      id
      isActive
      code
      fcj
      fck
      use
      wcRatio
      fctmk
      mortarFactor
      observations
      incorporatedAir
      consumption
      price
      margin
      aggressivenessIndex
      concreteFamily {
        id
        name
        use
        cementType {
          id
          name
        }
        gravelType
        slump {
          id
          nominal
          deviation
        }
      }
      formulaMaterials {
        id
        proportion
        weight
        specificWeight
        inventoryItem {
          id
          name
          unit
          materialType
          inventoryQuantity
        }
        supplier {
          id
          name
        }
      }
    }
    inventoryItems {
      id
      name
      materialType
      unit
    }
    concreteFamilies {
      id
      name
      use
      cementType {
        id
        name
      }
      gravelType
      slump {
        id
        nominal
        deviation
      }
    }
  }
`;

export const CONCRETE_FORMULA_CREATE_QUERY = gql`
  query {
    inventoryItems {
      id
      name
      materialType
      unit
    }
    concreteFamilies {
      id
      name
      use
      cementType {
        id
        name
      }
      gravelType
      slump {
        id
        nominal
        deviation
      }
    }
  }
`;

export const SUPPLIERS_QUERY = gql`
  query {
    suppliers {
      id
      name
    }
  }
`;