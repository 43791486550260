import React from "react";

export const TruckState = {
  WAITING: "WAITING",
  LOADING: "LOADING",
  FINISHED: "FINISHED",
};

const TruckStateContext = React.createContext({});

export const TruckStateProvider = TruckStateContext.Provider;

export const withTruckStateConsumer = (WrappredComponent) => {
  const Consumer = props => (
    <TruckStateContext.Consumer>
      { context => <WrappredComponent truckStateContext={context} {...props} /> }
    </TruckStateContext.Consumer>
  );
  return Consumer;
};
