import React, { Component } from "react";
import Table from "components/Table/Table";
import { withQuery } from "containers/withQuery";
import PencilButton from "components/ActionButtons/PencilButton";
import { INVENTORY_ITEMS_QUERY } from "../generalRegisterQueries";
import InventoryItemEdit from "./InventoryItemEdit";
import { typeDescription } from "./materialTypes";

const headers = [
  {
    label: "Nome", key: "name",
  },
  {
    label: "Tipo", key: "materialType",
  },
  {
    label: "NCM", key: "ncm",
  },
  {
    label: "PN", key: "partNumber",
  },
  {
    label: "QRCode", key: "qrCode",
  },
  {
    label: "Unidade", key: "unit",
  },
  {
    label: "Preço médio", key: "avgPrice",
  },
  {
    label: "Comentários", key: "comments",
  },
  {
    label: "Id", key: "id",
  },
  {
    label: "", key: "action"
  }
];

class InventoryItemsTable extends Component {
  state = {
    currentEditId: null,
  }

  onFinishEdit = () => this.setState({ currentEditId: null });

  render() {
    const { currentEditId } = this.state;
    return (
      <React.Fragment>
        <Table
          headers={headers}
          matchHeaders
          items={this.props.inventoryItems.map(({ avgPrice, ...inventoryItem }) => ({
            ...inventoryItem,
            avgPrice: avgPrice ? parseFloat(avgPrice).toFixed(2) : "-",
            materialType: typeDescription[inventoryItem.materialType],
            action: (
              <PencilButton
                onPencilEditClick={() => {
                  this.setState({
                    currentEditId: inventoryItem.id
                  });
                }}
              />
            )
          }))}
        />
        {currentEditId !== null && (
          <InventoryItemEdit id={currentEditId} onFinishEdit={this.onFinishEdit} />
        )}
      </React.Fragment>

    );
  }
}

export default withQuery({ query: INVENTORY_ITEMS_QUERY })(InventoryItemsTable);
