import React from "react";
import styled from "styled-components";
import Input from "components/Input/Input";
import Modal from "components/Modal/Modal";
import Field from "components/Field/Field";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import Footer from "../modalComponents/Footer";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
`;

class SupplierModal extends React.Component {
  state = {
    name: this.props.name || "",
    phone: this.props.phone || "",
    email: this.props.email || "",
  }

  onSaveEntry = () => {
    if (this.state.name === "") {
      this.props.snackbarContext.setNotificationTimeOut(Color.danger, "O campo nome é obrigatório");
      return;
    }

    this.props.onSave(this.state);
  }

  onChange = (key, value) => this.setState({ [key]: value })

  render() {
    const { isOpen, toggleModal } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggleModal={toggleModal}
        size="md"
        title={this.props.title}
        footer={<Footer onConfirm={this.onSaveEntry} />}
      >
        <Row>
          <Field title="Nome" width="100%">
            <Input
              name="name"
              style={{ minHeight: "40px" }}
              value={this.state.name}
              onChange={this.onChange}
            />
          </Field>
        </Row>
        <Row>
          <Field title="Telefone" width="100%">
            <Input
              name="phone"
              style={{ minHeight: "40px" }}
              value={this.state.phone}
              onChange={this.onChange}
            />
          </Field>
        </Row>
        <Row>
          <Field title="Email" width="100%">
            <Input
              name="email"
              style={{ minHeight: "40px" }}
              value={this.state.email}
              onChange={this.onChange}
            />
          </Field>
        </Row>
      </Modal>
    );
  }
}

export default withSnackbarConsumer(SupplierModal);
