import React from "react";
import styled from "styled-components";
import { compose, Query } from "react-apollo";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import {
  Row, Col, Container, Input
} from "reactstrap";

import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import Modal from "components/Modal/Modal";
import RegularField from "components/Field/Field";
import CurrencyInput from "components/Form/CurrencyInput";
import { withQuery } from "containers/withQuery";
import StockInputFooter from "./StockInputFooter";
import { INVENTORY_AND_SUPPLIERS_QUERY, LOCATIONS_QUERY } from "./stockInputQueries";

const Field = styled(RegularField)`
  margin-bottom: 25px;
`;

const initialState = {
  quantity: "",
  nfNumber: "",
  productValue: "",
  shipmentValue: "",
  totalValue: "",
  competenceDate: "",
  humidity: 0,
  supplierSelected: null,
  itemSelected: null,
  locationSelected: null,
  humidityRequired: false,
};

const REQUIRED_HUMIDITIES = ["SAND"];

class StockInputModal extends React.Component {
  state = initialState;

  componentWillReceiveProps(props) {
    const { id, itemSelected, ...data } = props.entry || {};

    this.setState({
      ...data,
      itemSelected,
      humidityRequired: itemSelected && this.isHumidityRequired(itemSelected),
    });
  }

  onSaveEntry = () => {
    if (this.isHumidityInvalid()) {
      this.props.snackbarContext.setNotificationTimeOut(Color.danger, "A umidade informada deve estar entre 0% e 30%");
      return;
    }

    if (this.isInvalid()) {
      this.props.snackbarContext.setNotificationTimeOut(Color.danger, "Nenhum campo pode estar em branco");
      return;
    }

    this.props.onSubmit({
      supplierId: this.state.supplierSelected && this.state.supplierSelected.value,
      nfNumber: parseInt(this.state.nfNumber, 10),
      competenceDate: this.state.competenceDate,
      productValue: this.state.productValue,
      shipmentValue: this.state.shipmentValue,
      totalValue: this.state.totalValue,
      humidity: this.state.humidity / 100,
      inventoryChange: {
        quantity: parseFloat(this.state.quantity),
        inventoryItemId: this.state.itemSelected && this.state.itemSelected.value,
        date: this.state.competenceDate,
        inventoryLocationId: this.state.locationSelected && this.state.locationSelected.value
      },
    });
  }

  isInvalid = () => Object.values(this.state).some(value => value === null || value === "")

  isHumidityInvalid = () => (this.state.humidity < 0 || this.state.humidity > 30);

  isHumidityRequired = value => REQUIRED_HUMIDITIES.some(type => type === value.materialType)

  onChangeNfNumber = e => this.setState({ nfNumber: e.target.value });

  onChangeCompetenceDate = competenceDate => this.setState({ competenceDate });

  onChangeQuantity = e => this.setState({ quantity: e.target.value });

  onChangeHumidity = e => this.setState({ humidity: e.target.value });

  onChangeInput = key => value => this.setState({ [key]: value });

  render() {
    const {
      isOpen,
      toggleModal,
      suppliers,
      inventoryItems,
      title
    } = this.props;
    const {
      itemSelected
    } = this.state;

    return (
      <Query
        query={LOCATIONS_QUERY}
        fetchPolicy="no-cache"
        variables={{
          inventoryItemId: itemSelected && itemSelected.value
        }}
      >
        {({ data }) => {
          const locations = data.availableInventoryLocations || [];

          return (
            <Modal
              isOpen={isOpen}
              toggleModal={toggleModal}
              size="lg"
              title={title}
              footer={<StockInputFooter onConfirm={this.onSaveEntry} />}
            >
              <Container fluid>
                <Row>
                  <Col>
                    <Field title="Item">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="itemSelected"
                        value={this.state.itemSelected}
                        onChange={value => this.setState({
                          itemSelected: value,
                          humidityRequired: this.isHumidityRequired(value),
                          locationSelected: null
                        })}
                        options={[
                          {
                            value: "",
                            isDisabled: true
                          },
                          ...inventoryItems.map(({
                            id,
                            name,
                            unit,
                            materialType
                          }) => ({
                            value: id,
                            label: name,
                            unit,
                            materialType
                          }))
                        ]}
                        placeholder="Escolha um..."
                      />
                    </Field>
                  </Col>
                  <Col>
                    <Field title="Fornecedor">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="supplierSelected"
                        value={this.state.supplierSelected}
                        onChange={value => this.setState({ supplierSelected: value })}
                        options={[
                          {
                            value: "",
                            isDisabled: true
                          },
                          ...suppliers.map(({ id, name }) => ({ value: id, label: name }))
                        ]}
                        placeholder="Escolha um..."
                      />
                    </Field>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Field title="Número NF">
                      <Input
                        style={{ minHeight: "40px" }}
                        type="number"
                        value={this.state.nfNumber}
                        onChange={this.onChangeNfNumber}
                      />
                    </Field>
                  </Col>
                  <Col>
                    <Field title="Data de competência">
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          style: { height: "40px" }
                        }}
                        onChange={this.onChangeCompetenceDate}
                        value={this.state.competenceDate}
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                      />
                    </Field>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Field title="Quantidade">
                      <Input
                        style={{ minHeight: "40px" }}
                        type="number"
                        onChange={this.onChangeQuantity}
                        value={this.state.quantity}
                      />
                    </Field>
                  </Col>
                  <Col>
                    <Field title="Unidade">
                      <Input
                        style={{ minHeight: "40px" }}
                        value={this.state.itemSelected ? this.state.itemSelected.unit : ""}
                        disabled
                      />
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field title="Local de estoque">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="itemSelected"
                        value={this.state.locationSelected}
                        onChange={value => this.setState({ locationSelected: value })}
                        options={[
                          {
                            value: "",
                            isDisabled: true
                          },
                          ...locations.map(({ id, name }) => ({ value: id, label: name }))
                        ]}
                        placeholder="Escolha um..."
                      />
                    </Field>
                  </Col>
                  <Col>
                    <Field title="Umidade (%)">
                      <Input
                        style={{ minHeight: "40px" }}
                        type="number"
                        step={1}
                        min={0}
                        max={30}
                        onChange={this.onChangeHumidity}
                        value={this.state.humidity}
                        disabled={!this.state.humidityRequired}
                      />
                    </Field>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Field title="Valor do produto">
                      <CurrencyInput
                        value={this.state.productValue}
                        onChange={this.onChangeInput("productValue")}
                      />
                    </Field>
                  </Col>
                  <Col>
                    <Field title="Valor do frete">
                      <CurrencyInput
                        value={this.state.shipmentValue}
                        onChange={this.onChangeInput("shipmentValue")}
                      />
                    </Field>
                  </Col>
                  <Col>
                    <Field title="Valor total">
                      <CurrencyInput
                        value={this.state.totalValue}
                        onChange={this.onChangeInput("totalValue")}
                      />
                    </Field>
                  </Col>
                </Row>
              </Container>
            </Modal>
          );
        }}
      </Query>
    );
  }
}

export default compose(
  withQuery({
    query: INVENTORY_AND_SUPPLIERS_QUERY
  }),
  withSnackbarConsumer,
)(StockInputModal);
