import React from "react";
import {
  Card,
  Row,
  Col,
  CardHeader,
  CardBody,
} from "reactstrap";
import { Line } from "react-chartjs-2";
import { withQuery } from "containers/withQuery";
import { format } from "date-fns";
import SANDS_QUERY from "./graphql/queries/list/sandsQuery";
import { UmidityChartData } from "./chart.js";

const UmidityGraph = (props) => {
  const sandsLabel = () => (
    props.inventoryItems.length && props.inventoryItems[0].humidityMeasurements.map(hm => (
      format(hm.time, "DD/MM/YYYY")))
  );

  const sandsHumidity = () => (
    props.inventoryItems.map(inventoryItem => ({
      title: inventoryItem.name,
      data: inventoryItem.humidityMeasurements.map((hm) => (
        parseFloat(hm.value) * 100).toFixed(0)),
    }))
  );
  const umidity = UmidityChartData(sandsLabel(), sandsHumidity());

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col sm="7">
            <div className="numbers pull-left">Umidade</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div>
          <h6 className="big-title">Umidade no ultimo mês</h6>
          <Line
            data={umidity.data}
            options={umidity.options}
            height={100}
            width={350}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default withQuery(({ fromDateTime, toDateTime }) => (
  {
    query: SANDS_QUERY,
    variables: {
      fromDateTime,
      toDateTime,
    },
  }
))(UmidityGraph);
