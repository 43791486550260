import React from "react";
import { compose, graphql } from "react-apollo";
import { withQuery } from "containers/withQuery";

import translateErrorMessage from "utils/translateErrorMessage";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { LOCATIONS_QUERY, LOCATION_QUERY } from "../generalRegisterQueries";
import { UPDATE_LOCATION_MUTATION } from "../generalRegisterMutations";
import LocationModal from "./LocationModal";

class LocationEdit extends React.Component {
  onSave = ({
    code,
    capacity,
    name,
  }) => {
    this.props.updateLocation({
      id: this.props.id,
      variables: {
        params: {
          capacity: capacity.length === 0 ? null : capacity,
          name,
          code,
        }
      },
      refetchQueries: [{
        query: LOCATIONS_QUERY
      }]
    })
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Local de estoque atualizado.");
        this.props.onFinishEdit();
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const { inventoryLocation, id } = this.props;
    return (
      <LocationModal
        toggleModal={this.props.onFinishEdit}
        isOpen={id !== null}
        onSave={this.onSave}
        title="Editar local de estoque"
        {...inventoryLocation}
      />
    );
  }
}

export default compose(
  withQuery(({ id }) => ({
    query: LOCATION_QUERY,
    variables: {
      id,
    },
    noLoader: true,
  })),
  graphql(UPDATE_LOCATION_MUTATION, { name: "updateLocation", }),
  withSnackbarConsumer,
)(LocationEdit);
