import React from "react";
import { Query, withApollo } from "react-apollo";
import Spinner from "react-spinkit";
import { format } from "date-fns";
import {
  Row, Col
} from "reactstrap";
import Table from "components/Table/Table";
import StatusIcon from "./StatusIcon";
import { CURRENT_AUTOMATION_STATUS_QUERY, LIST_AUTOMATION_STATUS_QUERY } from "./automationStatusQueries";
import {
  SpinnerContainer, StatusContainer, CardContent, Feedback, Card,
} from "./automationStatusComponents";

const headers = [
  {
    label: "Status", key: "status",
  },
  {
    label: "Descrição", key: "description",
  },
  {
    label: "Data - Hora", key: "dateHour",
  },
];

const AutomationStatus = ({ client }) => (
  <div className="content">
    <Row>
      <Col lg={4}>
        <Card>
          <Query
            query={CURRENT_AUTOMATION_STATUS_QUERY}
            pollInterval={5000}
            onCompleted={({ currentAutomationStatus }) => {
              if (currentAutomationStatus) {
                try {
                  const { listAutomationStatus } = client.readQuery({ query: LIST_AUTOMATION_STATUS_QUERY });
                  const logAlreadyExists = listAutomationStatus.some(({ id, insertedAt }) => (
                    id === currentAutomationStatus.id
                    && currentAutomationStatus.insertedAt === insertedAt
                  ));

                  const mostRecent = listAutomationStatus[0] || {};
                  const sameStatus = mostRecent.status === currentAutomationStatus.status;
                  const sameDescription = mostRecent.description === currentAutomationStatus.description;
                  const bothAutoGenerated = mostRecent.autoGenerated && currentAutomationStatus.autoGenerated;
                  const sameAutoGeneratedLog = sameStatus && sameDescription && bothAutoGenerated;

                  const ignoreWritingCache = logAlreadyExists || sameAutoGeneratedLog;

                  if (!ignoreWritingCache) {
                    client.writeQuery({
                      query: LIST_AUTOMATION_STATUS_QUERY,
                      data: {
                        listAutomationStatus: [currentAutomationStatus, ...listAutomationStatus],
                      },
                    });
                  }
                // Avoid the first query, when the cache is empty
                /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
                } catch (_) {}
              }
            }}
          >
            {({ loading, error, data }) => {
              if (!data && loading) {
                return (
                  <SpinnerContainer>
                    <Spinner name="line-spin-fade-loader" color="green" />
                  </SpinnerContainer>
                );
              }
              if (error) {
                return (
                  <Feedback>
                    <p>Não foi possível</p>
                    <p>obter o último status.</p>
                  </Feedback>
                );
              }

              const { currentAutomationStatus } = data;
              return (
                <CardContent>
                  <StatusIcon size="lg" type={currentAutomationStatus && currentAutomationStatus.status} />
                  <h3 style={{ marginTop: "20px", textAlign: "center" }}>
                    {currentAutomationStatus && currentAutomationStatus.description}
                  </h3>
                </CardContent>
              );
            }}
          </Query>
        </Card>
      </Col>
      <Col lg={8}>
        <Card>
          <Query query={LIST_AUTOMATION_STATUS_QUERY}>
            {({ loading, error, data }) => {
              if (loading) {
                return (
                  <SpinnerContainer>
                    <Spinner name="line-spin-fade-loader" color="green" />
                  </SpinnerContainer>
                );
              }
              if (error) {
                return (
                  <Feedback>
                    <p>Não foi possível conectar com o controlador</p>
                    <p>Tente novamente mais tarde ou entre em contato com o suporte.</p>
                  </Feedback>
                );
              }

              const { listAutomationStatus = {} } = data;
              return (
                <Table
                  maxHeight={492}
                  headers={headers}
                  matchHeaders
                  items={listAutomationStatus.map(automationStatus => ({
                    ...automationStatus,
                    dateHour: format(automationStatus.insertedAt, "DD/MM/YYYY - HH:mm:ss"),
                    status: (
                      <StatusContainer>
                        <StatusIcon type={automationStatus.status} size="sm" />
                      </StatusContainer>
                    ),
                  }))}
                />
              );
            }}
          </Query>
        </Card>
      </Col>
    </Row>
  </div>
);

export default withApollo(AutomationStatus);
