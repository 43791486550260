import React from "react";
import styled from "styled-components";
import Input from "components/Input/Input";
import Modal from "components/Modal/Modal";
import Field from "components/Field/Field";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import Footer from "../modalComponents/Footer";


const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
`;

const REQUIRED_FIELDS = ["name", "code"];

class LocationModal extends React.Component {
  state = {
    capacity: this.props.capacity || "",
    name: this.props.name || "",
    code: this.props.code || "",
  }

  onSaveEntry = () => {
    if (this.isInvalid()) {
      this.props.snackbarContext.setNotificationTimeOut(Color.danger, "Nome e código são obrigatórios");
      return;
    }

    this.props.onSave(this.state);
  }

  onChange = (key, value) => this.setState({ [key]: value });

  isInvalid = () => Object.keys(this.state).filter(key => REQUIRED_FIELDS.includes(key)).some(key => !this.state[key])

  render() {
    const { isOpen, toggleModal } = this.props;
    const {
      code,
      name,
      capacity,
    } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggleModal={toggleModal}
        size="md"
        title={this.props.title}
        footer={<Footer onConfirm={this.onSaveEntry} />}
      >
        <Row style={{ marginTop: "0px" }}>
          <Field title="Código" width="100%">
            <Input
              name="code"
              style={{ minHeight: "40px" }}
              value={code}
              onChange={this.onChange}
            />
          </Field>
        </Row>
        <Row>
          <Field title="Nome" width="100%">
            <Input
              name="name"
              style={{ minHeight: "40px" }}
              value={name}
              onChange={this.onChange}
            />
          </Field>
        </Row>
        <Row>
          <Field title="Capacidade" width="100%">
            <Input
              name="capacity"
              style={{ minHeight: "40px" }}
              type="number"
              value={capacity}
              onChange={this.onChange}
            />
          </Field>
        </Row>
      </Modal>
    );
  }
}

export default withSnackbarConsumer(LocationModal);
