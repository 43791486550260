import React from "react";
import { compose, graphql } from "react-apollo";
import translateErrorMessage from "utils/translateErrorMessage";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { LOCATIONS_QUERY } from "../generalRegisterQueries";
import { CREATE_LOCATION_MUTATION } from "../generalRegisterMutations";
import LocationModal from "./LocationModal";

class LocationCreate extends React.Component {
  onSave = ({
    capacity,
    name,
    code,
  }) => {
    this.props.createLocation({
      variables: {
        params: {
          capacity: capacity.length === 0 ? null : capacity,
          name,
          code,
        }
      },
      refetchQueries: [{
        query: LOCATIONS_QUERY
      }]
    })
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Local de estoque criado.");
        this.props.toggleModal();
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const { isOpen } = this.props;
    return (
      <LocationModal
        toggleModal={this.props.toggleModal}
        isOpen={isOpen}
        onSave={this.onSave}
        title="Cadastrar local de estoque"
      />
    );
  }
}

export default compose(
  graphql(CREATE_LOCATION_MUTATION, { name: "createLocation", }),
  withSnackbarConsumer,
)(LocationCreate);
