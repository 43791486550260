import React, { useState, useEffect } from "react";
import { Subscription } from "react-apollo";
import styled from "styled-components";
import ManualOperationAlert from "./ManualOperationAlert";
import { ManualOperationModal } from "./ManualOperationModal";
import SUBSCRIBE_TO_MANUAL_OPERATION from "../graphql/subscriptions/subscribeToManualOperation";
import SUBSCRIBE_TO_CONTROLLER_FINISHED from "../graphql/subscriptions/subscribeToControllerFinished";

const ManualOperationContainer = styled.div`
  top: 55px;
  left: 80px;
  position: fixed;
  z-index: 1029;
  height: 0px;

  @media screen and (max-width: 991px) {
    left: 0px;
  }
`;

export const ManualOperation = ({
  controllerHasFinished,
  concreteShipmentId,
  refetchConcreteShipmentQuery,
  manualOperations
}) => {
  const [nextOperation, setNextOperation] = useState(null);
  const [idleOperations, setIdleOperations] = useState([]);
  const [shipmentOperations, setShipmentOperations] = useState([]);

  const onResolve = operation => () => setNextOperation(operation);

  const manualOperationAlert = (message, operations, stack) => {
    const amount = operations.length;

    return (
      <ManualOperationAlert
        message={message}
        amount={amount}
        stack={stack}
        nextOperation={amount > 0 && operations[0]}
        onResolve={onResolve}
      />
    );
  };

  const clearOperation = () => setNextOperation(null);

  const onSubscriptionData = () => refetchConcreteShipmentQuery();

  useEffect(() => {
    setIdleOperations((manualOperations || []).filter(operation => !operation.concreteShipment));
    setShipmentOperations((manualOperations || []).filter(operation => !!operation.concreteShipment));
    if (manualOperations && manualOperations.length) {
      setNextOperation(manualOperations[0]);
    } else {
      setNextOperation(null);
    }
  }, [manualOperations]);

  return (
    <React.Fragment>
      <ManualOperationContainer>
        {manualOperationAlert("Operação manual", idleOperations, true)}
        {manualOperationAlert("Operação manual durante carregamento", shipmentOperations, false)}
      </ManualOperationContainer>

      <ManualOperationModal
        controllerHasFinished={controllerHasFinished}
        operation={nextOperation}
        clearOperation={clearOperation}
        onRefetch={refetchConcreteShipmentQuery}
      />

      <Subscription
        subscription={SUBSCRIBE_TO_MANUAL_OPERATION}
        onSubscriptionData={onSubscriptionData}
        fetchPolicy="no-cache"
      />

      <Subscription
        subscription={SUBSCRIBE_TO_CONTROLLER_FINISHED}
        variables={{
          concreteShipmentId,
        }}
        onSubscriptionData={onSubscriptionData}
        fetchPolicy="no-cache"
      />
    </React.Fragment>
  );
};
