import gql from "graphql-tag";

export default gql`
subscription {
  subscribeToInvoiceResponse {
    equipmentCode
    osCode
    reason
    startTime
    status
  }
}
`;
