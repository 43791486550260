import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { httpClient } from "../../settings/apolloClient";
import ArrowDown from "../../assets/img/arrow-down.svg";
import ArrowUp from "../../assets/img/arrow-up.svg";
import {
  CHANGE_SEQUENCE_ORDER_MUTATION,
} from "./graphql/scheduleMutations";
import { ShipmentInfo } from "./ShipmentInfo";


const TableContainer = styled.div`
  overflow: scroll;
  width: 100%;
  border-bottom: 1px solid #F6F8FF;
  border-radius: 0 0 15px 15px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableRow = styled.tr`
  width: 100%;
  border-bottom: 1px solid #cfd1d3;
  cursor: pointer;

  &.selected {
    background-color: #edeff2;
  };

  &:hover {
    background-color: #cfd1d3;
  };
`;

const Header = styled.th`
  width: 100%;
  background-color: #F4F6F8;
  padding-top: ${props => (!props.isMobile ? "22px" : "8px")};
  padding-bottom: ${props => (!props.isMobile ? "22px" : "8px")};
  padding-left: ${props => (!props.isMobile ? "16px" : "6px")};
  padding-right: ${props => (!props.isMobile ? "16px" : "6px")};
  text-align: ${props => (props.center ? "center" : "left")};
`;

// Sequence order column should be a colum that has 2 arrows to move the order up or down,
// if the shipment was already started, the arrows should be disabled,
// if it is the first non-started shipment, the up arrow should be disabled
// if it is the last non-started shipment, the down arrow should be disabled
// these arrows should call a mutation to update the order of the shipments
const SequenceOrder = styled.td`
  padding: 14px 16px 14px 16px;
  font-size: 13px;
  color: #77889D;
  text-align: center;
`;

const SingleLine = styled.td`
  padding: ${(props) => (props.isMobile ? "4px" : "14px 16px")};
  font-size: 16px;
  color: #8798AD;
  text-align: left;
  font-weight: bold;
`;

// Formula column should be a column with 2 lines,
// the first line should be the formula code it should have a bigger font size and a bold font weight,
// the second line should be the slump and fck, it should have a smaller font size and a normal font weight
const DoubleLine = styled.td`
  padding: ${(props) => (props.isMobile ? "4px" : "14px 16px")};
  font-size: 13px;
  color: #8798AD;
  text-align: left;
`;

const ArrowButton = styled.button`
  background-color: #E9E9E9;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: #F4F6F8;
    cursor: not-allowed;
  }
  
  &:focus {
    outline: 0;
  }

  img {
    width: 24px;
    height: 24px; // Adjust icon size as needed
  }
`;

const Status = styled.p`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  padding: 5px;
  margin: auto;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
`;


const mobileTableHeader = [
  { code: "orderAction", label: "", width: "5%" },
  { code: "formula", label: "Traço", width: "75%" },
  { code: "quantity", label: "", width: "20%" }
];

const desktopTableHeader = [
  { code: "orderAction", label: "", width: "5%" },
  { code: "formula", label: "Traço", width: "33%" },
  { code: "code", label: "Identificação", width: "37%" },
  { code: "quantity", label: "Volume", width: "10%" },
  { code: "status", label: "Status", width: "15%" }
];

const ShipmentOrderAction = ({
  id,
  isFirstPendingShipment,
  isLastShipment,
  refetchSequence,
  sequenceOrder,
  shipments,
  startTime,
}) => {
  const [changeSequenceOrder] = useMutation(CHANGE_SEQUENCE_ORDER_MUTATION, { client: httpClient });

  const arrowUpDisabled = isFirstPendingShipment || Boolean(startTime);
  const arrowDownDisabled = isLastShipment || Boolean(startTime);

  const handleChangeOrder = useCallback((direction) => {
    const shipmentIndex = shipments.findIndex((shipment) => shipment.id === id);
    const indexToChange = (direction === "up") ? shipmentIndex - 1 : shipmentIndex + 1;
    const swapId = shipments[indexToChange].id;
    changeSequenceOrder({
      variables: {
        concreteShipmentId: id,
        swapId,
      },
    }).then(() => {
      refetchSequence();
    });
  }, [changeSequenceOrder, refetchSequence, id, sequenceOrder]);

  return (
    <SequenceOrder>
      {

      }
      <ArrowButton
        hidden={arrowUpDisabled}
        onClick={() => handleChangeOrder("up")}
      >
        <img src={ArrowUp} alt="arrow up" />
      </ArrowButton>
      <ArrowButton
        hidden={arrowDownDisabled}
        onClick={() => handleChangeOrder("down")}
      >
        <img src={ArrowDown} alt="arrow down" />
      </ArrowButton>
    </SequenceOrder>
  );
};

const ShipmentFormula = ({ concreteFormula, isMobile }) => {
  const { code, fck, concreteFamily: { slump: { nominal, deviation } } } = concreteFormula;
  const slump = `${nominal} ± ${deviation}`;
  return (
    <DoubleLine isMobile={isMobile}>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {code}
      </div>
      <div>{`${slump} | Fck: ${fck} MPa`}</div>
    </DoubleLine>
  );
};

const ShipmentIdentification = ({ code, sealNumber, isMobile }) => (
  <DoubleLine isMobile={isMobile}>
    <div
      style={{
        fontSize: "16px",
        fontWeight: "bold",
      }}
    >
      {code}
    </div>
    <div>{`Lacre: ${sealNumber}`}</div>
  </DoubleLine>
);

const ShipmentQuantity = ({ quantity, isMobile }) => (
  <SingleLine isMobile={isMobile}>
    {`${quantity} m³`}
  </SingleLine>
);

const ShipmentStatus = ({ startTime, loadingEndTime }) => {
  const status = loadingEndTime ? "Finalizada" : startTime ? "Em andamento" : "Aguardando";
  const backgroundColor = loadingEndTime ? "#DCF6E5" : startTime ? "#FEF2D6" : "#D6F4F9";
  const textColor = loadingEndTime ? "#2D8D57" : startTime ? "#B76E01" : "#1C6C9C";
  return (
    <td style={{ paddingRight: "10px" }}>
      <Status backgroundColor={backgroundColor} textColor={textColor}>
        {status}
      </Status>
    </td>
  );
};

const ShipmentRow = ({
  isFirstPendingShipment,
  isLastShipment,
  refetchSequence,
  selectedShipment,
  setSelectedShipment,
  shipment,
  shipments,
  isMobile,
  isExpanded,
  handleRowExpand,
  setSelectedFormula,
  setEditShipmentModalOpen,
}) => {
  const {
    code,
    concreteFormula,
    id,
    loadingEndTime,
    quantity,
    sealNumber,
    sequenceOrder,
    startTime,
  } = shipment;

  const backgroundColor = loadingEndTime ? "#DCF6E5" : startTime ? "#FEF2D6" : "#D6F4F9";

  const hexToRGBA = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const [className, setClassName] = useState("");

  const handleSelectShipment = () => {
    setSelectedShipment((prev) => (prev && prev.id === id ? null : shipment));
    handleRowExpand(id);
  };

  useEffect(() => {
    if (Boolean(selectedShipment) && selectedShipment.id === id) {
      setClassName("selected");
    } else {
      setClassName("");
    }
  }, [selectedShipment, id]);

  return (
    <React.Fragment>
      <TableRow
        key={id}
        className={className}
        onClick={handleSelectShipment}
        style={{ ...(isMobile ? { backgroundColor } : {}), padding: "0px", cursor: "pointer" }}
      >
        <ShipmentOrderAction
          id={id}
          isFirstPendingShipment={isFirstPendingShipment}
          isLastShipment={isLastShipment}
          refetchSequence={refetchSequence}
          sequenceOrder={sequenceOrder}
          shipments={shipments}
          startTime={startTime}
        />
        <ShipmentFormula concreteFormula={concreteFormula} isMobile={isMobile} />
        {!isMobile ? <ShipmentIdentification code={code} sealNumber={sealNumber} /> : null}
        <ShipmentQuantity quantity={quantity} isMobile={isMobile} />
        {!isMobile ? <ShipmentStatus startTime={startTime} loadingEndTime={loadingEndTime} /> : null}
      </TableRow>

      {isExpanded && isMobile && (
        <TableRow
          style={{ backgroundColor: hexToRGBA(backgroundColor, 0.4) }}
        >
          <td colSpan={isMobile ? 4 : 6} style={{ padding: "0", backgroundColor: { backgroundColor } }}>
            <div
              style={{
                padding: "10px",
                backgroundColor: { backgroundColor },
              }}
            >
              <ShipmentInfo
                refetchSequence={refetchSequence}
                setSelectedFormula={setSelectedFormula}
                setEditShipmentModalOpen={setEditShipmentModalOpen}
                setSelectedShipment={setSelectedShipment}
                shipment={selectedShipment}
                style={{
                  margin: "10px",
                  padding: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                }}
              />
            </div>
          </td>
        </TableRow>
      )}
    </React.Fragment>
  );
};

const getFirstPendingShipmentId = (shipments) => {
  if (Boolean(shipments) && shipments.length > 0) {
    const firstPendingShipment = shipments.find((shipment) => Boolean(shipment) && !shipment.startTime);
    return !firstPendingShipment ? -1 : firstPendingShipment.id;
  }
  return -1;
};

export function ShipmentList({
  sequence,
  selectedShipment,
  setSelectedShipment,
  refetchSequence,
  isMobile,
  setSelectedFormula,
  setEditShipmentModalOpen,
}) {
  const [shipments, setShipments] = useState(null);
  const [firstPendingShipmentId, setFirstPendingShipmentId] = useState(null);
  const [expandedShipmentId, setExpandedShipmentId] = useState(null); // ID da linha expandida

  const TableHeader = isMobile ? mobileTableHeader : desktopTableHeader;

  useEffect(() => {
    if (Boolean(sequence) && Boolean(sequence.concreteShipments) && sequence.concreteShipments.length > 0) {
      setShipments(sequence.concreteShipments);
      setFirstPendingShipmentId(getFirstPendingShipmentId(sequence.concreteShipments));
    }
  }, [sequence]);

  const handleRowExpand = (id) => {
    setExpandedShipmentId((prev) => (prev === id ? null : id));
  };

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            {TableHeader.map(({ code, label, width }) => (
              <Header key={code} center={code === "sequenceOrder"} style={{ width }}>
                {label}
              </Header>
            ))}
          </tr>
        </thead>
        <tbody>
          {Boolean(shipments) && shipments.map((shipment, index) => {
            if (!shipment) return null;
            const isFirstPendingShipment = shipment.id === firstPendingShipmentId;
            const isLastShipment = index === shipments.length - 1;

            return (
              <ShipmentRow
                key={shipment.id}
                isFirstPendingShipment={isFirstPendingShipment}
                isLastShipment={isLastShipment}
                refetchSequence={refetchSequence}
                selectedShipment={selectedShipment}
                setSelectedShipment={setSelectedShipment}
                shipment={shipment}
                shipments={shipments}
                isMobile={isMobile}
                isExpanded={shipment.id === expandedShipmentId} // Passa o estado
                handleRowExpand={handleRowExpand}
                setSelectedFormula={setSelectedFormula}
                setEditShipmentModalOpen={setEditShipmentModalOpen}
              />
            );
          })}
          {((Boolean(shipments) && shipments.length === 0) || !shipments) && (
            <TableRow>
              <td colSpan={TableHeader.length}>
                <SingleLine isMobile={isMobile}>
                  Nenhuma carga foi programada para este sequenciamento ainda.
                </SingleLine>
              </td>
            </TableRow>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
}
