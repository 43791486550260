import React, { Component } from "react";
import { Subscription } from "react-apollo";
import MachineStatus from "components/MachineStatus/MachineStatus";
import { PlantItem } from "components/StyledComponents";
import SUBSCRIBE_TO_ACTUATOR_CHANGES from "../graphql/subscriptions/subscribeToActuatorStatusChanges.js";

class ActuatorSubscription extends Component {
  render() {
    const {
      automationStatus,
      id,
      name,
      status,
      type,
    } = this.props;
    return (
      <Subscription
        subscription={SUBSCRIBE_TO_ACTUATOR_CHANGES}
        fetchPolicy="no-cache"
        variables={{
          id
        }}
      >
        {({ data }) => (
          <PlantItem>
            <MachineStatus
              automationStatus={automationStatus}
              status={data ? data.subscribeToActuatorStatusChanges : (false || status)}
              actuatorName={name}
              id={id}
              type={type}
            />
          </PlantItem>
        )}
      </Subscription>
    );
  }
}

export default ActuatorSubscription;
