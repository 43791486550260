import React, { Component } from "react";
import {
  Container,
  Card,
  Row,
  Col,
} from "reactstrap";
import ReactSVG from "react-svg";
import sandBucket from "assets/img/sand-bucket.svg";
import styled from "styled-components";
import {
  firstDayOfMonth as newFirstDayOfMonth,
  firstDayOfNextMonth as newFirstDayOfNextMonth
} from "utils/chartDates.js";
import SlidersContainer from "./SlidersContainer.jsx";
import UmidityGraph from "./UmidityGraph.jsx";
import UmidityTable from "./UmidityTable/UmidityTable";

const CardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 30px;
  width: 100%;
`;

const styles = {
  svg: {
    width: 80,
    margin: "10px auto"
  },
};

class UmidityScreen extends Component {
  render() {
    const firstDayOfNextMonth = newFirstDayOfNextMonth().toISOString();
    const firstDayOfMonth = newFirstDayOfMonth().toISOString();

    return (
      <div className="content">
        <CardContainer>
          <Container>
            <Row>
              <Col md={2} xs={12}>
                <ReactSVG src={sandBucket} style={styles.svg} />
              </Col>

              <Col md={10} xs={12}>
                <SlidersContainer
                  toDateTime={firstDayOfNextMonth}
                  firstDayOfMonth={firstDayOfMonth}
                />
              </Col>
            </Row>
          </Container>
        </CardContainer>

        <UmidityGraph
          fromDateTime={firstDayOfMonth}
          toDateTime={firstDayOfNextMonth}
        />

        <Card>
          <UmidityTable
            onChangeSlider={this.onChangeSlider}
            sands={this.state}
            fromDateTime={firstDayOfMonth}
            toDateTime={firstDayOfNextMonth}
          />
        </Card>
      </div>
    );
  }
}

UmidityScreen.defaultProps = {
  humidityThinSand: 6,
  humidityThickSand: 6
};


export default UmidityScreen;
