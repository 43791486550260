import React, { Component } from "react";
import PropTypes from "prop-types";

import { ServiceOrdersFilterProvider } from "./ServiceOrdersFilterContext";

class ServiceOrdersFilterContainer extends Component {
  state = {
    serviceOrderStatus: "CURRENT",
  }

  setServiceOrder = status => {
    this.setState({ serviceOrderStatus: status });
  }

  render() {
    return (
      <ServiceOrdersFilterProvider
        value={{
          value: this.state.serviceOrderStatus,
          setServiceOrder: this.setServiceOrder
        }}
      >
        {this.props.children}
      </ServiceOrdersFilterProvider>
    );
  }
}

ServiceOrdersFilterContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

export default ServiceOrdersFilterContainer;
