import React from "react";
import { Button } from "reactstrap";

const StockInputFooter = props => (
  <Button
    style={{ paddingLeft: "100px", paddingRight: "100px", margin: 0 }}
    color="info"
    data-dismiss="modal"
    type="button"
    onClick={props.onConfirm}
  >
    Salvar entrada
  </Button>
);

export default StockInputFooter;
