import React from "react";
import { graphql, compose } from "react-apollo";

import robotIcon from "assets/img/robot.svg";
import handclickIcon from "assets/img/handclick.svg";

import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";

import MAINTENANCE_MODE_MUTATION from "./graphql/maintenanceModeMutation";
import ActionButton from "./ActionButton";

const MaintenanceButton = ({
  automationStatus,
  requestMaintenanceMode,
  snackbarContext,
}) => {

  const isMaintenanceButton = true;

  const isMaintenanceMode = automationStatus && automationStatus.currentAutomationStatus && automationStatus.currentAutomationStatus.status === "MAINTENANCE";

  const {
    action,
    label,
    message,
    icon,
  } = isMaintenanceMode
    ? {
      action: "desativar painel virtual",
      label: "Automático",
      message: "O painel virtual foi desativado!",
      icon: robotIcon,
    }
    : {
      action: "ativar painel virtual",
      label: "Painel Virtual",
      message: "O painel virtual foi ativado!",
      icon: handclickIcon,
    };


  const onConfirm = () => {
    requestMaintenanceMode({
      variables: {
        status: !isMaintenanceMode,
      },
      refetchQueries: []
    })
      .then(() => {
        snackbarContext.setNotificationTimeOut(Color.success, message);
      })
      .catch((error) => {
        snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  };

  return (
    <ActionButton
      icon={icon}
      label={label}
      onConfirm={onConfirm}
      classes="maintenance"
      action={action}
      active={isMaintenanceMode}
      isMaintenanceButton={isMaintenanceButton}
    />
  );
};

export default compose(
  withSnackbarConsumer,
  graphql(MAINTENANCE_MODE_MUTATION, { name: "requestMaintenanceMode" }),
)(MaintenanceButton);
