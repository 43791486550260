import React from "react";
import Table from "components/Table/Table";
import "./ConfirmModal.css";

const MaterialTable = ({ materials }) => (
  <div style={{ marginTop: "40px", }}>
    <h3 style={{ marginBottom: "19px" }}>Materiais por local</h3>
    <div style={{ border: "1px solid #F6F8FF", borderRadius: "15px" }}>
      <Table
        headers={[
          {
            label: "Local", key: "location"
          },
          {
            label: "Material", key: "name"
          },
          {
            label: "Quantidade", key: "weight"
          },
          {
            label: "Estoque", key: "quantity"
          },
          {
            label: "Previsão do estoque",
            key: "newQuantity",
            valueFormatter: (value => (
              <span className={value < 0 ? "label-error" : ""}>
                {value}
              </span>
            ))
          },
        ]}
        matchHeaders
        items={materials}
      />
    </div>
  </div>
);

export default MaterialTable;
