import { useState, useEffect } from "react";

export const useMobileDevice = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

export const useTabletDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const [isTablet, setIsTablet] = useState(
    (window.innerWidth <= 768
      || /iPad|Android|Tablet|PlayBook|Silk/i.test(userAgent)
      || !window.matchMedia("(orientation: portrait)").matches)
      && window.innerHeight <= 780
  );

  useEffect(() => {
    const handleResize = () => {
      const isTabletDevice = (window.innerWidth <= 768
        || /iPad|Android|Tablet|PlayBook|Silk/i.test(userAgent)
        || !window.matchMedia("(orientation: portrait)").matches)
        && window.innerHeight <= 780;
      setIsTablet(isTabletDevice);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [userAgent]);

  return isTablet;
};
