import React from "react";
import { compose, graphql } from "react-apollo";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";
import { CONCRETE_FAMILIES_QUERY } from "views/FamiliesAndFormulas/FamiliesAndFormulasQueries";
import { CREATE_CONCRETE_FAMILY_MUTATION } from "./familiesAndFormulasMutation";
import FamilyModal from "./FamilyModal";

class FamilyCreate extends React.Component {
  onSubmit = params => {
    this.props.createConcreteFamily({
      variables: { params },
      refetchQueries: [
        { query: CONCRETE_FAMILIES_QUERY },
      ]
    })
      .then(this.onSuccess)
      .catch(this.onError);
  };

  onSuccess = () => {
    const { snackbarContext, toggleModal } = this.props;
    snackbarContext.setNotificationTimeOut(Color.success, "Família criada.");
    toggleModal();
  }

  onError = error => {
    const { snackbarContext } = this.props;
    snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
  }

  render() {
    const { isOpen, toggleModal } = this.props;

    return (
      <FamilyModal
        title="Cadastrar família"
        isOpen={isOpen}
        toggleModal={toggleModal}
        onSave={this.onSubmit}
      />
    );
  }
}

export default compose(
  withSnackbarConsumer,
  graphql(CREATE_CONCRETE_FAMILY_MUTATION, { name: "createConcreteFamily", }),
)(FamilyCreate);
