import gql from "graphql-tag";

export const AUTHORIZED_OPERATOR_QUERY = gql`
  query {
    getCurrentUser {
      id
    }
    getCurrentAutomationOperator {
      id
      name
    }
  }
`;

export const REQUEST_OPERATOR_ACCESS_MUTATION = gql`
  mutation {
    requestOperatorAccess
  }
`;

export const REMOVE_OPERATOR_ACCESS_MUTATION = gql`
  mutation {
    removeOperatorAccess
  }
`;
