import React from "react";
import { compose, graphql } from "react-apollo";
import translateErrorMessage from "utils/translateErrorMessage";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { SLUMPS_QUERY } from "../generalRegisterQueries";
import { CREATE_SLUMP_MUTATION } from "../generalRegisterMutations";
import SlumpModal from "./SlumpModal";

class SlumpCreate extends React.Component {
  onSave = ({
    abntValue,
    deviation,
    nominal,
    real,
    specification,
  }) => {
    this.props.createSlump({
      variables: {
        params: {
          abntValue: parseInt(abntValue, 10),
          deviation: parseInt(deviation, 10),
          nominal: parseInt(nominal, 10),
          real: parseInt(real, 10),
          specification
        }
      },
      refetchQueries: [{
        query: SLUMPS_QUERY
      }]
    })
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Slump criado.");
        this.props.toggleModal();
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const { isOpen } = this.props;
    return (
      <SlumpModal
        toggleModal={this.props.toggleModal}
        isOpen={isOpen}
        onSave={this.onSave}
        title="Cadastrar slump"
      />
    );
  }
}

export default compose(
  graphql(CREATE_SLUMP_MUTATION, { name: "createSlump", }),
  withSnackbarConsumer,
)(SlumpCreate);
