import React from "react";
import styled from "styled-components";
import ReactSVG from "react-svg";

const TitleAndValueContainer = styled.div`
  margin: 10px auto;
  padding: 0 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;

const Title = styled.p`
  font-size: 18px;
  color: #8798AD;
  font-family: "Rubik";
  text-align: left;
`;

const Value = styled.p`
  font-size: 32px;
  color: #2E384D;
  font-family: "Rubik";
  text-align: left;
`;

const styles = {
  svg: {
    width: 60,
  },
  icon: {
    width: 60,
    margin: "5px auto",
  },
};

const MaterialCard = ({
  title,
  value,
  imageSrc,
  toggleShow,
  toggleOpen,
}) => (
  <Container>
    <ReactSVG svgStyle={styles.svg} style={styles.icon} src={imageSrc} />
    <TitleAndValueContainer>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </TitleAndValueContainer>
    {toggleShow && <i className={`fa fa-angle-${toggleOpen ? "up" : "down"} fa-2x`} style={styles.icon} />}
  </Container>
);

export default MaterialCard;
