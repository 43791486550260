export const UPDATE_REQUIRED_FIELDS = [
  { key: "code", label: "Código do traço" },
  { key: "fck", label: "FCK" },
  { key: "concreteFamily", label: "Família", select: "value" },
  { key: "use", label: "Uso do traço" },
  { key: "margin", label: "Margem" },
];

export const CREATE_REQUIRED_FIELDS = UPDATE_REQUIRED_FIELDS;

export const MATERIAL_REQUIRED_FIELDS = [
  { key: "name", label: "Nome", select: "value" },
  { key: "supplier", label: "Fornecedor", select: "value" },
  { key: "proportion", label: "Proporção" },
  { key: "specificWeight", label: "Peso específico", select: "label" },
  { key: "weight", label: "Peso", select: "label" },
];

export const MATERIAL_TABLE_REQUIRED_FIELDS = [
  { key: "nameValue", label: "Nome" },
  { key: "supplierValue", label: "Fornecedor" },
  { key: "proportion", label: "Proporção" },
  { key: "specificWeight", label: "Peso específico" },
  { key: "weight", label: "Peso", select: "label" },
];

export const isBlank = value => value === "" || value === undefined || value === null;
