import gql from "graphql-tag";

export const CREATE_INVENTORY_ENTRY_MUTATION = gql`
  mutation($params: CreateInventoryEntryParams!) {
    createInventoryEntry(params: $params) {
      id
    }
  }
`;

export const UPDATE_INVENTORY_ENTRY_MUTATION = gql`
  mutation($id: Int!, $params: UpdateInventoryEntryParams!) {
    updateInventoryEntry(id: $id, params: $params) {
      id
    }
  }
`;
