import React from "react";
import styled from "styled-components";
import { Card } from "reactstrap";

import sandImage from "assets/img/materialTypes/sand.svg";
import gravelImage from "assets/img/materialTypes/gravel.svg";
import cementImage from "assets/img/materialTypes/cement.svg";
import additiveImage from "assets/img/materialTypes/additive.svg";
import additionImage from "assets/img/materialTypes/addition.svg";
import waterImage from "assets/img/materialTypes/water.svg";

import MaterialCard from "./MaterialCard";
import LocationCard from "./LocationCard";
import Touchable from "../../components/Touchable/Touchable";

const materialImage = {
  SAND: sandImage,
  GRAVEL: gravelImage,
  CEMENT: cementImage,
  ADDITIVE: additiveImage,
  ADDITION: additionImage,
  WATER: waterImage,
};

const StyledTouchable = styled(Touchable)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const StyledMaterialCard = styled(MaterialCard)`
  display: flex;
  flex-grow: 1;
`;

const LocationsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;
`;

class InventoryItem extends React.Component {
  state = {
    dropdownOpen: false
  }

  toggle = () => {
    this.setState(prev => ({
      dropdownOpen: !prev.dropdownOpen
    }));
  }

  render() {
    const {
      name,
      unit,
      inventoryQuantity,
      materialType,
      inventoryLocations: locations
    } = this.props;

    return (
      <React.Fragment>
        <Card>
          <StyledTouchable onClick={this.toggle}>
            <StyledMaterialCard
              title={name}
              value={`${Math.floor(inventoryQuantity)} ${unit}`}
              imageSrc={materialImage[materialType]}
              toggleShow={!!locations.length}
              toggleOpen={this.state.dropdownOpen}
            />
          </StyledTouchable>
          {this.state.dropdownOpen && !!locations.length && (
            <React.Fragment>
              <hr />
              <LocationsContainer>
                {locations.map((location) => (
                  <LocationCard
                    key={location.id}
                    title={location.name}
                    value={`${Math.floor(location.inventoryQuantity)} ${unit}`}
                  />
                ))}
              </LocationsContainer>
            </React.Fragment>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

export default InventoryItem;
