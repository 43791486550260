import React from "react";
import { Subscription } from "react-apollo";
import SUBSCRIBE_TO_SENSOR_CHANGES from "../graphql/subscriptions/subscribeToSensorMeasurementChanges.js";


export const SensorSubscription = ({ id, name, unit }) => {
  return (
    <Subscription
      subscription={SUBSCRIBE_TO_SENSOR_CHANGES}
      fetchPolicy="no-cache"
      variables={{
        id
      }}
    >
      {({ data }) => <Sensor data={data} name={name} unit={unit} />}
    </Subscription>
  );
};

const Sensor = ({ data, name, unit }) => {
  return (
    <span>
      <h2>
        { data ? `${data.subscribeToSensorMeasurementChanges.toFixed(1)} ${unit}` : `0.0 ${unit}` }
      </h2>
      <h5>{ name }</h5>
    </span>
  );
};
