import gql from "graphql-tag";

export const CURRENT_AUTOMATION_STATUS_QUERY = gql`
  query {
    currentAutomationStatus {
      id
      description
      status
      autoGenerated
      insertedAt
    }
  }
`;

export const LIST_AUTOMATION_STATUS_QUERY = gql`
  query {
    listAutomationStatus {
      id
      description
      insertedAt
      autoGenerated
      status
    }
  }
`;
