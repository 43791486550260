import React from "react";

import styled from "styled-components";

const Property = styled.h2`
  font-family: "Rubik";
  font-size: 20px;
  color: #8798AD;
  font-weight: 500;
`;

const Title = styled.p`
  font-family: "Rubik";
  font-size: 15px;
  color: #283745;
  font-weight: 500;

  margin-top: 10px;
`;

const Container = styled.div`
  background-color: #F6F8FF;
  padding-top: 24px;
  padding-bottom: 14px;
  padding-left: 10px;
  padding-right: 10px;
  width: 24.8%;
  text-align: center;
`;

const MaterialBox = ({ title, value }) => (
  <Container>
    <Property>{value}</Property>
    <Title>{title}</Title>
  </Container>
);

export default MaterialBox;
