import React from "react";

import styled from "styled-components";

const Container = styled.div`
  background-color: #F6F8FF;
  /* text-align: center; */
  width: 39.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-family: "Rubik";
  font-size: 28px;
  font-weight: 500;
  color: #1D84C6;
`;

const SubTitle = styled.div`
  font-family: "Rubik";
  font-size: 15px;
  font-weight: 500;
  color: #283745;
`;

const Info = ({ waterQuantity, sliderValue }) => (
  <Container>
    <Title>{`${~~(waterQuantity * (100 - sliderValue) / 100)} Litros`}</Title>
    <SubTitle>Corte de Água</SubTitle>
  </Container>
);

export default Info;
