import React from "react";
import { compose, graphql } from "react-apollo";

import translateErrorMessage from "utils/translateErrorMessage";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { CEMENT_TYPES_QUERY } from "../generalRegisterQueries";
import { CREATE_CEMENT_TYPE_MUTATION } from "../generalRegisterMutations";
import CementTypeModal from "./CementTypeModal";

class CementTypeCreate extends React.Component {
  onSave = cementType => {
    this.props.createCementType({
      variables: {
        params: cementType
      },
      refetchQueries: [{
        query: CEMENT_TYPES_QUERY
      }]
    })
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Tipo de Cimento criado.");
        this.props.toggleModal();
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const { isOpen } = this.props;
    return (
      <CementTypeModal
        toggleModal={this.props.toggleModal}
        isOpen={isOpen}
        onSave={this.onSave}
        title="Cadastrar tipo de cimento"
      />
    );
  }
}

export default compose(
  graphql(CREATE_CEMENT_TYPE_MUTATION, { name: "createCementType", }),
  withSnackbarConsumer,
)(CementTypeCreate);
