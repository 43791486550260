import React, { Component } from "react";
import PropTypes from "prop-types";

import { TruckStateProvider, TruckState } from "./TruckStateContext";

class TruckStateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: {
        truckState: TruckState.WAITING,
        setTruckState: this.setTruckState,
      }
    };
  }

  setTruckState = (truckState) => {
    this.setState(prevState => (
      {
        context: {
          ...prevState.context,
          truckState
        }
      }));
  }

  render() {
    return (
      <TruckStateProvider
        value={this.state.context}
      >
        {this.props.children}
      </TruckStateProvider>
    );
  }
}

TruckStateContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

export default TruckStateContainer;
