import React, { Component } from "react";

import { InvoiceSubscriptionProvider } from "./InvoiceSubscriptionContext";

class InvoiceSubscriptionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      context: {
        invoiceSubscriptionContext: {
          afterSubscriptionData: null,
          setAfterSubscriptionData: this.setAfterSubscriptionData,
          clearAfterSubscriptionData: this.clearAfterSubscriptionData,
        }
      }
    };
  }

  setAfterSubscriptionData = afterSubscriptionData => {
    this.setState(state => ({
      context: {
        invoiceSubscriptionContext: {
          ...state.context.invoiceSubscriptionContext,
          afterSubscriptionData
        }
      }
    }));
  }

  clearAfterSubscriptionData = () => {
    this.setState(state => ({
      context: {
        invoiceSubscriptionContext: {
          ...state.context.invoiceSubscriptionContext,
          afterSubscriptionData: null,
        }
      }
    }));
  }

  render() {
    const { context } = this.state;
    return (
      <InvoiceSubscriptionProvider value={context}>
        {this.props.children}
      </InvoiceSubscriptionProvider>
    );
  }
}

export default InvoiceSubscriptionContainer;
