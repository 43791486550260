import gql from "graphql-tag";

export default gql`
query {
  getCurrentUser {
    email
    name
    role
  }
}
`;
