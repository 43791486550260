import React from "react";
import ReactSelect from "react-select";
import { withQuery } from "containers/withQuery";
import { typeDescription } from "./materialTypes";
import { MATERIAL_TYPES_QUERY } from "../generalRegisterQueries";

const Select = ({
  name,
  placeholder,
  value,
  onChange,
  __type: {
    states
  }
}) => (
  <ReactSelect
    styles={{
      valueContainer: () => ({
        width: 250,
      }),
    }}
    className="react-select primary"
    classNamePrefix="react-select"
    name="itemSelected"
    value={value}
    onChange={newValue => onChange(name, newValue)
    }
    options={[
      {
        value: "",
        isDisabled: true
      },
      ...states.map(({ name: materialType }) => ({
        value: materialType,
        label: typeDescription[materialType],
      }))
    ]}
    placeholder={placeholder}
  />
);

export default withQuery({
  query: MATERIAL_TYPES_QUERY
})(Select);
