/* eslint-disable dot-notation */
import React, { Component } from "react";
import PlantLocation from "./PlantLocations/PlantLocation";

import ProcessFinishedContent from "./ProcessFinishedContent.jsx";

class InstrumentsPanelContent extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions();
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => this.setState({ width: window.innerWidth, height: window.innerHeight });

  render() {
    const {
      automationStatus,
      loadingShipment,
      plantLocations,
      refetchConcreteShipmentQuery,
    } = this.props;
    const loadingShipmentId = loadingShipment && loadingShipment.id;

    return (
      <React.Fragment>
        <ProcessFinishedContent
          refetchConcreteShipmentQuery={refetchConcreteShipmentQuery}
          onFinish={this.props.onFinish}
          concreteShipmentId={loadingShipmentId}
        />
        {
          plantLocations.map(location => (
            <PlantLocation
              key={location.id}
              loadingShipment={loadingShipment}
              plantLocation={location}
              width={this.state.width}
              height={this.state.height}
              automationStatus={automationStatus}
            />
          ))
        }
      </React.Fragment>
    );
  }
}

export default InstrumentsPanelContent;
