import React from "react";

const ServiceOrdersFilterContext = React.createContext({});

export const ServiceOrdersFilterProvider = ServiceOrdersFilterContext.Provider;

export const withServiceOrdersFilterConsumer = (WrappredComponent) => {
  const Consumer = props => (
    <ServiceOrdersFilterContext.Consumer>
      { context => <WrappredComponent serviceOrdersFilterContext={context} {...props} /> }
    </ServiceOrdersFilterContext.Consumer>
  );
  return Consumer;
};
