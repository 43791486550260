import gql from "graphql-tag";

export default gql`
query inventoryItems($fromDateTime: DateTime!, $toDateTime: DateTime!) {
  inventoryItems(materialType: SAND ) {
    id
    name
    humidityMeasurements(
      fromDatetime: $fromDateTime,
      toDatetime: $toDateTime,
      datetimeField: "time",
      orderBy: "time",
      sortingOrder: ASC
    ) {
      id
      time
      value
    }
  }
}
`;
