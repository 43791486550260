import gql from "graphql-tag";

const FORMULA_MATERIAL_FRAGMENT = gql`
  fragment FormulaMaterial on FormulaMaterial {
    id
    weight
    order
    inventoryItem {
      id
      name
      materialType
      unit
      inventoryLocations {
        id
        name
        inventoryQuantity
      }
      lastHumidityMeasurement {
        id
        value
      }
    }
  }
`;

export const FORMULA_MATERIAL_TABLE_QUERY = gql`
  query formulaMaterialTable($formulaCode: String!, $quantity: Decimal!) {
    formulaMaterialTable(formulaCode: $formulaCode, quantity: $quantity) {
      water { ...FormulaMaterial }
      sand { ...FormulaMaterial }
      gravel { ...FormulaMaterial }
      cement { ...FormulaMaterial }
      additive { ...FormulaMaterial }
      addition { ...FormulaMaterial }
    }
  }
  ${FORMULA_MATERIAL_FRAGMENT}
`;

export const CONCRETE_FORMULA_QUERY = gql`
  query($formulaCode: Int!) {
    concreteFormula(code: $formulaCode) {
      id
      fck
      use
      concreteFamily {
        id
        gravelType
        slump {
          id
          nominal
          deviation
        }
      }
    }
  }
`;
