import React from "react";
import { compose, graphql } from "react-apollo";
import { Card } from "reactstrap";
import Table from "components/Table/Table";
import PencilButton from "components/ActionButtons/PencilButton";
import DeleteButton from "components/ActionButtons/DeleteButton";
import { withQuery } from "containers/withQuery";
import { format } from "date-fns";
import moment from "moment";
import AlertModal from "components/AlertModal/AlertModal";
import { ErrorParagraph } from "components/StyledComponents";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";
import StockInputEdit from "components/Navbars/StockMaterials/StockInputEdit";
import { INVENTORY_ENTRIES_QUERY, DELETE_INVENTORY_ENTRY_MUTATION } from "./stockMaterialsQueries";

const headers = [
  {
    label: "Item", key: "item"
  },
  {
    label: "Fornecedor", key: "supplierName"
  },
  {
    label: "N. NF", key: "nfNumber"
  },
  {
    label: "Data competência", key: "competenceDate", valueFormatter: date => format(new Date(date), "DD/MM/YYYY")
  },
  {
    label: "QTD", key: "quantity", valueFormatter: quantity => parseFloat(quantity).toFixed(1)
  },
  {
    label: "UN", key: "unit"
  },
  {
    label: "Valor produto", key: "productValue"
  },
  {
    label: "Valor frete", key: "shipmentValue"
  },
  {
    label: "Valor total", key: "totalValue"
  },
  {
    label: "", key: "edit", className: "table-action"
  },
  {
    label: "", key: "delete", className: "table-action"
  },
];

class InventoryEntriesTable extends React.Component {
  state = {
    entry: null,
  };

  mapDataToTable = inventoryEntries => inventoryEntries.map(item => {
    const {
      id,
      inventoryItem,
      supplier,
      competenceDate,
      nfNumber,
      inventoryChange,
      productValue,
      shipmentValue,
      totalValue,
    } = item;

    return {
      id,
      item: inventoryItem.name,
      supplierName: supplier.name,
      nfNumber,
      competenceDate,
      quantity: inventoryChange.quantity,
      unit: inventoryItem.unit,
      productValue,
      shipmentValue,
      totalValue,
      edit: (
        <PencilButton onPencilEditClick={this.onSelect(item)} />
      ),
      delete: (
        <DeleteButton onClick={this.onClickDelete(item.id)} />
      ),
    };
  });

  onSelect = selectedEntry => () => {
    const {
      id,
      humidity,
      competenceDate,
      nfNumber,
      productValue,
      shipmentValue,
      totalValue,
      inventoryChange: {
        quantity,
        inventoryLocation: {
          id: locationId,
          name: locationName,
        }
      },
      supplier: {
        id: supplierId,
        name: supplierName,
      },
      inventoryItem: {
        id: itemId,
        name: itemName,
        unit,
        materialType,
      }
    } = selectedEntry;


    const entry = {
      id,
      competenceDate: moment(competenceDate),
      humidity: humidity * 100,
      nfNumber,
      productValue: parseFloat(productValue),
      shipmentValue: parseFloat(shipmentValue),
      totalValue: parseFloat(totalValue),
      quantity,
      supplierSelected: {
        value: supplierId,
        label: supplierName,
      },
      itemSelected: {
        value: itemId,
        label: itemName,
        unit,
        materialType,
      },
      locationSelected: {
        value: locationId,
        label: locationName,
      },
    };

    this.setState({ entry });
  }

  onRemove = () => this.setState({ entry: null });

  onClickDelete = id => () => {
    AlertModal.fire({
      title: "Você tem certeza que deseja excluir esta entrada de estoque?",
      html: (
        <ErrorParagraph>
          Esta alteração não pode ser desfeita!
        </ErrorParagraph>
      ),
      type: "error",
      showCancelButton: true,
      confirmButtonColor: "#1D84C6",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Voltar",
      reverseButtons: true,
    })
      .then(result => {
        if (result.value) {
          this.onConfirmDelete(id);
        }
      });
  }

  onConfirmDelete = id => {
    const { deleteInventoryEntry, snackbarContext } = this.props;

    deleteInventoryEntry({
      variables: { id },
      refetchQueries: [
        { query: INVENTORY_ENTRIES_QUERY },
      ]
    })
      .then(() => {
        snackbarContext.setNotificationTimeOut(Color.success, "Entrada de estoque excluída com sucesso!");
      })
      .catch(error => {
        snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const { inventoryEntries } = this.props;
    const { entry } = this.state;

    return (
      <React.Fragment>
        <Card>
          <Table
            headers={headers}
            matchHeaders
            items={this.mapDataToTable(inventoryEntries)}
          />
        </Card>

        <StockInputEdit entry={entry} clearEntry={this.onRemove} />
      </React.Fragment>
    );
  }
}

export default compose(
  withQuery({
    query: INVENTORY_ENTRIES_QUERY,
  }),
  graphql(DELETE_INVENTORY_ENTRY_MUTATION, { name: "deleteInventoryEntry" }),
  withSnackbarConsumer,
)(InventoryEntriesTable);
