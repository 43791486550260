import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { Socket as PhoenixSocket } from "phoenix";
import { setContext } from "apollo-link-context";
import Cookies from "universal-cookie";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { TOKEN_KEY } from "../utils/auth.js";

const ORIGIN_WS = process.env.NODE_ENV === "production"
  ? `${window.location.protocol.includes("https") ? "wss" : "ws"}://${window.location.hostname}`
  : "ws://localhost:4000";

const ORIGIN_HTTP = process.env.NODE_ENV === "production"
  ? `${window.location.protocol}//${window.location.hostname}`
  : "http://localhost:4000";

const WEBSOCKET_ENDPOINT = `${ORIGIN_WS}/graphql`;
const HTTP_ENDPOINT = `${ORIGIN_HTTP}/graphql`;

const createAbsintheLink = (token) => {
  const socket = new PhoenixSocket(
    WEBSOCKET_ENDPOINT,
    {
      params: { Bearer: token },
      timeout: 900000, // 15 minutes timeout
    },
  );

  return createAbsintheSocketLink(
    AbsintheSocket.create(socket)
  );
};

const httpAuthLink = setContext((_, { headers }) => {
  const cookies = new Cookies();
  const token = cookies.get(TOKEN_KEY, { path: "/" });

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const httpLink = new HttpLink({
  uri: HTTP_ENDPOINT,
  credentials: "include",
});

const authenticatedLink = httpAuthLink.concat(httpLink);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
  },
};

const httpClient = new ApolloClient({
  link: authenticatedLink,
  cache: new InMemoryCache(),
  defaultOptions,
});

const createSplitLink = token => split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  createAbsintheLink(token),
  authenticatedLink,
);

const createSplitClient = token => new ApolloClient({
  link: createSplitLink(token),
  cache: new InMemoryCache(),
  defaultOptions,
});

export {
  httpClient,
  createSplitClient,
};
