import React, { useEffect, useState } from "react";
import {
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import Spinner from "react-spinkit";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import styled from "styled-components";
import Table from "components/Table/Table";
import CardHeader from "./CardHeader";

import {
  useGetProcesses,
} from "api/controller-configs";

import {
  put,
  endpoints,
} from "utils/axios";

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: flex-center;
  margin: 15px;
  padding: 20px;
`;

const headers = [
  {
    label: "", key: "radio", className: "table-action"
  },
  {
    label: "Nome", key: "name",
  },
  {
    label: "Descrição", key: "description",
  },
  {
    label: "Habilitado", key: "enabled",
  },
];


function ProcessesTable({
  setProcessCode,
  setProcessName,
}) {
  const { processes, processesLoading, processesRefetch } = useGetProcesses();
  const [selectedProcessCode, setSelectedProcessCode] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const handleRadioClick = ({ code, name }) => {
    setSelectedProcessCode(code);
    setProcessCode(code);
    setProcessName(name);
  };

  const handleToggleClick = async ({ code, enabled }) => {
    if (enabled) {
      await put(endpoints.processes.disable(code));
    } else {
      await put(endpoints.processes.enable(code));
    };
    setRefetch(!refetch);
  };

  const mapDataToTable = processes => processes.map(process => ({
    radio: (
      <FormGroup style={{ paddingLeft: "30px", marginTop: "-20px" }} check>
        <Label check>
          <Input onClick={() => handleRadioClick(process)} type="checkbox" checked={selectedProcessCode === process.code}/>
          <span className="form-check-sign" />
        </Label>
      </FormGroup>
    ),
    name: process.name,
    description: process.description,
    enabled: (
      <Toggle
        icons={{
          unchecked: null,
        }}
        checked={process.enabled}
        onChange={() => handleToggleClick(process)}
      />
    ),
  }));

  const [tableItems, setTableItems] = useState(mapDataToTable(processes));

  useEffect(() => {
    processesRefetch();
  }, [refetch, processesRefetch]);

  useEffect(() => {
    setTableItems(mapDataToTable(processes));
  }, [processes, mapDataToTable]);

  return (
    <React.Fragment>
      <CardHeader title="Processos" />
      {
        processesLoading && (
          <SpinnerContainer>
            <Spinner name="line-spin-fade-loader" color="green" />
          </SpinnerContainer>
        )
      }
      { !processesLoading && tableItems.length === 0 && <p>Nenhum processo encontrado.</p> }
      { !processesLoading && tableItems.length > 0 && 
        <Table tableName="processes" headers={headers} matchHeaders items={tableItems} maxHeight="1200px" />
      }
    </React.Fragment>
  );
};

export default ProcessesTable;
