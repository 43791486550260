import React, {
  useState, useEffect, useMemo, useCallback
} from "react";
import { Decimal } from "decimal.js";

import MaterialProgress from "components/MaterialProgress/MaterialProgress";
import { PlantItem, PlantContent } from "components/StyledComponents";
import { convertArrayToObject } from "utils/convertArrayToObject";
import update from "immutability-helper";

import MultipleProgressSubscriptionWrapper from "./MultipleProgressSubscriptionWrapper";

const ProgressAggregate = ({
  concreteShipmentId,
  materials,
  inventoryItems,
  numberOfColumns,
  isContainerVertical,
}) => {
  const [shipments, setShipments] = useState({});

  const items = useMemo(
    () => shipments[concreteShipmentId] || {},
    [shipments, concreteShipmentId],
  );

  useEffect(() => {
    const hasConcreteShipment = !!concreteShipmentId;
    const hasItems = !!Object.keys(items).length;
    const hasValidItems = inventoryItems.some(({ shipmentMaterial }) => !!shipmentMaterial);

    const shouldUpdate = !hasItems && hasValidItems;

    if (hasConcreteShipment && shouldUpdate) {
      setShipments(update(shipments, {
        [concreteShipmentId]: {
          $set: convertArrayToObject(
            inventoryItems,
            item => item.id
          )
        },
      }));
    }
  }, [
    items,
    shipments,
    inventoryItems,
    concreteShipmentId,
    setShipments,
  ]);

  const value = useMemo(() => (
    Object.values(items).reduce((acum, current) => (
      new Decimal(current.itemShipmentQuantity).plus(acum)
    ), 0)
  ), [items]);

  const total = useMemo(() => (
    Object.values(items).reduce((acum, current) => (
      new Decimal((current.shipmentMaterial ? current.shipmentMaterial.formulaWeight : 0)).plus(acum)
    ), 0)
  ), [items]);

  const onSubscriptionData = useCallback((id, subscriptionItem) => {
    setShipments(update(shipments, {
      [concreteShipmentId]: {
        [id]: {
          itemShipmentQuantity: {
            $set: subscriptionItem.subscribeToItemShipmentQuantity
          }
        }
      }
    }));
  }, [
    concreteShipmentId,
    shipments,
    setShipments,
  ]);

  return (
    <React.Fragment>
      <PlantContent
        cols={1}
        isContainerVertical={isContainerVertical}
      >
        <PlantItem className={isContainerVertical ? "" : "center-vertically"}>
          <MaterialProgress
            title="Soma"
            value={value}
            total={total}
          />
        </PlantItem>
      </PlantContent>

      <PlantContent
        cols={numberOfColumns}
        isContainerVertical={isContainerVertical}
      >
        <MultipleProgressSubscriptionWrapper
          concreteShipmentId={concreteShipmentId}
          onSubscriptionData={onSubscriptionData}
          materials={materials}
          inventoryItems={inventoryItems}
        />
      </PlantContent>
    </React.Fragment>
  );
};

export default ProgressAggregate;
