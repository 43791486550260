/* eslint no-restricted-globals: 0 */
// For isNaN usage
import React from "react";
import TableEdit from "components/Table/TableEdit";
import ReactSVG from "react-svg";
import DeleteIcon from "assets/img/delete-button.svg";
import AddIcon from "assets/img/add.svg";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import {
  InfoContainer,
  Title,
  materialHeaders,
  materialTypes,
  TableActionHeader,
} from "./ConcreteTracesModalStyle.jsx";
import { MATERIAL_TABLE_REQUIRED_FIELDS, isBlank } from "./requiredFields.js";

const typeDescription = {
  ADDITION: "Adição",
  ADDITIVE: "Aditivo",
  CEMENT: "Cimento",
  GRAVEL: "Brita",
  SAND: "Areia",
  WATER: "Água"
};

class MaterialsTable extends React.Component {
  constructor(props) {
    super(props);
    this.NEW_MATERIAL_ID = 0;

    const materials = this.mapDataToTable(props.materials, false);

    this.state = {
      materials,
      availableInventoryItems: props.inventoryItems,
    };
  }

  componentDidMount() {
    this.props.onChangeMaterials(this.state.materials);
  }

  componentWillReceiveProps(nextProps) {
    const { materials } = this.state;
    let newMaterials = [];
    if (!nextProps.shouldUpdate) {
      return;
    }

    if (nextProps.isEditable) {
      newMaterials = this.updateMaterialsList(materials, !nextProps.isEditable);
    } else {
      newMaterials = materials.map(({ action, ...material }) => material);
    }
    this.setState({ materials: newMaterials });
  }

  getAnEmptyMaterial = () => {
    const id = this.getNextMaterialId();

    return {
      id,
      name: "",
      supplier: "",
      materialType: "-",
      specificWeight: {"label":"1.00"},
      weight: "",
      quantity: "-",
      proportion: "1.0",
      action: (
        <ReactSVG
          src={DeleteIcon}
          onClick={() => this.onDeleteMaterialsRow(id)}
          title="Excluir"
          className="clickable-default"
        />
      )
    };
  }

  mapDataToTable = materials => materials.map(material => ({
    id: this.getNextMaterialId(),
    name: {
      value: this.props.inventoryItems.find(item => item.name === material.inventoryItem.name).id,
      label: material.inventoryItem.name,
      materialType: material.inventoryItem.materialType,
    },
    supplier: {
      value: material.supplier ? material.supplier.id : "",
      label: material.supplier ? material.supplier.name : "",
      optionKey: this.props.inventoryItems.find(item => item.name === material.inventoryItem.name).id,
    },
    materialType: materialTypes[material.inventoryItem.materialType],
    specificWeight: {
      label: parseFloat(material.specificWeight).toFixed(2),
      unit: material.inventoryItem.unit
    },
    weight: {
      label: parseFloat(material.weight).toFixed(2),
      unit: material.inventoryItem.unit
    },
    quantity: parseFloat(material.weight / material.specificWeight).toFixed(2),
    proportion: `${parseFloat(material.proportion).toFixed(4)}`,
  }))

  selectProperties = ({
    id, materialType, name: { value: nameValue }, supplier: { value: supplierValue }, weight, specificWeight, proportion
  }) => ({
    id, materialType, nameValue, supplierValue, weight, specificWeight, proportion
  })

  onAddMaterialRow = () => {
    const lastMaterial = this.state.materials[this.state.materials.length - 1];

    if (lastMaterial) {
      const selectedProperties = this.selectProperties(lastMaterial);

      const missingFields = MATERIAL_TABLE_REQUIRED_FIELDS.filter(field => {
        const value = selectedProperties[field.key];

        if (field.select) {
          return !value || isBlank(value[field.select]);
        }

        return isBlank(value);
      });

      if (missingFields.length === 0) {
        this.addMaterialRow();
      } else {
        const message = missingFields.length === 1
          ? `${missingFields[0].label} é obrigatório`
          : `${missingFields.map(({ label }) => label).join(", ")} são obrigatórios`;

        this.props.snackbarContext.setNotificationTimeOut(Color.danger, message);
      }
    } else {
      this.addMaterialRow();
    }
  }

  addMaterialRow = () => this.setState(({ materials }) => ({
    materials: this.updateMaterialsList(materials).concat(this.getAnEmptyMaterial()),
    availableInventoryItems: this.props.inventoryItems.filter(item => !materials.find(material => material.name.label === item.name))
  }), () => this.props.onChangeMaterials(this.state.materials));

  updateMaterialsList = (materials, isEditable) => {
    const newMaterials = materials.map(material => ({
      ...material,
      ...(!isEditable && {
        action: (
          <ReactSVG
            src={DeleteIcon}
            onClick={() => this.onDeleteMaterialsRow(material.id)}
            title="Excluir"
            className="clickable-default"
          />
        )
      })
    }));

    return newMaterials;
  }

  calculateVolume = (specificWeight, weight) => {
    const a = parseFloat(specificWeight);
    const b = parseFloat(weight);

    const result = a > 0 ? b / a : 1;
    return !isNaN(result) ? result.toFixed(2) : "";
  }

  getNextMaterialId = () => {
    this.NEW_MATERIAL_ID = this.NEW_MATERIAL_ID + 1;
    return this.NEW_MATERIAL_ID;
  }

  onDeleteMaterialsRow = id => this.setState(({ materials }) => {
    this.NEW_MATERIAL_ID = 0;
    const newMaterials = materials
      .filter(material => material.id !== id)
      .map(material => {
        const newId = this.getNextMaterialId();
        return {
          ...material,
          id: newId,
          action: (
            <ReactSVG
              src={DeleteIcon}
              onClick={() => this.onDeleteMaterialsRow(newId)}
              title="Excluir"
              className="clickable-default"
            />
          )
        };
      });

    return {
      materials: newMaterials
    };
  }, () => this.props.onChangeMaterials(this.state.materials))

  onChangeRowData = newData => {
    const { key, id, newValue } = newData;
    this.setState(({ materials }) => {
      const newMaterials = materials.map(material => {
        if (material.id === id) {
          return {
            ...material,
            [key]: newValue,
            ...(key === "supplier" && {
              supplier: {
                ...material[key],
                value: newValue.value,
                label: newValue.label,
              }
            }),
            ...(key === "name" && {
              materialType: typeDescription[newValue.materialType],
              name: newValue,
              supplier: {
                optionKey: newValue.value
              },
            }),
            ...(key === "specificWeight" && {
              specificWeight: {
                label: newValue || parseFloat(material.specificWeight.label).toFixed(2),
                unit: material.specificWeight.unit || ""
              },
              quantity: {
                label: this.calculateVolume(newValue, material.weight.label),
                unit: material.specificWeight.unit
              }
            }),
            ...(key === "weight" && {
              weight: {
                label: newValue,
                unit: material.weight.unit || ""
              },
              quantity: {
                label: this.calculateVolume(material.specificWeight.label, newValue),
                unit: material.weight.unit
              }
            }),
          };
        }
        return material;
      });
      return ({ materials: newMaterials });
    }, () => this.props.onChangeMaterials(this.state.materials));
  }

  render() {
    return (
      <React.Fragment>
        <InfoContainer>
          <TableActionHeader>
            <Title>Materiais</Title>
            {
              this.props.isEditable && (
                <ReactSVG
                  src={AddIcon}
                  onClick={this.onAddMaterialRow}
                  title="Adicionar"
                  className="clickable-default"
                />
              )
            }
          </TableActionHeader>
        </InfoContainer>
        <InfoContainer>
          <TableEdit
            tableName="materials"
            isEditable={this.props.isEditable}
            headers={materialHeaders(this.state.availableInventoryItems, this.props.suppliers)}
            items={this.state.materials}
            onChange={this.onChangeRowData}
          />
        </InfoContainer>
      </React.Fragment>
    );
  }
}

export default withSnackbarConsumer(MaterialsTable);
