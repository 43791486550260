import React, { useState } from "react";
import { graphql, compose } from "react-apollo";

import topGoIcon from "assets/img/top-go-button.svg";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";

import BOOST_PROCESS_MUTATION from "./graphql/boostProcessMutation";
import ActionButton from "./ActionButton";

const BoostButton = ({
  hasShipment,
  boostShipment,
  snackbarContext,
}) => {
  const [active, setActive] = useState(false);

  const onConfirm = () => {
    boostShipment()
      .then(() => {
        snackbarContext.setNotificationTimeOut(Color.success, "O carregamento está sendo acelerado");
        setActive(true);
      })
      .catch((error) => {
        snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  };

  return (
    <ActionButton
      icon={topGoIcon}
      label="Top-Go!"
      onConfirm={onConfirm}
      classes="boost"
      action="acelerar"
      active={active}
      disabled={active || !hasShipment}
    />
  );
};

export default compose(
  withSnackbarConsumer,
  graphql(BOOST_PROCESS_MUTATION, { name: "boostShipment" }),
)(BoostButton);
