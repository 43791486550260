import React from "react";
import ReactSVG from "react-svg";
import Truck from "assets/img/blue-truck.svg";
import OperatorsList from "components/OperatorsList/OperatorsList";
import {
  EquipmentContainer,
  TruckContainer,
  EquipmentCode,
  InfoContainer,
  InfoText,
  SimpleDataContainer,
  Title,
  OperatorContainer,
  TeamContainer,
  AvatarsContainer,
} from "./confirmModalComponents";
import MaterialTable from "./MaterialTable";
import MaterialBoxes from "./MaterialBoxes";

const styles = {
  withContract: {
    padding: "30px",
    borderTop: "2px solid #F6F8FF",
  },
  withoutContract: {
    padding: "0 30px 30px 30px",
  },
};

const OperationData = ({
  equipmentCode,
  quantity,
  materials,
  formulaCode,
  onConcreteFormulaQueryCompleted,
  operatorsSelected,
  teamSelected,
  hasContract,
}) => (
  <div style={hasContract ? styles.withContract : styles.withoutContract}>
    {hasContract && <Title>Dados da operação</Title>}

    <MaterialBoxes
      formulaCode={formulaCode}
      onConcreteFormulaQueryCompleted={onConcreteFormulaQueryCompleted}
    />

    <SimpleDataContainer style={{ marginTop: "30px", }}>
      <EquipmentContainer>
        <div>
          <h3>Equipamento</h3>
          <EquipmentCode>{equipmentCode || "Não selecionado"}</EquipmentCode>
        </div>

        <TruckContainer>
          <ReactSVG src={Truck} />
        </TruckContainer>
      </EquipmentContainer>

      <InfoContainer>
        <h3>Quantidade</h3>

        <InfoText>
          {`${quantity}m³`}
        </InfoText>
      </InfoContainer>
    </SimpleDataContainer>

    <SimpleDataContainer style={{ marginTop: "40px", }}>
      <OperatorContainer>
        <h3>Operador</h3>

        <AvatarsContainer>
          {operatorsSelected.length > 0 ? (
            <OperatorsList
              type="confirm-team"
              operators={operatorsSelected}
            />
          ) : (
            <p>Não selecionado</p>
          )}
        </AvatarsContainer>
      </OperatorContainer>

      <TeamContainer>
        <h3>Equipe</h3>

        <AvatarsContainer>
          {teamSelected.length > 0 ? (
            <OperatorsList
              type="confirm-operators"
              operators={teamSelected}
            />
          ) : (
            <p>Não selecionado</p>
          )}
        </AvatarsContainer>
      </TeamContainer>
    </SimpleDataContainer>

    <MaterialTable materials={materials} />
  </div>
);

export default OperationData;
