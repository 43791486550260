import gql from "graphql-tag";

const CONCRETE_SHIPMENT_REPORT_QUERY = gql`
  query ConcreteShipmentQuery($code: String!) {
    concreteShipmentReport(code: $code) {
      concreteShipment {
        id
        status
        insertedAt
        loadingStartTime
        travelStartTime
        waterCut
        quantity
        shipmentInfo {
          contractCode
          constructionName
          equipmentCode
          equipmentPlate
          operatorName
        }
        concreteFormula {
          id
          code
          fck
          concreteFamily {
            gravelType
            slump {
              id
              nominal
              deviation
            }
          }
        }
      }
      theoreticalVolumeTotal
      suppliedVolumeTotal
      humidityMaterials {
        materialName
        humidityValue
      }
      shipmentLocations {
        inventoryLocationName
        theoreticalVolume
        suppliedVolume
        specificMass
        materialTrace
        consumedMaterial
        grossError
        percentageError
      }
    }
  }
`;

export default CONCRETE_SHIPMENT_REPORT_QUERY;
