import React from "react";

const SnackbarContext = React.createContext({});

export const SnackbarProvider = SnackbarContext.Provider;

export const withSnackbarConsumer = (WrappredComponent) => {
  const Consumer = props => (
    <SnackbarContext.Consumer>
      { context => <WrappredComponent snackbarContext={context} {...props} /> }
    </SnackbarContext.Consumer>
  );

  return Consumer;
};

export const Color = {
  primary: "primary",
  secondary: "secondary",
  success: "success",
  danger: "danger",
  warning: "warning",
  info: "info",
  light: "light",
  dark: "dark"
};
