import React, { Component } from "react";
import PropTypes from "prop-types";
import NotificationAlert from "react-notification-alert";
import styled from "styled-components";

import { SnackbarProvider } from "./SnackbarContext";

const NotificationContainer = styled.div`
  z-index: 99999;
  position: absolute;
`;

class SnackbarContainer extends Component {
  setNotificationTimeOut = (type, message, autoDismiss = 7) => {
    const options = {
      autoDismiss,
      type,
      place: "tr",
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
    };

    this.notificationAlert.notificationAlert(options);
  };

  assignNotificationRef = ref => {
    this.notificationAlert = ref;
  }

  render() {
    return (
      <SnackbarProvider
        value={{ setNotificationTimeOut: this.setNotificationTimeOut }}
      >
        <NotificationContainer>
          <NotificationAlert ref={this.assignNotificationRef} />
        </NotificationContainer>

        {this.props.children}
      </SnackbarProvider>
    );
  }
}

SnackbarContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
};

export default SnackbarContainer;
