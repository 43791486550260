import React from "react";
import { compose, graphql } from "react-apollo";
import { withQuery } from "containers/withQuery";

import translateErrorMessage from "utils/translateErrorMessage";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import InventoryItemModal from "./InventoryItemModal";
import { INVENTORY_ITEM_QUERY, INVENTORY_ITEMS_QUERY } from "../generalRegisterQueries";
import { UPDATE_INVENTORY_ITEM_MUTATION } from "../generalRegisterMutations";

class InventoryItemEdit extends React.Component {
  onSave = inventoryItem => {
    this.props.updateInventoryItem({
      variables: {
        id: this.props.id,
        params: inventoryItem
      },
      refetchQueries: [{
        query: INVENTORY_ITEMS_QUERY
      }]
    })
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Item de inventário atualizado.");
        this.props.onFinishEdit();
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const { inventoryItem, id } = this.props;
    return (
      <InventoryItemModal
        toggleModal={this.props.onFinishEdit}
        isOpen={id !== null}
        onSave={this.onSave}
        {...inventoryItem}
        title="Editar item de inventário"
      />
    );
  }
}

export default compose(
  withQuery(({ id }) => ({
    query: INVENTORY_ITEM_QUERY,
    variables: {
      id,
    },
    noLoader: true,
  })),
  graphql(UPDATE_INVENTORY_ITEM_MUTATION, { name: "updateInventoryItem", }),
  withSnackbarConsumer,
)(InventoryItemEdit);
