import React from "react";
import {
  Card,
  Row,
  Col,
  Container,
} from "reactstrap";

import Table from "components/Table/Table";
import {
  formatDate,
  timeMask,
  dateMask,
} from "utils/dateFormats";
import {
  formatVolume,
  formatWeight,
  formatPercentual,
} from "utils/formatters";

import {
  Title,
  DataTitle,
  DataCard,
  DataValue,
} from "./ReportComponents";

const humidityHeaders = [
  {
    label: "Material",
    key: "materialName",
  },
  {
    label: "Umidade",
    key: "humidityValue",
    valueFormatter: formatPercentual,
  },
];

const locationsHeaders = [
  {
    label: "Local de estoque",
    key: "inventoryLocationName",
  },
  // TODO: Enable this when we are able to display this info
  // {
  //   label: "Volume teórico",
  //   key: "theoreticalVolume",
  //   valueFormatter: formatVolume,
  // },
  // {
  //   label: "Volume entregue",
  //   key: "suppliedVolume",
  //   valueFormatter: formatVolume,
  // },
  // {
  //   label: "Massa específica",
  //   key: "specificMass",
  //   valueFormatter: formatMass,
  // },
  {
    label: "Material no traço",
    key: "materialTrace",
    valueFormatter: formatWeight,
  },
  {
    label: "Material consumido",
    key: "consumedMaterial",
    valueFormatter: formatWeight,
  },
  {
    label: "Erro bruto",
    key: "grossError",
    valueFormatter: formatWeight,
  },
  {
    label: "Erro percentual",
    key: "percentageError",
    valueFormatter: formatPercentual,
  },
];

const ShipmentReport = ({
  data,
}) => {
  const {
    concreteShipment,
    shipmentLocations,
    humidityMaterials,
  } = (data && data.concreteShipmentReport) || {};

  if (!concreteShipment) {
    if (data) {
      return (
        <div className="message-container">
          <h3 className="mb-3">Carga não encontrada</h3>
          <p>Não existem cargas com o código informado, verifique se o código informado está correto</p>
        </div>
      );
    }

    return (
      <div className="message-container">
        <h3 className="mb-3">Nenhuma carga selecionada</h3>
        <p>Digite o código de uma carga para gerar o relatório</p>
      </div>
    );
  }

  const { shipmentInfo } = concreteShipment;

  const {
    nominal,
    deviation,
  } = concreteShipment.concreteFormula.concreteFamily.slump;

  return (
    <Container>
      {
        shipmentInfo && (
          <React.Fragment>
            <Row>
              <Col md={4}>
                <DataCard>
                  <DataTitle>Código do contrato</DataTitle>
                  <DataValue>{shipmentInfo.contractCode}</DataValue>
                </DataCard>
              </Col>

              <Col md={8}>
                <DataCard>
                  <DataTitle>Nome da obra</DataTitle>
                  <DataValue>{shipmentInfo.constructionName}</DataValue>
                </DataCard>
              </Col>
            </Row>

            <Row>
              <Col>
                <DataCard>
                  <DataTitle>Código do equipamento</DataTitle>
                  <DataValue>{shipmentInfo.equipmentCode}</DataValue>
                </DataCard>
              </Col>

              <Col>
                <DataCard>
                  <DataTitle>Placa do equipamento</DataTitle>
                  <DataValue>{shipmentInfo.equipmentPlate}</DataValue>
                </DataCard>
              </Col>

              <Col>
                <DataCard>
                  <DataTitle>Nome do operador</DataTitle>
                  <DataValue>{shipmentInfo.operatorName}</DataValue>
                </DataCard>
              </Col>
            </Row>
          </React.Fragment>
        )
      }

      <Row>
        <Col>
          <DataCard>
            <DataTitle>Código do traço</DataTitle>
            <DataValue>{concreteShipment.concreteFormula.code}</DataValue>
          </DataCard>
        </Col>

        <Col>
          <DataCard>
            <DataTitle>FCK</DataTitle>
            <DataValue>{concreteShipment.concreteFormula.fck}</DataValue>
          </DataCard>
        </Col>

        <Col>
          <DataCard>
            <DataTitle>Slump</DataTitle>
            <DataValue>{`${nominal} ± ${deviation}`}</DataValue>
          </DataCard>
        </Col>

        <Col>
          <DataCard>
            <DataTitle>Tipo de brita</DataTitle>
            <DataValue>{concreteShipment.concreteFormula.concreteFamily.gravelType}</DataValue>
          </DataCard>
        </Col>
      </Row>

      <Row>
        <Col>
          <DataCard>
            <DataTitle>Data da carga</DataTitle>
            <DataValue>{formatDate(concreteShipment.insertedAt, dateMask)}</DataValue>
          </DataCard>
        </Col>

        <Col>
          <DataCard>
            <DataTitle>Início da carga</DataTitle>
            <DataValue>{formatDate(concreteShipment.insertedAt, timeMask)}</DataValue>
          </DataCard>
        </Col>

        <Col>
          <DataCard>
            <DataTitle>Equipamento pronto</DataTitle>
            <DataValue>{formatDate(concreteShipment.loadingStartTime, timeMask)}</DataValue>
          </DataCard>
        </Col>

        <Col>
          <DataCard>
            <DataTitle>Liberação da carga</DataTitle>
            <DataValue>{formatDate(concreteShipment.travelStartTime, timeMask)}</DataValue>
          </DataCard>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Title>Consumo de material</Title>

            <Table
              matchHeaders
              center={false}
              headers={locationsHeaders}
              items={shipmentLocations}
            />
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <DataCard>
            <DataTitle>Volume solicitado</DataTitle>
            <DataValue>{formatVolume(concreteShipment.quantity)}</DataValue>
          </DataCard>
        </Col>

        {/* TODO: Enable this when we are able to display this info */}
        {/* <Col>
          <DataCard>
            <DataTitle>Volume teórico</DataTitle>
            <DataValue>{formatVolume(theoreticalVolumeTotal)}</DataValue>
          </DataCard>
        </Col>

        <Col>
          <DataCard>
            <DataTitle>Volume entregue</DataTitle>
            <DataValue>{formatVolume(suppliedVolumeTotal)}</DataValue>
          </DataCard>
        </Col> */}
      </Row>

      <Row>
        <Col>
          <Card>
            <Title>Umidade dos materiais</Title>

            <Table
              matchHeaders
              center={false}
              headers={humidityHeaders}
              items={humidityMaterials}
            />
          </Card>
        </Col>

        <Col>
          <DataCard>
            <DataTitle>Corte de água</DataTitle>
            <DataValue>{formatWeight(concreteShipment.waterCut, "-", "litros")}</DataValue>
          </DataCard>
        </Col>
      </Row>
    </Container>
  );
};

export default ShipmentReport;
