import React, { useState, useEffect } from "react";
import { Button, Modal, Input } from "reactstrap";
import {
  Title,
  Subtitle,
  Label,
  Span
} from "components/StyledComponents";
import { useQuery } from "@apollo/react-hooks";
import { httpClient } from "settings/apolloClient";
import { useMutation } from '@apollo/client';
import INVENTORY_ITEMS_LOCATIONS_QUERY from "../graphql/queries/inventoryItemsLocationsQuery";
import RESOLVE_MANUAL_OPERATION_MUTATION from "../graphql/mutations/resolveManualOperationMutation";

export const ManualOperationModal = ({
  clearOperation,
  operation,
  title
}) => {
  const [variations, setVariations] = useState([]);
  const [itemLocationSensorLink, setItemLocationSensorLink] = useState([]);
  const [itemNameById, setItemNameById] = useState({});
  const [locationNameById, setLocationNameById] = useState({});
  const [quantityBySensorId, setQuantityBySensorId] = useState({});
  const [quantityByLocationId, setQuantityByLocationId] = useState({});
  const [uniqueLocationQuantity, setUniqueLocationQuantity] = useState({});
  const [reason, setReason] = useState("");
  const [resolveManualOperation] = useMutation(RESOLVE_MANUAL_OPERATION_MUTATION, { client: httpClient });

  const { data: inventoryItemsData } = useQuery(INVENTORY_ITEMS_LOCATIONS_QUERY, {
    fetchPolicy: "network-only",
    client: httpClient,
  });

  const changeLocationQuantity = (locationId, quantity) => {
    setQuantityByLocationId({
      ...quantityByLocationId,
      [locationId]: quantity,
    });
  };

  const setUniqueLocationsQuantity = () => {
    const sensorLocations = itemLocationSensorLink.reduce((acc, link) => {
      acc[link.sensorId] = acc[link.sensorId] || [];
      acc[link.sensorId].push(link.inventoryLocationId);
      return acc;
    }, {});
    const newLocationQuantity = {};
    const filteredLocationQuantity = {};
    itemLocationSensorLink.forEach((link) => {
      const locationId = link.inventoryLocationId;
      if (sensorLocations[link.sensorId].length === 1 && quantityBySensorId[link.sensorId]) {
        newLocationQuantity[locationId] = quantityBySensorId[link.sensorId];
      } else {
        filteredLocationQuantity[locationId] = quantityByLocationId[locationId] || 0;
      }
    });
    setQuantityByLocationId({
      ...filteredLocationQuantity,
      ...newLocationQuantity,
    });
  };

  const resolve = () => {
    const conciliatedVariations = itemLocationSensorLink.reduce((acc, link) => {
      if (quantityByLocationId[link.inventoryLocationId] > 0) {
        acc.push({
          sensorId: link.sensorId,
          inventoryItemId: link.inventoryItemId,
          inventoryLocationId: link.inventoryLocationId,
          quantity: quantityByLocationId[link.inventoryLocationId],
        });
      }
      return acc;
    }, []);

    resolveManualOperation({
      variables: {
        manualOperationId: operation.id,
        params: {
          variations: conciliatedVariations,
          reason,
        },
      },
    }).then(() => {
      setReason("");
    });
    clearOperation();
  };

  useEffect(() => {
    const manualSensorVariations = operation ? operation.manualSensorVariations : null;
    if (manualSensorVariations) {
      const qBSI = manualSensorVariations.reduce((acc, variation) => {
        acc[variation.sensor.id] = variation.quantity;
        return acc;
      }, {});

      setVariations(manualSensorVariations);
      setQuantityBySensorId(qBSI);
    }
  }, [operation]);

  useEffect(() => {
    const inventoryItems = inventoryItemsData ? inventoryItemsData.inventoryItems : null;
    if (inventoryItems) {
      const iLSL = inventoryItems.reduce((acc, item) => {
        const locations = item.inventoryLocations || [];
        locations.forEach((location) => {
          if (!location.sensor) {
            return;
          }
          acc.push({
            inventoryItemId: item.id,
            inventoryLocationId: location.id,
            sensorId: location.sensor.id,
          });
        });
        return acc;
      }, []);

      const iNBI = inventoryItems.reduce((acc, item) => {
        acc[item.id] = item.name;
        return acc;
      }, {});

      const lNBI = inventoryItems.reduce((acc, item) => {
        const locations = item.inventoryLocations || [];
        locations.forEach((location) => {
          acc[location.id] = location.name;
        });
        return acc;
      }, {});

      const qBLI = inventoryItems.reduce((acc, item) => {
        const locations = item.inventoryLocations || [];
        locations.forEach((location) => {
          acc[location.id] = 0.0;
        });
        return acc;
      }, {});

      setItemLocationSensorLink(iLSL);
      setItemNameById(iNBI);
      setLocationNameById(lNBI);
      setQuantityByLocationId(qBLI);
    }
  }, [inventoryItemsData]);

  useEffect(() => {
    setUniqueLocationsQuantity();
  }, [quantityBySensorId, itemLocationSensorLink])


  return (
    <React.Fragment>
      <Modal
        isOpen={operation !== null}
        size="lg"
        zIndex={2040}
      >
        <div className="modal-header default">
          <Title>{title}</Title>
          <Subtitle>Indique a quantidade total de material utilizado:</Subtitle>
        </div>
        <div className="modal-body default">
          {
            variations.map((variation) => {
              if (variation.quantity > 0) {
                return (
                  <div>
                    <Label
                      style={{
                        textAlign: "left",
                        marginBottom: "5px",
                        paddingTop: "10px",
                      }}
                    >
                      {`Sensor: ${variation.sensor.name} - Quantidade consumida: ${parseFloat(quantityBySensorId[variation.sensor.id]).toFixed(1)} ${variation.sensor.unit}`}
                    </Label>
                    {
                      itemLocationSensorLink
                        .filter((link) => link.sensorId === variation.sensor.id)
                        .map((link) => (
                          <div
                            key={link.inventoryLocationId}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "10px",
                              padding: "5px",
                            }}
                          >
                            <Span
                              style={{
                                width: "80%",
                                textAlign: "left",
                              }}
                            >
                              {`${itemNameById[link.inventoryItemId]} - ${locationNameById[link.inventoryLocationId]}`}
                            </Span>
                            <Input
                              type="number"
                              value={quantityByLocationId[link.inventoryLocationId]}
                              onChange={(e) => changeLocationQuantity(link.inventoryLocationId, e.target.value)}
                            />
                          </div>
                        ))
                    }
                  </div>
                );
              }
              return null;
            })
          }
          <Label style={{ marginBottom: 10 }}>MOTIVO DA AÇÃO</Label>
          <Input
            type="textarea"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            style={{ padding: "0.375rem 0.75rem" }}
          />
        </div>
        <div className="modal-footer default">
          <Button className="btn-info" onClick={resolve}>
            Finalizar
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
