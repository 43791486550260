import React, { Component, Fragment } from "react";
import { format } from "date-fns";
import { Subscription, compose } from "react-apollo";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { withInvoiceSubscriptionConsumer } from "contexts/InvoiceSubscriptionContext/InvoiceSubscriptionContext";
import SUBSCRIBE_TO_INVOICE_RESPONSE from "./SubscribeToInvoiceResponse";


class InvoiceResponseSubscription extends Component {
  COLORS = {
    Autorizada: Color.success,
    Cancelada: Color.success,
    Negada: Color.danger,
    CancelamentoNegado: Color.danger,
  }

  MESSAGES = ({
    reason,
    info,
  }) => ({
    Autorizada: `A nota fiscal ${info} foi autorizada com sucesso.`,
    Cancelada: `A nota fiscal ${info} foi cancelada com sucesso.`,
    Negada: `A nota fiscal ${info} foi negada. Motivo: ${reason}`,
    CancelamentoNegado: `A nota fiscal ${info} teve seu cancelamento negado`,
  })

  onSubscriptionData = ({ subscriptionData: { data: { subscribeToInvoiceResponse } } }) => {
    const {
      snackbarContext,
      invoiceSubscriptionContext: {
        afterSubscriptionData
      }
    } = this.props;
    const {
      reason,
      status,
      equipmentCode,
      osCode,
      startTime,
    } = subscribeToInvoiceResponse;

    const time = new Date(startTime);
    const info = `(OS: ${osCode} | Equipamento: ${equipmentCode} | Início: ${format(time, "HH:mm")})`;
    const color = this.COLORS[status];
    const message = this.MESSAGES({ reason, info })[status];

    snackbarContext.setNotificationTimeOut(color, message);

    if (typeof afterSubscriptionData === "function") {
      afterSubscriptionData();
    }
  }

  render() {
    return (
      <Fragment>
        <Subscription
          subscription={SUBSCRIBE_TO_INVOICE_RESPONSE}
          fetchPolicy="no-cache"
          onSubscriptionData={this.onSubscriptionData}
        />

      </Fragment>
    );
  }
}

export default compose(
  withSnackbarConsumer,
  withInvoiceSubscriptionConsumer,
)(InvoiceResponseSubscription);
