import React from "react";
import { compose, graphql } from "react-apollo";
import Table from "components/Table/Table";
import PencilButton from "components/ActionButtons/PencilButton";
import DeleteButton from "components/ActionButtons/DeleteButton";
import FamilyEdit from "components/Navbars/FamiliesAndFormulas/FamilyEdit";
import { withQuery } from "containers/withQuery";
import AlertModal from "components/AlertModal/AlertModal";
import { ErrorParagraph } from "components/StyledComponents";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";
import {
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { CONCRETE_FAMILIES_QUERY, DELETE_CONCRETE_FAMILY_MUTATION } from "./FamiliesAndFormulasQueries";
import CardHeader from "./CardHeader";

const headers = [
  {
    label: "Nome", key: "name",
  },
  {
    label: "Uso", key: "use",
  },
  {
    label: "Slump", key: "slump",
  },
  {
    label: "Cimento", key: "cement",
  },
  {
    label: "Pedra", key: "gravelType",
  },
  {
    label: "", key: "checkbox", className: "table-action"
  },
  {
    label: "", key: "edit", className: "table-action"
  },
  {
    label: "", key: "delete", className: "table-action"
  },
];

class FamiliesTable extends React.Component {
  state = {
    filterText: "",
    currentFamily: null,
  }

  onSelectFamily = currentFamily => () => {
    const slump = {
      value: currentFamily.slump.id,
      label: `${currentFamily.slump.nominal} ± ${currentFamily.slump.deviation}`,
    };
    const cementType = {
      value: currentFamily.cementType.id,
      label: currentFamily.cementType.name,
    };

    this.setState({
      currentFamily: {
        ...currentFamily,
        slump,
        cementType
      }
    });
  }

  removeCurrentFamily = () => this.setState({ currentFamily: null });

  onClickDelete = familyId => () => {
    AlertModal.fire({
      title: "Você tem certeza que deseja excluir esta família?",
      html: (
        <ErrorParagraph>
          Esta alteração não pode ser desfeita!
        </ErrorParagraph>
      ),
      type: "error",
      showCancelButton: true,
      confirmButtonColor: "#1D84C6",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Voltar",
      reverseButtons: true,
    })
      .then(result => {
        if (result.value) {
          this.onConfirmDelete(familyId);
        }
      });
  }

  onConfirmDelete = id => {
    const { deleteConcreteFamily, snackbarContext } = this.props;

    deleteConcreteFamily({
      variables: { id },
      refetchQueries: [
        { query: CONCRETE_FAMILIES_QUERY },
      ]
    })
      .then(() => {
        snackbarContext.setNotificationTimeOut(Color.success, "Família excluída com sucesso!");
      })
      .catch(error => {
        snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  mapDataToTable = (concreteFamilies, onFamilySelected) => concreteFamilies.map(family => {
    const {
      id,
      name,
      use,
      slump,
      cementType,
      gravelType
    } = family;

    return {
      id,
      name,
      use,
      slump: `${slump.nominal} ± ${slump.deviation}`,
      cement: cementType.name,
      gravelType,
      checkbox: (
        <FormGroup style={{ marginTop: "-20px" }} check>
          <Label check>
            <Input onClick={e => onFamilySelected(id, name, e.target.checked)} type="checkbox" />
            <span className="form-check-sign" />
          </Label>
        </FormGroup>
      ),
      edit: (
        <PencilButton onPencilEditClick={this.onSelectFamily(family)} />
      ),
      delete: (
        <DeleteButton onClick={this.onClickDelete(family.id)} />
      ),
    };
  });

  onChangeFilterText = e => this.setState(({ filterText: e.target.value || "" }));

  render() {
    const { filterText, currentFamily } = this.state;
    const { concreteFamilies, onFamilySelected } = this.props;

    const dataTable = this.mapDataToTable(concreteFamilies, onFamilySelected);
    const items = dataTable
      .filter(({ checkbox, ...values }) => (
        Object.values(values).some(data => data.toString().match(RegExp(filterText, "i")))
      ));

    return (
      <React.Fragment>
        <CardHeader title="Famílias" onChange={this.onChangeFilterText} />

        <Table tableName="families" headers={headers} matchHeaders items={items} />

        <FamilyEdit currentFamily={currentFamily} removeCurrentFamily={this.removeCurrentFamily} />
      </React.Fragment>
    );
  }
}

export default compose(
  withQuery({
    query: CONCRETE_FAMILIES_QUERY,
  }),
  graphql(DELETE_CONCRETE_FAMILY_MUTATION, { name: "deleteConcreteFamily", }),
  withSnackbarConsumer,
)(FamiliesTable);
