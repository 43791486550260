import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import { fetcher, endpoints } from 'utils/axios';

// ----------------------------------------------------------------------

export function useGetProcesses() {
  const URL = endpoints.processes.list;
  
  try {
    const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher);

    const memoizedValue = useMemo(
      () => ({
        processes: data || [],
        processesLoading: isLoading,
        processesError: error,
        processesValidating: isValidating,
        processesEmpty: !isLoading && !data.length,
        processesRefetch: mutate,
      }),
      [data, error, isLoading, isValidating, mutate]
    );
    return memoizedValue;
  } catch (error) {
    // Handle the error here
    console.error(error);
    return {
      processes: [],
      processesLoading: false,
      processesError: error,
      processesValidating: false,
      processesEmpty: true,
      processesRefetch: () => {},
    };
  }
};

// ----------------------------------------------------------------------

export function useGetConfigs(processCode) {
  const URL = endpoints.configs.list(processCode);

  try {
    const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher);

    const memoizedValue = useMemo(
      () => ({
        configs: data || [],
        configsLoading: isLoading,
        configsError: error,
        configsValidating: isValidating,
        configsEmpty: !isLoading && !data.length,
        configsRefetch: mutate,
      }),
      [data, error, isLoading, isValidating, mutate]
    );

    return memoizedValue;
  } catch (error) {
    // Handle the error here
    console.error(error);
    return {
      configs: [],
      configsLoading: false,
      configsError: error,
      configsValidating: false,
      configsEmpty: true,
      configsRefetch: () => {},
    };
  }
};


// ----------------------------------------------------------------------

export function useGetEstimatorsList() {
  const URL = endpoints.estimators.list;

  try {
    const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher);

    const memoizedValue = useMemo(
      () => ({
        estimators: data || [],
        estimatorsLoading: isLoading,
        estimatorsError: error,
        estimatorsValidating: isValidating,
        estimatorsEmpty: !isLoading && !data.length,
        estimatorsRefetch: mutate,
      }),
      [data, error, isLoading, isValidating, mutate]
    );
    return memoizedValue;
  } catch (error) {
    // Handle the error here
    console.error(error);
    return {
      estimators: [],
      estimatorsLoading: false,
      estimatorsError: error,
      estimatorsValidating: false,
      estimatorsEmpty: true,
      estimatorsRefetch: () => {},
    };
  };
};

// ----------------------------------------------------------------------

export function useGetEstimatorDetails(code, params) {
  const URL = endpoints.estimators.details(code);
  const swrKey = [URL, params];
  try {
    const { data, isLoading, error, isValidating, mutate } = useSWR(swrKey, fetcher);
    const estimatorData = data ? [data] : [];

    const memoizedValue = useMemo(
      () => ({
        estimatorDetails: estimatorData || [],
        estimatorDetailsLoading: isLoading,
        estimatorDetailsError: error,
        estimatorDetailsValidating: isValidating,
        estimatorDetailsEmpty: !isLoading && !estimatorData.length,
        estimatorDetailsRefetch: mutate,
      }),
      [data, error, isLoading, isValidating, mutate]
    );
    return memoizedValue;
 } catch (error) {
    // Handle the error here
    console.error(error);
    return {
      estimatorDetails: [],
      estimatorDetailsLoading: false,
      estimatorDetailsError: error,
      estimatorDetailsValidating: false,
      estimatorDetailsEmpty: true,
      estimatorDetailsRefetch: () => {},
    };
  };
};

// ----------------------------------------------------------------------

export function useGetEstimatorDefaultDetails(code) {
  const URL = endpoints.estimators.details(code);
  try {
    const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher);
    const estimatorData = data ? [data] : [];

    const memoizedValue = useMemo(
      () => ({
        estimatorDefaultDetails: estimatorData || [],
        estimatorDefaultDetailsLoading: isLoading,
        estimatorDefaultDetailsError: error,
        estimatorDefaultDetailsValidating: isValidating,
        estimatorDefaultDetailsEmpty: !isLoading && !estimatorData.length,
        estimatorDefaultDetailsRefetch: mutate,
      }),
      [data, error, isLoading, isValidating, mutate]
    );
    return memoizedValue;
  } catch (error) {
    // Handle the error here
    console.error(error);
    return {
      estimatorDefaultDetails: [],
      estimatorDefaultDetailsLoading: false,
      estimatorDefaultDetailsError: error,
      estimatorDefaultDetailsValidating: false,
      estimatorDefaultDetailsEmpty: true,
      estimatorDefaultDetailsRefetch: () => {},
    };
  };
};

// ----------------------------------------------------------------------

export function useGetQRCode() {
  const URL = endpoints.qrCode;

  try {
    const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher);

    const memoizedValue = useMemo(
      () => ({
        qrCode: data || {},
        qrCodeLoading: isLoading,
        qrCodeError: error,
        qrCodeValidating: isValidating,
        qrCodeEmpty: !isLoading && !data.length,
        qrCodeRefetch: mutate,
      }),
      [data, error, isLoading, isValidating, mutate]
    );
    return memoizedValue;
  } catch (error) {
    // Handle the error here
    console.error(error);
    return {
      qrCode: {},
      qrCodeLoading: false,
      qrCodeError: error,
      qrCodeValidating: false,
      qrCodeEmpty: true,
      qrCodeRefetch: () => {},
    };
  };
}