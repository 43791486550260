import React from "react";
import { Input } from "reactstrap";
import Select from "react-select";

import {
  TableEditContainer,
  Table,
  Header,
  Row,
  Data
} from "./tableComponents";


export default ({ tableName, headers, items, onChange, isEditable }) => (
  <TableEditContainer>
    <Table>
      <thead>
        <tr>
          {Object.keys(headers).map((id) => (
            <Header key={id}>
              <h5>{headers[id].label}</h5>
            </Header>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map(({ __typename, ...item }) => (
          <Row key={item.id}>
            {
              Object.keys(item).map((key) => {
                const header = headers[key];
                const label = item[key].label !== undefined ? item[key].label : item[key];
                return (
                  <Data key={`${tableName}-${item.id}-${key}`}>
                    {key !== "action" && isEditable && header.isEditable ? (
                      <React.Fragment>
                        {header.component === "select" && (
                          <Select
                            styles={{
                              valueContainer: () => ({
                                width: 250,
                              }),
                            }}
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="itemSelected"
                            value={item[key]}
                            onChange={value =>
                              onChange({ newValue: value, id: item.id, key })
                            }
                            options={[
                              {
                                value: "",
                                isDisabled: true
                              },
                              ...Array.isArray(header.options)
                                ? header.options
                                : header.options[item[key].optionKey] || []
                            ]}
                            placeholder=""
                          />
                        )}
                        {header.component === "text" && (
                          <Input value={label} onChange={e => {
                            const value = e.target.value;
                            if (!header.isValid || header.isValid(value)) {
                              onChange({ newValue: value, id: item.id, key })
                            }
                          }} />
                        )}
                      </React.Fragment>
                    ) : (
                      (header && header.formatter) ? header.formatter(label, item[key].unit) : label
                    )}
                  </Data>
                )
              })
            }
          </Row>
        ))}
      </tbody>
    </Table>
  </TableEditContainer>
);
