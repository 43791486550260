import { Decimal } from "decimal.js";

const format = (value, fallback, precision, unit) => {
  if (!value) {
    return fallback;
  }

  return `${Decimal(value).toFixed(precision)} ${unit}`;
};

export const formatVolume = (value, fallback = "-") => format(value, fallback, 1, "m³");

export const formatMass = (value, fallback = "-") => format(value, fallback, 3, "kg/m³");

export const formatWeight = (value, fallback = "-", unit = "kg") => format(value, fallback, 2, unit);

export const formatPercentual = (value, fallback = "-") => format(Decimal.mul(value, 100), fallback, 2, "%");
