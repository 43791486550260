import React from "react";
import CloseImg from "assets/img/close.svg";
import ReactSVG from "react-svg";
import {
  Tooltip
} from "reactstrap";
import PlaceholderImg from "assets/img/operator-placeholder.jpg";

class OperatorsList extends React.Component {
  state = {}

  toggle = tooltipId => {
    this.setState(({ [tooltipId]: isOpen }) => ({
      [tooltipId]: !isOpen
    }));
  }

  render() {
    const {
      operators, type, onClick, onDelete
    } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {operators.map(operator => {
          const tooltipId = `tooltip${operator.id}${type}`;
          return (
            <div
              key={`${operator.id}-${type}`}
              style={{ position: "relative" }}
              onClick={() => onClick && onClick(operator)}
            >
              <img
                id={tooltipId}
                alt="operator"
                src={operator.photo || PlaceholderImg}
                style={{
                  borderRadius: "50%", marginRight: "10px", width: "35px", height: "35px"
                }}
              />
              {onDelete && (
                <ReactSVG
                  src={CloseImg}
                  svgStyle={{ position: "absolute", top: "-6px", right: "0px" }}
                  onClick={event => {
                    event.stopPropagation();
                    onDelete(operator);
                  }}
                />
              )}
              <Tooltip
                placement="left"
                isOpen={this.state[tooltipId]}
                target={tooltipId}
                toggle={() => this.toggle(tooltipId)}
              >
                {operator.name}
              </Tooltip>
            </div>
          );
        })}
      </div>
    );
  }
}

export default OperatorsList;
