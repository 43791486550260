import React from "react";
import styled from "styled-components";
import statusError from "assets/img/status-error.svg";
import statusManual from "assets/img/status-warning.svg";
import statusPaused from "assets/img/status-paused.svg";
import statusRunning from "assets/img/status-running.svg";
import handclickIcon from "assets/img/handclick.svg";

const statuses = {
  ERROR: "ERROR",
  MANUAL: "MANUAL",
  PAUSED: "PAUSED",
  RUNNING: "RUNNING",
  ON: "ON",
  OFF: "OFF",
  MAINTENANCE: "MAINTENANCE",
};

const icons = {
  [statuses.ERROR]: statusError,
  [statuses.MANUAL]: statusManual,
  [statuses.PAUSED]: statusPaused,
  [statuses.RUNNING]: statusRunning,
  [statuses.MAINTENANCE]: handclickIcon,
};

const colors = {
  [statuses.ON]: "#00B630",
  [statuses.OFF]: "#FF3F3F",
  [statuses.PAUSED]: "#FFAA00",
  [statuses.RUNNING]: "#1D84C6",
  [statuses.ERROR]: "#FF3F3F",
  [statuses.MANUAL]: "#FFAA00",
  [statuses.MAINTENANCE]: "#ff7700",
};

const sizes = {
  lg: "120px",
  md: "85px",
  sm: "31px",
};

const fontSizes = {
  lg: "49px",
  md: "30px",
  sm: "10px",
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${props => sizes[props.size]};
  height: ${props => sizes[props.size]};
  border-radius: 50%;
  font-size: ${props => fontSizes[props.size]};
`;

const StatusText = styled.h1`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: ${props => fontSizes[props.size]};
  color: white;
`;

const Icon = styled.img`
  width: 45%;
  height: 45%;
`;

const StatusIcon = ({ type, size }) => (
  <Container
    size={size}
    style={{
      ...colors[type] && {
        backgroundColor: colors[type],
      },
    }}
  >
    {
      Object.keys(icons).includes(type)
        ? (
          <Icon src={icons[type]} alt={`status-${type.toLowerCase()}`} />
        )
        : (
          <StatusText size={size}>
            {type}
          </StatusText>
        )
    }
  </Container>
);

export default StatusIcon;
