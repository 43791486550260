import React, { useEffect, useCallback } from 'react';
import { useMutation } from "@apollo/client";
import { httpClient } from "settings/apolloClient";
import {
  Card,
  Col,
  Row,
} from 'reactstrap';
import styled from 'styled-components';
import PencilImg from "assets/img/pencil-edit-button.svg";
import DeleteButtonIcon from "assets/img/blue-delete-button.svg";
import { CANCEL_CONCRETE_SHIPMENT_MUTATION } from './graphql/scheduleMutations';
import AlertModal from "components/AlertModal/AlertModal";

const ShipmentCard = styled(Card)`
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #edeff1;
`;

const ActionButton = styled.button`
  border: none;
  pointer-events: auto;
  cursor: pointer;
  background-color: transparent;
  margin: 2px;
  
  &:focus {
    outline: 0;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

const InfoRow = styled(Row)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
`;

const InfoText = styled.p`
  margin: auto;
  font-weight: bold;
`;

const InfoHeader = styled.h3`
  margin: 5px 0;
`;


export function ShipmentInfo({
  refetchSequence,
  setSelectedFormula,
  setEditShipmentModalOpen,
  setSelectedShipment,
  shipment,
}) {
  const {
    code,
    concreteFormula,
    id,
    sealNumber,
    shipmentInfo,
    shipmentMaterials,
    startTime,
    waterCut,
  } = shipment;

  const {
    deviation,
    nominal,
  } = concreteFormula.concreteFamily.slump;

  const slump = `${nominal} ± ${deviation}`;

  const [cancelShipment] = useMutation(CANCEL_CONCRETE_SHIPMENT_MUTATION, { client: httpClient });

  const sandsHumidity = shipmentMaterials.reduce((acc, { humidityMeasurement }) => {
    if (Boolean(humidityMeasurement)) {
      acc.push(
        {
          value: humidityMeasurement.value,
          itemName: humidityMeasurement.inventoryItem.name,
        }
      );
    }
    return acc;
  }, []);

  const materials = shipmentMaterials.reduce((acc, { inventoryItem, shipmentMaterialLocations }) => {
    shipmentMaterialLocations.map(({ formulaWeight, inventoryLocation }) => {
      acc.push({
        materialId: shipmentMaterialLocations.id,
        materialName: `${inventoryItem.name} - ${inventoryLocation.name}`,
        materialWeight: `${Number(formulaWeight || 0).toFixed(1)}`,
      });
    });
    return acc;
  }, []);

  useEffect(() => {
    if (Boolean(shipment) && Boolean(concreteFormula)) {
      setSelectedFormula({
        value: concreteFormula.id,
        code: concreteFormula.code,
        label: `Cód. ${concreteFormula.code} | FCK ${concreteFormula.fck} | Slump ${nominal} ± ${deviation}`,
      });
    } else {
      setSelectedFormula(null);
    };
  }, [shipment]);

  const handleCancelShipment = useCallback(() => {
    cancelShipment({
      variables: {
        id,
      },
    }).then(() => {
      setSelectedShipment(null);
      refetchSequence();
    });
  }, [cancelShipment, id, refetchSequence, setSelectedShipment]);

  const handleCancel = () => {
    AlertModal.fire({
      title: "Você tem certeza que deseja cancelar este carregamento?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1D84C6",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Voltar",
      reverseButtons: true,
    })
      .then(result => {
        if (result.value) {
          handleCancelShipment();
        }
      });
  };

  return (
    <ShipmentCard key={`${id}-${code}`}>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Col
          style={{
            justifyContent: "flex-start",
          }}
        >
          <p
            style={{
              margin: "auto",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#000",
            }}
          >
            Carga
          </p>
        </Col>
        {
          !startTime && (
            <Col
              style={{
                justifyContent: "flex-end",
                textAlign: "right",
              }}
            >
              <ActionButton
                onClick={() => setEditShipmentModalOpen(true)}
                title="Editar carga"
              >
                <img src={PencilImg} alt="Editar" />
              </ActionButton>
              <ActionButton
                onClick={() => handleCancel()}
                title="Excluir carga"
              >
                <img src={DeleteButtonIcon} alt="Excluir" />
              </ActionButton>
            </Col>
          )
        }
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <Col>
          <p
            style={{
              margin: "auto",
              fontSize: "20px",
              fontWeight: 500,
              color: "#000",
            }}
          >
            {`#${code}`}
          </p>
        </Col>
      </Row>
      {
        Boolean(shipmentInfo) && (
          <React.Fragment>
            {
              Boolean(shipmentInfo.constructionName) && (
                <InfoRow>
                  <Col>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {shipmentInfo.constructionName.toUpperCase()}
                    </p>
                  </Col>
                </InfoRow>
              )
            }
            {
              Boolean(shipmentInfo.constructionAddress) && (
                <InfoRow>
                  <Col>
                    <p
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      {shipmentInfo.constructionAddress}
                    </p>
                  </Col>
                </InfoRow>
              )
            }
            {
              Boolean(shipmentInfo.contractCode) && (
                <InfoRow>
                  <Col>
                    <p
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Contrato
                    </p>
                  </Col>
                  <Col>
                    <InfoText
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {shipmentInfo.contractCode}
                    </InfoText>
                  </Col>
                </InfoRow>
              )
            }
          </React.Fragment>
        )
      }
      <InfoHeader>Concreto</InfoHeader>
      <InfoRow>
        <Col>
          <p>Traço</p>
        </Col>
        <Col>
          <InfoText>{concreteFormula.code}</InfoText>
        </Col>
      </InfoRow>
      <InfoRow>
        <Col>
          <p>Fck</p>
        </Col>
        <Col>
          <InfoText>{`${concreteFormula.fck} MPa`}</InfoText>
        </Col>
      </InfoRow>
      <InfoRow>
        <Col>
          <p>Slump</p>
        </Col>
        <Col>
          <InfoText>{slump}</InfoText>
        </Col>
      </InfoRow>
      <InfoRow>
        <Col>
          <p>Corte de água</p>
        </Col>
        <Col>
          <InfoText>{`${waterCut} L`}</InfoText>
        </Col>
      </InfoRow>
      <InfoHeader>Entrega</InfoHeader>
      {
        Boolean(shipmentInfo) && Boolean(shipmentInfo.equipmentPlate) && (
          <InfoRow>
            <Col>
              <p>Placa</p>
            </Col>
            <Col>
              <InfoText>{shipmentInfo.equipmentPlate}</InfoText>
            </Col>
          </InfoRow>
        )
      }
      <InfoRow>
        <Col>
          <p>Lacre</p>
        </Col>
        <Col>
          <InfoText>{sealNumber}</InfoText>
        </Col>
      </InfoRow>
      { sandsHumidity.length > 0 && (
        <React.Fragment>
          <InfoHeader>Umidade das Areias</InfoHeader>
          {sandsHumidity.map(({ value, itemName }) => (
            <InfoRow key={itemName}>
              <Col>
                <p>{itemName}</p>
              </Col>
              <Col>
                <InfoText>{`${parseFloat(value) * 100} %`}</InfoText>
              </Col>
            </InfoRow>
          ))}
        </React.Fragment>
      )}
      {
        materials.length > 0 && (
          <React.Fragment>
            <InfoHeader>Materiais</InfoHeader>
            {materials.map(({ materialId, materialName, materialWeight }) => (
              <InfoRow key={materialId}>
                <Col>
                  <p>{materialName}</p>
                </Col>
                <Col>
                  <InfoText>{`${materialWeight} Kg`}</InfoText>
                </Col>
              </InfoRow>
            ))}
          </React.Fragment>
        )
      }
    </ShipmentCard>
  );
};
