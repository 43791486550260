import React, { useState, useEffect } from "react";
import styled from "styled-components";


import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';

import {
  put,
  endpoints,
} from "utils/axios";


export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;


export const Title = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: #2E384D;
`;

export default function ConfigModal({
  title,
  isOpen,
  setIsOpen,
  config,
  setSelectedConfig,
}) {
  const [newConfig, setNewConfig] = useState(
    {
      data: config
    }
  );
  
  const handleInputValue = (type, value) => {
    if (type === "JSON" || type === "list") {
      return JSON.stringify(value);
    } else {
      return value;
    }
  };

  const [newValue, setNewValue] = useState(handleInputValue(config.type, config.value));

  const handleUpdate = async () => {
    try {
      await put(endpoints.configs.update(config.id), newConfig);
      setIsOpen(false);
      setSelectedConfig(null);
    } catch (error) {
      console.error(error);
      setIsOpen(false);
      setSelectedConfig(null);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    setSelectedConfig(null);
  };

  const handleChangeInput = (e) => {
    const { value } = e.target;
    setNewValue(value);
  };

  const handleChangeBool = (e) => {
    const { checked } = e.target;
    setNewValue(checked);
  };

  const renderValue = (cfg) => {
    const { type } = cfg;
    let inputType = "text";
    if (type === "bool") {
      return (
        <FormGroup check>
          <Label check>
            <Input checked={newValue} onClick={e => handleChangeBool(e)} type="checkbox" />
            <span className="form-check-sign" />
          </Label>
        </FormGroup>
      );
    } else if (type === "int" || type === "float") {
      inputType = "number";
    } else if (type === "str") {
      inputType = "text";
    } else if (type === "JSON" || type === "list") {
      inputType = "textarea";
    } else {
      inputType = "text";
    };

    return (
      <Input
        type={inputType}
        value={newValue}
        onChange={e => handleChangeInput(e)}
      />
    )
  };

  useEffect(() => {
    setNewConfig(
      {
        data: {
          ...config,
          value: newValue
        }
      }
    );
  }, [config, newValue]);

  return (
    <React.Fragment>
      { config &&
        <Modal
          isOpen={isOpen}
          size="md"
          style={{ maxWidth: "800px", width: "80%" }}
        >
          <ModalHeader style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "5px" }}>
            <Title>
              {title}
            </Title>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => handleCancel()}
            >
              <i className="nc-icon nc-simple-remove" />
            </button>
          </ModalHeader>
          <ModalBody style={{ paddingLeft: "20px", paddingRight: "20px"}}>
            <Card>
              <CardBody>
                <CardTitle>Descrição</CardTitle>
                <h5>{config.description}</h5>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>Valor</CardTitle>
                { renderValue(config) }
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter style={{ paddingLeft: "20px", paddingRight: "20px"}}>
            <Button
              style={{ paddingLeft: "100px", paddingRight: "100px", margin: 0 }}
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={() => handleUpdate()}
            >
              Salvar
            </Button>
          </ModalFooter>
        </Modal>
      }
    </React.Fragment>
  )
};
