import React from "react";
import styled from "styled-components";
import Input from "components/Input/Input";
import Modal from "components/Modal/Modal";
import Field from "components/Field/Field";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import Footer from "../modalComponents/Footer";


const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
`;

class SlumpModal extends React.Component {
  state = {
    abntValue: this.props.abntValue || 0,
    deviation: this.props.deviation || 0,
    nominal: this.props.nominal || 0,
    real: this.props.real || 0,
    specification: this.props.specification || ""
  }

  onSaveEntry = () => {
    if (this.isInvalid()) {
      this.props.snackbarContext.setNotificationTimeOut(Color.danger, "Nenhum campo pode estar em branco");
      return;
    }

    this.props.onSave(this.state);
  }

  onChange = (key, value) => this.setState({ [key]: value });

  isInvalid = () => Object.values(this.state).some(value => value === null || value === "")

  render() {
    const { isOpen, toggleModal } = this.props;
    const {
      abntValue,
      deviation,
      nominal,
      real,
      specification,
    } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggleModal={toggleModal}
        size="md"
        title={this.props.title}
        footer={<Footer onConfirm={this.onSaveEntry} />}
      >
        <Row style={{ marginTop: "0px" }}>
          <Field title="Nominal" width="100%">
            <Input
              name="nominal"
              style={{ minHeight: "40px" }}
              type="number"
              value={nominal}
              onChange={this.onChange}
            />
          </Field>
        </Row>
        <Row>
          <Field title="Desvio" width="100%">
            <Input
              name="deviation"
              style={{ minHeight: "40px" }}
              type="number"
              value={deviation}
              onChange={this.onChange}
            />
          </Field>
        </Row>
        <Row>
          <Field title="ABNT" width="100%">
            <Input
              name="abntValue"
              style={{ minHeight: "40px" }}
              type="number"
              value={abntValue}
              onChange={this.onChange}
            />
          </Field>
        </Row>
        <Row>
          <Field title="Especificação" width="100%">
            <Input
              name="specification"
              style={{ minHeight: "40px" }}
              value={specification}
              onChange={this.onChange}
            />
          </Field>
        </Row>
        <Row>
          <Field title="Real" width="100%">
            <Input
              name="real"
              style={{ minHeight: "40px" }}
              value={real}
              type="number"
              onChange={this.onChange}
            />
          </Field>
        </Row>
      </Modal>
    );
  }
}

export default withSnackbarConsumer(SlumpModal);
