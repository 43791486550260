import React, { useState, useEffect } from "react";
import {
  Input,
  Label,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import Spinner from "react-spinkit";
import styled from "styled-components";
import Table from "components/Table/Table";
import CardHeader from "./CardHeader";
import EstimateModal from "./EstimateModal";

import {
    useGetEstimatorDetails,
    useGetEstimatorDefaultDetails,
} from "api/controller-configs";

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: flex-center;
  margin: 15px;
  padding: 20px;
`;

const headers = [
  {
    label: "Medições no intervalo", key: "count",
  },
  {
    label: "Média", key: "value",
  },
  {
    label: "Desvio padrão", key: "standard_deviation",
  },
];

const defaultHeaders = [
  {
    label: "Medições válidas", key: "count",
  },
  {
    label: "Média geral", key: "value",
  },
  {
    label: "Desvio padrão", key: "standard_deviation",
  },
]


function EstimateTable({
  selectedEstimator,
}) {
  const [selectedSetpoint, setSelectedSetpoint] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const selectedCode = selectedEstimator ? selectedEstimator.code : "";

  const [selectedParams, setSelectedParams] = useState({
    params: {
      setpoint: selectedSetpoint,
    },
  });

  const [showTargetTable, setShowTargetTable] = useState(false);

  const { estimatorDetails, estimatorDetailsLoading } = useGetEstimatorDetails(selectedCode, selectedParams);
  const { estimatorDefaultDetails, estimatorDefaultDetailsLoading } = useGetEstimatorDefaultDetails(selectedCode);

  const onSelectEstimate = () => {
    setModalOpen(true);
  };


  const mapDataToTable = (items) => items.map(item => ({
    count: item.count,
    value: item.value,
    standard_deviation: item.standard_deviation,
  }));

  const [tableItems, setTableItems] = useState(mapDataToTable(estimatorDetails));

  const [defaultTableItems, setDefaultTableItems] = useState([]);

  const handleUpdateParams = () => {
    setShowTargetTable(true);
    setSelectedParams({
      params: {
        setpoint: selectedSetpoint,
      },
    });
  };

  useEffect(() => {
    setTableItems(mapDataToTable(estimatorDetails));
  }, [estimatorDetails]);

  useEffect(() => {
    setDefaultTableItems(mapDataToTable(estimatorDefaultDetails));
  }, [estimatorDefaultDetails]);

  return (
    <React.Fragment>
      <CardHeader title={`Estimativa: ${selectedEstimator.code}`} />
      { (!estimatorDefaultDetailsLoading && defaultTableItems.length > 0) &&
        <React.Fragment>
          { Boolean(selectedCode) &&
            <EstimateModal
              title={`Configuração: ${selectedCode}`}
              isOpen={modalOpen}
              setIsOpen={setModalOpen}
              estimator={selectedEstimator}
              selectedSetpoint={selectedSetpoint}
            />
          }
          <Table tableName="defaultEstimate" headers={defaultHeaders} matchHeaders items={defaultTableItems} maxHeight="800px" />
        </React.Fragment>
      }
      <Container fluid>
        <Row>
          <Col sm={12} md={6} lg={4}>
            <Label for="setpoint">Quantidade alvo</Label>
            <Input
              type="number"
              name="setpoint"
              id="setpoint"
              value={selectedSetpoint}
              onChange={e => setSelectedSetpoint(e.target.value)}
            />
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Button
              style={{ marginTop: "20px", width: "150px", float: "right" }}
              color="info"
              type="button"
              onClick={() => handleUpdateParams()}
            >
              Consultar
            </Button>
          </Col>
          <Col sm={12} md={6} lg={4}>
            <Button
              style={{ marginTop: "20px", width: "150px", float: "right" }}
              color="info"
              type="button"
              onClick={() => onSelectEstimate()}
            >
              Alterar
            </Button>
          </Col>
        </Row>
      </Container>
      { (!estimatorDetailsLoading && tableItems.length > 0 && showTargetTable) &&
        <Table tableName="estimate" headers={headers} matchHeaders items={tableItems} maxHeight="800px" />
      }
      {
        estimatorDetailsLoading && (
          <SpinnerContainer>
            <Spinner name="line-spin-fade-loader" color="green" />
          </SpinnerContainer>
        )
      }
    </React.Fragment>
  );
};

export default EstimateTable;
