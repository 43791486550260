import styled from "styled-components";
import { Card } from "reactstrap";

export const Title = styled.h3`
  font-family: 'Rubik';
  color: #2E384D;
  font-size: 16px;
  padding: 20px;
  display: inline;
  min-width: 180px;
`;

export const SearchHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 200px;
`;

export const DataTitle = styled.h3`
  font-family: 'Rubik';
  color: #2E384D;
  font-size: 14px;
`;

export const DataValue = styled.p`
  font-size: 24px;
`;

export const DataCard = styled(Card)`
  padding: 20px;
`;

export const SearchCard = styled(Card)`
  padding-right: 20px;
`;
