import React from "react";
import { compose } from "react-apollo";
import styled from "styled-components";
import Select from "react-select";
import {
  Input,
} from "reactstrap";

import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import Modal from "components/Modal/Modal";
import Field from "components/Field/Field";
import { withQuery } from "containers/withQuery";
import CreateFamilyFooter from "./CreateFamilyFooter";
import { FAMILY_CREATE_QUERY } from "./familiesAndFormulasQueries";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 35px;
`;

const initialState = {
  name: "",
  use: "",
  slump: {},
  cementType: {},
  gravelType: "",
};

class FamilyModal extends React.Component {
  state = initialState;

  componentWillReceiveProps(props) {
    const { id, ...data } = props.family || {};
    this.setState({ ...data });
  }

  onSaveEntry = () => {
    if (this.isInvalid()) {
      this.props.snackbarContext.setNotificationTimeOut(Color.danger, "Nenhum campo pode estar em branco");
      return;
    }

    const {
      cementType,
      gravelType,
      name,
      slump,
      use,
    } = this.state;
    const { onSave } = this.props;

    onSave({
      gravelType,
      name,
      cementTypeId: cementType.value,
      slumpId: slump.value,
      use,
    });
  };

  onChangeId = e => this.setState({ id: e.target.value });

  onChangeName = e => this.setState({ name: e.target.value });

  onChangeUse = e => this.setState({ use: e.target.value });

  onChangeGravelType = e => this.setState({ gravelType: e.target.value });

  isInvalid = () => Object.values(this.state).some(value => value === null || value === "" || value === {})

  render() {
    const {
      isOpen,
      toggleModal,
      slumps,
      cementTypes,
      title,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggleModal={toggleModal}
        size="lg"
        title={title}
        footer={<CreateFamilyFooter onConfirm={this.onSaveEntry} />}
      >
        <Row>
          <Field title="Nome" width="100%">
            <Input
              style={{ minHeight: "40px" }}
              onChange={this.onChangeName}
              value={this.state.name}
            />
          </Field>
        </Row>

        <Row>
          <Field title="Uso" width="45%">
            <Input
              style={{ minHeight: "40px" }}
              onChange={this.onChangeUse}
              value={this.state.use}
            />
          </Field>
          <Field title="Slump" width="45%">
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="itemSelected"
              value={this.state.slump}
              onChange={slump => this.setState({ slump })}
              options={[
                {
                  value: "",
                  isDisabled: true
                },
                ...slumps.map(({ id, nominal, deviation }) => ({ value: id, label: `${nominal} ± ${deviation}` }))
              ]}
              placeholder="Escolha um..."
            />
          </Field>
        </Row>

        <Row>
          <Field title="Cimento" width="45%">
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="itemSelected"
              value={this.state.cementType}
              onChange={cementType => this.setState({ cementType })
              }
              options={[
                {
                  value: "",
                  isDisabled: true
                },
                ...cementTypes.map(({ id, name }) => ({ value: id, label: name }))
              ]}
              placeholder="Escolha um..."
            />
          </Field>
          <Field title="Pedra" width="45%">
            <Input
              style={{ minHeight: "40px" }}
              onChange={this.onChangeGravelType}
              value={this.state.gravelType}
            />
          </Field>
        </Row>
      </Modal>
    );
  }
}

export default compose(
  withSnackbarConsumer,
  withQuery({
    noLoader: true,
    query: FAMILY_CREATE_QUERY
  }),
)(FamilyModal);
