/**
 * This function receives a name and a slump and formats
 * into a proper concrete family name.
 *
 * Example: Name (Slump) -> Família (1 ± 2)
 */
export const getConcreteFamilyName = (name, slump) => {
  if (!slump) {
    return name;
  }

  return `${name} (${slump.nominal} ± ${slump.deviation})`;
};

/**
 * This function receives an array of concrete families, and converts
 * into select options.
 */
const getConcreteFamiliesOptions = (families) => (
  families.map(({ id, name, slump }) => ({
    value: id,
    label: getConcreteFamilyName(name, slump),
  }))
);

export default getConcreteFamiliesOptions;
