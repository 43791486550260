import React from "react";
import ReactSVG from "react-svg";

import BlueTruck from "assets/img/blue-truck.svg";
import GreenTruck from "assets/img/green-truck.svg";
import RedTruck from "assets/img/red-truck.svg";
import "./TruckCard.scss";

const shippingStatus = {
  WAITING: { statusTruck: "Aguardando Caminhão", truck: RedTruck },
  LOADING: { statusTruck: "Caminhão Carregando", truck: BlueTruck },
  FINISHED: { statusTruck: "Concluído", truck: GreenTruck },
};

const TruckCard = ({ status }) => (
  <div className="truck-container">
    <div className="svg-container">
      <ReactSVG
        src={shippingStatus[status].truck}
        svgClassName="svg-truck"
      />
    </div>
    <h5 className="truck-text">
      {shippingStatus[status].statusTruck}
    </h5>
  </div>
);

export default TruckCard;
