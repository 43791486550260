import React from "react";
import InventoryItems from "./InventoryItems";
import InventoryEntriesTable from "./InventoryEntriesTable";

const StockMaterials = () => (
  <div className="content">
    <InventoryItems />
    <InventoryEntriesTable />
  </div>
);

export default StockMaterials;
