import React, { Component } from "react";
import Table from "components/Table/Table";
import { withQuery } from "containers/withQuery";
import PencilButton from "components/ActionButtons/PencilButton";
import { SUPPLIERS_QUERY } from "../generalRegisterQueries";
import SupplierEdit from "./SupplierEdit";

const headers = [
  {
    label: "Nome", key: "name",
  },
  {
    label: "Telefone", key: "phone",
  },
  {
    label: "Email", key: "email",
  },
  {
    label: "", key: "action"
  }
];

class SuppliersTable extends Component {
  state = {
    currentEditId: null,
  }

  onFinishEdit = () => this.setState({ currentEditId: null });

  render() {
    const { currentEditId } = this.state;
    return (
      <React.Fragment>
        <Table
          headers={headers}
          matchHeaders
          items={this.props.suppliers.map(supplier => ({
            ...supplier,
            action: (
              <PencilButton
                onPencilEditClick={() => this.setState({
                  currentEditId: supplier.id
                })}
              />
            )
          }))}
        />
        {currentEditId !== null && (
          <SupplierEdit id={currentEditId} onFinishEdit={this.onFinishEdit} />
        )}
      </React.Fragment>
    );
  }
}

export default withQuery({ query: SUPPLIERS_QUERY })(SuppliersTable);
