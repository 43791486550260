import React from "react";

const InvoiceSubscriptionContext = React.createContext({});

export const InvoiceSubscriptionProvider = InvoiceSubscriptionContext.Provider;

export const withInvoiceSubscriptionConsumer = Component => {
  const Consumer = props => (
    <InvoiceSubscriptionContext.Consumer>
      { context => <Component {...context} {...props} /> }
    </InvoiceSubscriptionContext.Consumer>
  );
  return Consumer;
};
