import React from "react";
import { compose, graphql } from "react-apollo";
import gql from "graphql-tag";
import { withCookies } from "react-cookie";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { withRouter } from "react-router-dom";
import { isEmail } from "utils/validateEmail.js";
import { TOKEN_KEY } from "utils/auth.js";
import { withQuery } from "containers/withQuery";
import translateErrorMessage from "utils/translateErrorMessage";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";

import BackgroundImg from "assets/img/bg/login.jpg";
import LogoLogin from "assets/img/logo-login.svg";
import EyeOpened from "assets/img/eye-opened.svg";
import EyeClosed from "assets/img/eye-closed.svg";
import ReactSVG from "react-svg";
import { LOGIN_MUTATION } from "./loginMutations.jsx";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    eye: false,
  }

  loginHandler = event => {
    event.preventDefault();
    const { email, password } = this.state;
    const {
      isErpAvailable,
      loginMutation,
      snackbarContext,
      cookies,
      history,
    } = this.props;

    loginMutation({
      variables: {
        email: isErpAvailable ? email : undefined,
        password,
      }
    })
      .then((res) => {
        cookies.set(TOKEN_KEY, res.data.login.token, { path: "/" });
        history.push("/");
      })
      .catch(error => snackbarContext.setNotificationTimeOut(
        Color.danger,
        translateErrorMessage(error)
      ));
  }

  onChangeField = key => e => {
    e.persist();
    this.setState(() => (
      {
        [key]: e.target.value,
      }));
  }

  render() {
    const { isErpAvailable } = this.props;
    const areFieldsValid = isErpAvailable ? (isEmail(this.state.email) && this.state.password) : this.state.password;

    return (
      // eslint-disable-next-line react/no-string-refs
      <div className="wrapper wrapper-full-page" ref="fullPages">
        <div className="full-page section-image">
          <div className="login-page">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                  <Form action="" className="form" method="">
                    <Card className="card-login">
                      <CardHeader>
                        <CardHeader style={{ textAlign: "center" }}>
                          <ReactSVG
                            svgStyle={{
                              width: "200px",
                              height: "90px",
                            }}
                            src={LogoLogin}
                          />
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                        {isErpAvailable && (
                          <InputGroup>
                            <Input
                              style={{ fontFamily: "Rubik" }}
                              value={this.state.email}
                              onChange={this.onChangeField("email")}
                              placeholder="Email"
                              type="text"
                            />
                          </InputGroup>
                        )}
                        {/* div with o margin padding and border but with white background */}
                        <div style={{ padding: 0, margin: 0, background: "#FFF" }}>
                          <InputGroup>
                            <div style={{ display: 'flex', alignItems: 'center', width: "100%" }}>
                              <Input
                                style={{ fontFamily: "Rubik" }}
                                placeholder="Senha"
                                value={this.state.password}
                                onChange={this.onChangeField("password")}
                                type={this.state.eye ? "text" : "password"}
                                autoComplete="off"
                                width="80%"
                              />
                              <div
                                style={{
                                  padding: 0,
                                  paddingLeft: 2,
                                  margin: 0,
                                  background: "#FFF",
                                  '&:hover': {
                                    backgroundColor: '#FFF !important',
                                  },
                                  '&:active': {
                                    backgroundColor: '#FFF !important',
                                    outline: 'none !important', // Remove outline on focus as well
                                  },
                                  cursor: 'pointer',
                                }}
                                onClick={() => this.setState({ eye: !this.state.eye })}
                              >
                                <ReactSVG
                                  svgStyle={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                  src={this.state.eye ? EyeOpened : EyeClosed}
                                />
                              </div>
                            </div>
                          </InputGroup>
                        </div>
                      </CardBody>
                      <CardFooter>
                        <Button
                          block
                          type="submit"
                          disabled={!areFieldsValid}
                          color="info"
                          onClick={(e) => this.loginHandler(e)}
                        >
                          Entrar
                        </Button>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
            <div
              className="full-page-background"
              style={{
                backgroundImage: `url(${BackgroundImg})`
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withCookies,
  graphql(LOGIN_MUTATION, { name: "loginMutation", }),
  withQuery({ query: gql` { isErpAvailable } ` }),
  withSnackbarConsumer,
)(Login);
