import React from "react";
import Slider from "rc-slider";

import "rc-slider/assets/index.css";
import styled from "styled-components";

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
  margin: 0;
`;

const SliderContainer = styled.div`
  margin-top: 32px;
`;

const Percentage = styled.p`
  font-size: 21px;
  margin: 5px;
`;

const SandSlider = ({ ...props }) => (
  <React.Fragment>
    <InfoContainer>
      <h1>
        {props.title}
      </h1>
      <Percentage>
        {` ${props.value}%`}
      </Percentage>
    </InfoContainer>
    <SliderContainer>
      <Slider
        value={props.value}
        vertical={false}
        step={0.5}
        onChange={e => props.onChangeSlider(e)}
        max={20}
      />
    </SliderContainer>
  </React.Fragment>
);

export default SandSlider;
