import gql from "graphql-tag";

export const CREATE_CONCRETE_FAMILY_MUTATION = gql`
  mutation($params: ConcreteFamilyParams!) {
    createConcreteFamily(params: $params) {
      id
    }
  }
`;

export const UPDATE_CONCRETE_FAMILY_MUTATION = gql`
  mutation($id: Int!, $params: ConcreteFamilyParams!) {
    updateConcreteFamily(id: $id, params: $params) {
      id
    }
  }
`;
