import React, { useState } from "react";
import { SensorSubscription } from "./SensorSubscriptionWrapper.jsx";
import "./sensors.scss";
import scales from "./scale-weight.svg";


export const Sensors = ({ sensorsList, controlMenuOpen }) => {
  const [showScales, setShowScales] = useState(false);
  return (
    <div className="sensor-container"  style={(showScales && controlMenuOpen) ? {transform: 'translate(-230px, 260px)', maxHeight:`calc(95vh - 380px)`} : showScales ? {transform: 'translate(-230px)'} : controlMenuOpen ? {transform: 'translate(0,260px)',maxHeight:`calc(95vh - 380px)`} : {}}>
      <div className="scale-icon" onClick={() => setShowScales(!showScales)}>
        <img src={scales} alt="Scale icon" width="40px" height="40px" />
      </div>
      <div className="scales-container">
        <p>BALANÇAS</p>
        <div className="scales-list" style={controlMenuOpen ? {maxHeight:`calc(95vh - 460px)`} : {}}>
          { sensorsList.map(({ id, name, unit }) => <SensorSubscription id={id} name={name} unit={unit} />)}
        </div>
      </div>
    </div>
  );
};
