import React from "react";
import gql from "graphql-tag";
import { withRouter } from "react-router-dom";
import {
  compose,
} from "recompose";
import { withCookies } from "react-cookie";
import { ApolloProvider } from "react-apollo";
import {
  createSplitClient,
  httpClient
} from "settings/apolloClient.jsx";
import Spinner from "react-spinkit";
import styled from "styled-components";
import { TOKEN_KEY } from "./auth";


const SpinnerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 15px;
  padding: 20px;
`;

const LOGIN_PATH = "/login";
const CURRENT_USER_QUERY = gql`
  query {
    getCurrentUser {
      email
      name
      role
    }
  }
`;

const IS_SCHEDULE_ENABLED_QUERY = gql`
query {
  isScheduleEnabled
}
`;

const withApolloProvider = Component => class FetchCurrentUser extends React.Component {
  state = {
    userClient: null,
    currentUser: null,
    loading: true,
    isScheduleEnabled: false,
  };

  componentDidMount() {
    const token = this.props.cookies.get(TOKEN_KEY, { path: "/" });
    this.getIsScheduleEnabled();
    if (token) {
      this.getCurrentUser(token);
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillReceiveProps({ cookies }) {
    const token = cookies.get(TOKEN_KEY, { path: "/" });
    if (token && !this.state.currentUser) {
      this.getCurrentUser(token);
    }

    if (this.state.userClient && !token) {
      this.setState({ userClient: null, currentUser: null });
    }
  }

  createUserClient = token => ({ data: { getCurrentUser } }) => {
    const newUserClient = createSplitClient(token);
    this.setState({ loading: false, currentUser: getCurrentUser, userClient: newUserClient },
      () => {
        if (this.props.history.location.pathname === LOGIN_PATH) {
          this.props.history.push("/admin/dashboard");
        }
      });
  }

  getCurrentUser = token => {
    this.setState({ loading: true }, () => {
      httpClient.query({ query: CURRENT_USER_QUERY })
        .then(this.createUserClient(token))
        .catch(() => {
          this.props.cookies.remove(TOKEN_KEY, { path: "/" });
        });
    });
  }

  getIsScheduleEnabled = () => {
    httpClient.query({ query: IS_SCHEDULE_ENABLED_QUERY })
      .then(({ data }) => this.setState({ isScheduleEnabled: data.isScheduleEnabled }))
      .catch(() => this.setState({ isScheduleEnabled: false }));
  }

  render() {
    const { cookies, ...props } = this.props;

    if (this.state.loading) {
      return (
        <SpinnerContainer>
          <Spinner name="line-spin-fade-loader" color="green" />
        </SpinnerContainer>
      );
    }

    return (
      <ApolloProvider client={this.state.userClient || httpClient}>
        <Component {...props} {...this.state} />
      </ApolloProvider>
    );
  }
};

export const withAuthentication = compose(
  withRouter,
  withCookies,
  withApolloProvider,
);

export default withAuthentication;
