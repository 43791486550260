/**
 * This function converts arrays into objects
 *
 * @param {*} array  An array of objects
 * @param {*} keyExtractor A function to extract the key
 * @param {*} contentExtractor A function to extract extra content
 */
export const convertArrayToObject = (array, keyExtractor, contentExtractor) => (
  array.reduce((acc, step) => ({
    ...acc,
    [keyExtractor(step)]: {
      ...step,
      ...contentExtractor ? contentExtractor(step) : {}
    }
  }), {})
);
