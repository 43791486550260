
import React from "react";
import { Card } from "reactstrap";

import {
  PlantContainer,
  PlantContent,
  PlantTitle,
  PlantCard,
  ArrowTop,
  ArrowBottom,
  ArrowLeft,
  ArrowRight
} from "components/StyledComponents";

import StopButton from "../ActionButton/StopButton";
import BoostButton from "../ActionButton/BoostButton";
import PauseButton from "../ActionButton/PauseButton";
import MaintenanceButton from "../ActionButton/MaintenanceButton";
import ActuatorSubscriptionWrapper from "../Actuators/ActuatorSubscriptionWrapper.jsx";
import ProgressSubscriptionWrapper from "../Materials/ProgressSubscriptionWrapper.jsx";
import Truck from "../Truck/Truck.jsx";
import ProgressAggregate from "../Materials/ProgressAggregate";
import AutomationStatus from "../AutomationStatus/AutomationStatus";
import AppQrCode from "../AppQrCode/AppQrCode";
import "./PlantLocation.scss";

const TITLED_TYPES = ["ACTUATOR", "AGGREGATES", "GENERAL", "SENSOR"];

const BUTTONS = {
  BOOST: BoostButton,
  STOP: StopButton,
  PAUSE: PauseButton,
  MAINTENANCE: MaintenanceButton,
};

const ARROW_CONTAINERS = {
  TOP: ArrowTop,
  BOTTOM: ArrowBottom,
  LEFT: ArrowLeft,
  RIGHT: ArrowRight,
};

const PanelCardContainer = ({
  top,
  left,
  children,
  title,
  isVertical,
  arrowPosition,
  screenWidth,
  isButton,
}) => {
  const Container = ARROW_CONTAINERS[arrowPosition];

  const content = (
    <React.Fragment>
      {
        title
        && (
          <PlantTitle isVertical={isVertical}>
            {title.toUpperCase()}
          </PlantTitle>
        )
      }
      <PlantContainer isVertical={isVertical} isButton={isButton}>
        {children}
      </PlantContainer>
    </React.Fragment>
  );

  return (
    <PlantCard top={top} left={left}>
      <Container screenWidth={screenWidth}>
        {
          isButton
            ? content
            : (
              <Card>
                {content}
              </Card>
            )
        }
      </Container>
    </PlantCard>
  );
};

class PlantLocation extends React.Component {
  renderActuator = () => {
    const {
      automationStatus,
      plantLocation: {
        actuators,
        isContainerVertical,
        numberOfColumns,
      },
    } = this.props;

    return (
      <PlantContent
        cols={numberOfColumns}
        isContainerVertical={isContainerVertical}
      >
        {
          actuators.map(({
            id,
            name,
            status,
            type,
          }) => (
            <ActuatorSubscriptionWrapper
              automationStatus={automationStatus}
              key={id}
              id={id}
              name={name}
              numberOfColumns={numberOfColumns}
              status={status}
              type={type}
            />
          ))
        }
      </PlantContent>
    );
  }

  renderAggregates = () => {
    const {
      loadingShipment,
      plantLocation: {
        numberOfColumns,
        inventoryItems,
        isContainerVertical,
      }
    } = this.props;
    const loadingShipmentId = loadingShipment && loadingShipment.id;

    return (
      <ProgressAggregate
        inventoryItems={inventoryItems}
        concreteShipmentId={loadingShipmentId}
        numberOfColumns={numberOfColumns}
        isContainerVertical={isContainerVertical}
      />
    );
  }

  renderGeneral = () => {
    const {
      automationStatus,
      loadingShipment,
      plantLocation: {
        isContainerVertical,
        inventoryItems,
        numberOfColumns,
        actuators
      }
    } = this.props;
    const loadingShipmentId = loadingShipment && loadingShipment.id;

    return (
      <React.Fragment>
        <PlantContent
          cols={1}
          isContainerVertical={isContainerVertical}
        >
          {
            inventoryItems.map(item => (
              <ProgressSubscriptionWrapper
                key={item.id}
                inventoryItem={item}
                concreteShipmentId={loadingShipmentId}
                numberOfColumns={numberOfColumns}
              />
            ))
          }
        </PlantContent>
        <PlantContent
          cols={numberOfColumns}
          isContainerVertical={isContainerVertical}
        >
          {
            actuators.map(({
              id,
              name,
              status,
              type,
            }) => (
              <ActuatorSubscriptionWrapper
                automationStatus={automationStatus}
                key={id}
                id={id}
                name={name}
                numberOfColumns={numberOfColumns}
                status={status}
                type={type}
              />
            ))
          }
        </PlantContent>
      </React.Fragment>
    );
  }

  renderSensor = () => {
    const {
      loadingShipment,
      plantLocation: {
        isContainerVertical,
        inventoryItems,
        numberOfColumns
      }
    } = this.props;
    const loadingShipmentId = loadingShipment && loadingShipment.id;

    return (
      <PlantContent
        cols={1}
        isContainerVertical={isContainerVertical}
      >
        {
          inventoryItems.map(item => (
            <ProgressSubscriptionWrapper
              key={item.id}
              inventoryItem={item}
              concreteShipmentId={loadingShipmentId}
              numberOfColumns={numberOfColumns}
            />
          ))
        }
      </PlantContent>
    );
  }

  renderTruck = () => {
    const { loadingShipment } = this.props;
    return (
      <PlantContent>
        <Truck loadingShipment={loadingShipment} />
      </PlantContent>
    );
  }

  renderStatus = () => {
    const { automationStatus } = this.props;
    return (
      <PlantContent>
        <AutomationStatus automationStatus={automationStatus} />
      </PlantContent>
    );
  }

  renderAppQrCode = () => <AppQrCode />;

  renderButton = (type) => {
    const {
      automationStatus,
      loadingShipment,
    } = this.props;
    const loadingShipmentId = loadingShipment && loadingShipment.id;
    const Button = BUTTONS[type];

    if (Button) {
      return (
        <Button
          hasShipment={!!loadingShipmentId}
          automationStatus={automationStatus}
        />
      );
    }

    return null;
  }

  render() {
    const {
      plantLocation,
    } = this.props;
    const {
      id,
      name,
      locationType,
      positionX,
      positionY,
      isContainerVertical,
      arrowPosition,
    } = plantLocation;
    const LOCATION_TYPES = {
      ACTUATOR: this.renderActuator(),
      AGGREGATES: this.renderAggregates(),
      GENERAL: this.renderGeneral(),
      SENSOR: this.renderSensor(),
      TRUCK: this.renderTruck(),
      PAUSE: this.renderButton(locationType),
      BOOST: this.renderButton(locationType),
      STOP: this.renderButton(locationType),
      STATUS: this.renderStatus(),
      MAINTENANCE: this.renderButton(locationType),
      APP: this.renderAppQrCode(),
    };

    return (
      <React.Fragment key={id}>
        <PanelCardContainer
          left={positionX}
          top={positionY}
          title={TITLED_TYPES.find(type => type === locationType) && name}
          isButton={Object.keys(BUTTONS).find(type => type === locationType)}
          isVertical={isContainerVertical}
          arrowPosition={arrowPosition}
          screenWidth={this.props.width}
        >
          {LOCATION_TYPES[locationType]}
        </PanelCardContainer>
      </React.Fragment>
    );
  }
}

export default PlantLocation;
