import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

const Title = styled.p`
  font-size: 14px;
  color: #8798AD;
  font-family: "Rubik";
`;

const Value = styled.p`
  font-size: 16px;
  color: #2E384D;
  font-family: "Rubik";
`;

const LocationCard = ({ title, value }) => (
  <Container>
    <Title>{title}</Title>
    <Value>{value}</Value>
  </Container>
);

export default LocationCard;
