import React from "react";
import { compose, graphql } from "react-apollo";

import { INVENTORY_ENTRIES_QUERY, INVENTORY_ITEMS_QUERY } from "views/StockMaterials/stockMaterialsQueries";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";
import { UPDATE_INVENTORY_ENTRY_MUTATION } from "./stockInputMutations";
import StockInputModal from "./StockInputModal";

class StockInputCreate extends React.Component {
  onSubmit = params => {
    const {
      entry,
      updateInventoryEntry,
      clearEntry,
      snackbarContext,
    } = this.props;

    updateInventoryEntry({
      variables: {
        id: entry.id,
        params,
      },
      refetchQueries: [
        { query: INVENTORY_ENTRIES_QUERY },
        { query: INVENTORY_ITEMS_QUERY }
      ]
    })
      .then(() => {
        snackbarContext.setNotificationTimeOut(Color.success, "Entrada de estoque criada com sucesso.");
        clearEntry();
      })
      .catch(error => {
        snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const {
      entry,
      clearEntry,
    } = this.props;

    return (
      <StockInputModal
        title="Editar entrada de estoque"
        onSubmit={this.onSubmit}
        isOpen={entry !== null}
        toggleModal={clearEntry}
        entry={entry}
      />
    );
  }
}

export default compose(
  graphql(UPDATE_INVENTORY_ENTRY_MUTATION, { name: "updateInventoryEntry", }),
  withSnackbarConsumer,
)(StockInputCreate);
