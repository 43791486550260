import React from "react";
import { compose, graphql } from "react-apollo";
import { withQuery } from "containers/withQuery";

import translateErrorMessage from "utils/translateErrorMessage";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { CEMENT_TYPE_QUERY, CEMENT_TYPES_QUERY } from "../generalRegisterQueries";
import { UPDATE_CEMENT_TYPE_MUTATION } from "../generalRegisterMutations";
import CementTypeModal from "./CementTypeModal";

class CementTypeEdit extends React.Component {
  onSave = cementType => {
    this.props.updateCementType({
      variables: {
        id: this.props.id,
        params: cementType
      },
      refetchQueries: [{
        query: CEMENT_TYPES_QUERY
      }]
    })
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Tipo de cimento atualizado.");
        this.props.onFinishEdit();
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const { cementType, id } = this.props;
    return (
      <CementTypeModal
        toggleModal={this.props.onFinishEdit}
        isOpen={id !== null}
        onSave={this.onSave}
        title="Editar tipo de cimento"
        {...cementType}
      />
    );
  }
}

export default compose(
  withQuery(({ id }) => ({
    query: CEMENT_TYPE_QUERY,
    variables: {
      id,
    },
    noLoader: true,
  })),
  graphql(UPDATE_CEMENT_TYPE_MUTATION, { name: "updateCementType", }),
  withSnackbarConsumer,
)(CementTypeEdit);
