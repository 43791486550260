import React, { useState, useEffect } from "react";
import {
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import Spinner from "react-spinkit";
import styled from "styled-components";
import Table from "components/Table/Table";
import CardHeader from "./CardHeader";

import {
  useGetEstimatorsList,
} from "api/controller-configs";

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: flex-center;
  margin: 15px;
  padding: 20px;
`;

const headers = [
  {
    label: "", key: "radio", className: "table-action"
  },
  {
    label: "Código", key: "code",
  },
];


function EstimatorsTable({
  selectedEstimator,
  setSelectedEstimator,
}) {
  const { estimators, estimatorsLoading } = useGetEstimatorsList();

  const [selectedCode, setSelectedCode] = useState(null);

  const handleRadioClick = (estimator) => {
    setSelectedEstimator(estimator);
  };

  const mapDataToTable = estimators => estimators.map(estimator => ({
    radio: (
      <FormGroup style={{ paddingLeft: "30px", marginTop: "-20px" }} check>
        <Label check>
          <Input onClick={() => handleRadioClick(estimator)} type="checkbox" checked={selectedCode === estimator.code} />
          <span className="form-check-sign" />
        </Label>
      </FormGroup>
    ),
    code: estimator.code,
  }));

  const [tableItems, setTableItems] = useState(mapDataToTable(estimators));

  useEffect(() => {
    setTableItems(mapDataToTable(estimators));
  }, [estimators, mapDataToTable]);

  useEffect(() => {
    if (selectedEstimator) {
      setSelectedCode(selectedEstimator.code);
    }
  }, [selectedEstimator]);


  return (
    <React.Fragment>
      <CardHeader title="Estimadores" />
      {
        estimatorsLoading && (
          <SpinnerContainer>
            <Spinner name="line-spin-fade-loader" color="green" />
          </SpinnerContainer>
        )
      }
      { !estimatorsLoading && tableItems.length === 0 && <p>Nenhum estimador encontrado.</p> }
      { !estimatorsLoading && tableItems.length > 0 && 
        <Table tableName="estimators" headers={headers} matchHeaders items={tableItems} maxHeight="1200px" />
      }
    </React.Fragment>
  );
};

export default EstimatorsTable;
