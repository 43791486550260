import React from "react";
import styled from "styled-components";
import ReactSVG from "react-svg";
import PencilImg from "assets/img/pencil-edit-button.svg";

const PencilButton = styled.button`
  border: none;
  pointer-events: auto;
  cursor: pointer;
  background-color: transparent;
  &:focus {
    outline: 0;
  }
`;

export default ({ onPencilEditClick }) => (
  <PencilButton onClick={onPencilEditClick} title="Editar">
    <ReactSVG src={PencilImg} />
  </PencilButton>
);
