import styled from "styled-components";

export const InfoContainer = styled.div`
  text-align: left;
  /* border: 1px solid red; */
  width: 50%;
`;

export const InfoText = styled.p`
  margin-top: 7px;
`;

export const SimpleDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const EquipmentContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  width: 50%;
  /* border: 1px solid yellow; */
`;

export const TruckContainer = styled.div`
  display: flex;
  align-self: center;
  margin-left: 15px;
`;

export const EquipmentCode = styled.p`
  margin-top: 7px;
`;

export const Title = styled.h2`
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  font-family: "Rubik";
  color: #2E384D;
  margin-bottom: 20px;
`;

export const OperatorContainer = styled.div`
  width: 50%;
  /* border: 1px solid blue; */
`;

export const TeamContainer = styled.div`
  width: 50%;
  /* border: 1px solid purple; */
`;

export const AvatarsContainer = styled.div`
  display: flex;
  flex-direction: row;

  padding-top: 10px;
`;

export const MaterialBoxesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
`;
