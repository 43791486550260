import React from "react";
import { compose, graphql } from "react-apollo";
import { withQuery } from "containers/withQuery";

import translateErrorMessage from "utils/translateErrorMessage";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import { SLUMP_QUERY, SLUMPS_QUERY } from "../generalRegisterQueries";
import { UPDATE_SLUMP_MUTATION } from "../generalRegisterMutations";
import SlumpModal from "./SlumpModal";

class SlumpEdit extends React.Component {
  onSave = ({
    abntValue,
    deviation,
    nominal,
    real,
    specification,
  }) => {
    this.props.updateSlump({
      variables: {
        id: this.props.id,
        params: {
          abntValue: parseInt(abntValue, 10),
          deviation: parseInt(deviation, 10),
          nominal: parseInt(nominal, 10),
          real: parseInt(real, 10),
          specification
        }
      },
      refetchQueries: [{
        query: SLUMPS_QUERY
      }]
    })
      .then(() => {
        this.props.snackbarContext.setNotificationTimeOut(Color.success, "Slump atualizado.");
        this.props.onFinishEdit();
      })
      .catch(error => {
        this.props.snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  }

  render() {
    const { slump, id } = this.props;
    return (
      <SlumpModal
        toggleModal={this.props.onFinishEdit}
        isOpen={id !== null}
        onSave={this.onSave}
        title="Editar slump"
        {...slump}
      />
    );
  }
}

export default compose(
  withQuery(({ id }) => ({
    query: SLUMP_QUERY,
    variables: {
      id,
    },
    noLoader: true,
  })),
  graphql(UPDATE_SLUMP_MUTATION, { name: "updateSlump", }),
  withSnackbarConsumer,
)(SlumpEdit);
