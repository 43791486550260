import React from "react";
import { graphql, compose } from "react-apollo";

import cancelIcon from "assets/img/cancel-button.svg";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";

import STOP_PROCESS_MUTATION from "./graphql/stopProcessMutation";
import ActionButton from "./ActionButton";

const StopButton = ({
  hasShipment,
  stopShipment,
  snackbarContext,
}) => {
  const onConfirm = () => {
    stopShipment()
      .then(() => {
        snackbarContext.setNotificationTimeOut(Color.success, "Cancelando carregamento...");
      })
      .catch((error) => {
        snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
      });
  };

  return (
    <ActionButton
      icon={cancelIcon}
      label="Cancelar"
      onConfirm={onConfirm}
      classes="stop"
      action="cancelar"
      active={false}
      disabled={!hasShipment}
    />
  );
};

export default compose(
  withSnackbarConsumer,
  graphql(STOP_PROCESS_MUTATION, { name: "stopShipment" }),
)(StopButton);
