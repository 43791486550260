import gql from "graphql-tag";

export const FAMILY_CREATE_QUERY = gql`
  query {
    slumps {
      id
      nominal
      deviation
    }
    cementTypes {
      id
      name
    }
  }
`;

export const SUPPLIERS_QUERY = gql`
  query {
    suppliers {
      id
      name
    }
  }
`;
