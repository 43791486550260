import React, { useEffect, useState } from "react";
import Spinner from "react-spinkit";
import styled from "styled-components";
import PencilButton from "components/ActionButtons/PencilButton";
import Table from "components/Table/Table";
import CardHeader from "./CardHeader";
import ConfigModal from "./ConfigModal";

import {
  useGetConfigs,
} from "api/controller-configs";


const SpinnerContainer = styled.div`
  display: flex;
  justify-content: flex-center;
  margin: 15px;
  padding: 20px;
`;

const headers = [
  {
    label: "Nome", key: "name",
  },
  {
    label: "Código", key: "code",
  },
  {
    label: "Descrição", key: "description",
  },
  {
    label: "Valor atual", key: "value",

  },
  {
    label: "", key: "edit", className: "table-action"
  },
];

function parseData(value) {
  try {
    return JSON.stringify(value);
  } catch (e) {
    return value;
  }
};


function ConfigsTable({
  processName,
  processCode,
}) {

  const { configs, configsLoading, configsRefetch } = useGetConfigs(processCode);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(null);

  const onSelectConfig = config => () => {
    setModalOpen(true);
    setSelectedConfig(config);
  };

  const mapDataToTable = cfgs => cfgs.map(config => ({
    name: config.name,
    code: config.code,
    description: config.description,
    value: parseData(config.value),
    edit: (
      <PencilButton onPencilEditClick={onSelectConfig(config)} />
    ),
  }));

  const [tableItems, setTableItems] = useState(mapDataToTable(configs));

  useEffect(() => {
    configsRefetch();
  }, [modalOpen, configsRefetch]);

  useEffect(() => {
    setTableItems(mapDataToTable(configs));
  }, [configs]);

  return (
    <React.Fragment>
      <CardHeader title={`Configurações do Processo: ${processName}`} />
      {
        configsLoading && (
          <SpinnerContainer>
            <Spinner name="line-spin-fade-loader" color="green" />
          </SpinnerContainer>
        )
      }
      { !configsLoading && tableItems.length === 0 && <p>Nenhuma configuração encontrada.</p> }
      { !configsLoading && tableItems.length > 0 && 
        <React.Fragment>
          <Table tableName="processes" headers={headers} matchHeaders items={tableItems} maxHeight="1200px" />
          { Boolean(selectedConfig) &&
            <ConfigModal
              title={`Configuração: ${selectedConfig ? selectedConfig.name : ""}`}
              isOpen={modalOpen}
              setIsOpen={setModalOpen}
              config={selectedConfig}
              setSelectedConfig={setSelectedConfig}
            />
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ConfigsTable;
