import React, { Component, Fragment } from "react";
import { Subscription } from "react-apollo";
import { withTruckStateConsumer, TruckState } from "contexts/TruckContext/TruckStateContext.jsx";
import AlertModal from "components/AlertModal/AlertModal";
import SUBSCRIBE_TO_PROCESS_FINISHED from "./graphql/subscriptions/subscribeToProcessFinished.js";

class ProcessFinishedContent extends Component {
  onSubscriptionData = () => {
    const {
      onFinish,
      truckStateContext,
      refetchConcreteShipmentQuery
    } = this.props;

    refetchConcreteShipmentQuery();

    truckStateContext.setTruckState(TruckState.WAITING);

    AlertModal.fire({
      title: "Processo finalizado!",
      type: "success",
      showCancelButton: false,
      confirmButtonColor: "#1D84C6",
      confirmButtonText: "Continuar",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    })
      .then(() => {
        onFinish();
      });
  }

  render() {
    return (
      <Fragment>
        <Subscription
          subscription={SUBSCRIBE_TO_PROCESS_FINISHED}
          variables={{
            concreteShipmentId: this.props.concreteShipmentId,
          }}
          fetchPolicy="no-cache"
          onSubscriptionData={this.onSubscriptionData}
        />
      </Fragment>
    );
  }
}

export default withTruckStateConsumer(ProcessFinishedContent);
