import gql from "graphql-tag";

export const CONCRETE_FAMILIES_QUERY = gql`
  query {
    concreteFamilies {
      id
      name
      use
      cementType {
        id
        name
      }
      gravelType
      slump {
        id
        nominal
        deviation
      }
    }
  }
  `;

export const CONCRETE_FORMULAS_QUERY = gql`
  query($concreteFamilyIds: [Int]) {
    concreteFormulas(concreteFamilyIds: $concreteFamilyIds) {
      id
      code
      fck
      use
      consumption
      wcRatio
      version
      price
    }
  }
`;

export const DELETE_CONCRETE_FAMILY_MUTATION = gql`
  mutation($id: Int!) {
    deleteConcreteFamily(
      id: $id
    ) {
      id
    }
  }
`;
