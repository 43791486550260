import styled, { css } from "styled-components";

const IMAGE_WIDTH = 1920;
const SMALL_DEVICE_WIDTH = "430px";

export const PlantTitle = styled.div`
  min-width: 200px;
  font-weight: bold;
  color: #2E384D;
  padding: 10px 15px 10px 15px;
  border-bottom: ${props => (props.isVertical ? "none" : "2px solid #F1F4FE")};

  @media screen and (max-width: ${SMALL_DEVICE_WIDTH}) {
    flex-wrap: wrap;
    border-bottom: none;
  }
`;

export const PlantContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.isVertical ? "column" : "row")};
  justify-content: flex-start;
  align-items: stretch;
  padding: ${props => (props.isButton ? "0" : "5px")};

  @media screen and (max-width: ${SMALL_DEVICE_WIDTH}) {
    flex-wrap: wrap;
  }
`;

export const PlantContent = styled.div`
  display: block;
  padding: 5px;
  width: calc((${({ cols }) => cols} * 200px) + 10px);

  :not(:last-child) {
    border-right:  ${props => (props.isContainerVertical ? "" : "2px solid #F1F4FE")};
  }

  @media screen and (max-width: ${SMALL_DEVICE_WIDTH}) {
    flex-wrap: wrap;
    border-right: none;
  }
`;

export const PlantItem = styled.div`
  display: inline-block;
  width: 200px;
`;

export const PlantCard = styled.div`
  position: absolute;
  @media screen and (min-width: 801px) {
    z-index: 0;
  }
  top: ${props => props.top}%;
  left: ${props => props.left}%;

  @media screen and (max-width: 800px) {
    position: relative;
    display: block;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: 15px;
  }
`;

const arrowStyle = css`
  transform-origin: 0% 0% 0;

  @media screen and (max-width: 800px) {
    transform: scale(1) translate(0, 0);
  }
`;

export const ArrowLeft = styled.div`
  @media screen and (min-width: 801px) {
    position: absolute;
    z-index: 1;
  }
  transform: scale(${({ screenWidth }) => screenWidth / IMAGE_WIDTH}) translate(0, -50%);
  ${arrowStyle}
`;

export const ArrowRight = styled.div`
  @media screen and (min-width: 801px) {
    position: absolute;
    z-index: 1;
  }
  transform: scale(${({ screenWidth }) => screenWidth / IMAGE_WIDTH}) translate(-100%, -50%);
  ${arrowStyle}
`;

export const ArrowTop = styled.div`
  @media screen and (min-width: 801px) {
    position: absolute;
    z-index: 1;
  }
  transform: scale(${({ screenWidth }) => screenWidth / IMAGE_WIDTH}) translate(-50%, 0);
  ${arrowStyle}
`;

export const ArrowBottom = styled.div`
  @media screen and (min-width: 801px) {
    position: absolute;
    z-index: 1;
  }
  transform: scale(${({ screenWidth }) => screenWidth / IMAGE_WIDTH}) translate(-50%, -100%);
  ${arrowStyle}
`;

export const InstrumentsPanelWrapper = styled.div`
  position: relative;
`;

export const CircularIcon = styled.div`
  background-color: ${props => props.backgroundColor || "#000"};
  width: ${props => props.size || 46}px;
  height: ${props => props.size || 46}px;
  border-radius: ${props => props.size || 46}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: ${props => (props.onClick ? "pointer" : "default")};
  margin: 0 5px;
`;

export const ErrorParagraph = styled.p`
  font-size: 14px;
  font-family: 'Rubik';
  color: #FB404B;
  font-weight: 400;
`;

export const InfoParagraph = styled.p`
  font-size: 14px;
  font-family: 'Rubik';
  color: #8798AD;
  font-weight: 400;
`;

export const H3 = styled.h3`
  font-family: 'Rubik';
  color: #2E384D;
  font-size: 20px;
`;

export const Title = styled.h3`
  font-size: 20px;
  font-family: 'Rubik';
  color: #2E384D;
  font-weight: 500;
`;

export const Subtitle = styled.h3`
  font-size: 13px;
  font-family: 'Rubik';
  color: #707070;
  font-weight: 500;
`;

export const Label = styled.p`
  font-size: 14px;
  font-family: 'Rubik';
  color: #2E384D;
  font-weight: 500;
`;

export const Span = styled.span`
  font-size: 13px;
  font-family: 'Rubik';
  color: #8798AD;
  font-weight: 400;
`;
