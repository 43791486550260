import React from "react";
import Switch from "react-bootstrap-switch";
import PencilButton from "components/ActionButtons/PencilButton";
import {
  Title,
  ModalHeader,
} from "./ConcreteTracesModalStyle.jsx";

const ConcreteFormulaHeader = ({
  onChangeActive,
  closeModalHandler,
  isActive,
  onPencilEditClick
}) => (
  <div className="modal-header">
    <ModalHeader>
      <Title>
        Traço do Concreto
      </Title>

      <div style={{ display: "flex", flexDirection: "row", alginItems: "center" }}>
        <div style={{ marginTop: "5px", marginRight: "15px", }}>
          <Switch
            offColor="info"
            offText=""
            onColor="info"
            onText=""
            value={isActive}
            onChange={onChangeActive}
          />
          {" "}
        </div>
        {onPencilEditClick && (
          <PencilButton onPencilEditClick={onPencilEditClick} />
        )}
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={closeModalHandler}
        >
          <i className="nc-icon nc-simple-remove" />
        </button>
      </div>

    </ModalHeader>
  </div>
);

export default ConcreteFormulaHeader;
