import React from "react";
import { withQuery } from "containers/withQuery";
import { MaterialBoxesContainer } from "./confirmModalComponents";
import MaterialBox from "./MaterialBox";
import { CONCRETE_FORMULA_QUERY } from "./confirmModalQueries";

const MaterialBoxes = ({ concreteFormula }) => (
  <MaterialBoxesContainer>
    <MaterialBox title="Slump" value={`${concreteFormula.concreteFamily.slump.nominal} ± ${concreteFormula.concreteFamily.slump.deviation}`} />
    <MaterialBox title="Resistência" value={`Fck ${concreteFormula.fck}`} />
    <MaterialBox title="Pedra" value={concreteFormula.concreteFamily.gravelType} />
    <MaterialBox title="Uso" value={concreteFormula.use} />
  </MaterialBoxesContainer>
);

export default withQuery(({ formulaCode, onConcreteFormulaQueryCompleted, }) => ({
  query: CONCRETE_FORMULA_QUERY,
  variables: {
    formulaCode,
  },
  fetchPolicy: "no-cache",
  onCompleted: onConcreteFormulaQueryCompleted,
}))(MaterialBoxes);
