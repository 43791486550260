import React from "react";
import NumberFormat from "react-number-format";

const styles = {
  field: {
    minHeight: 40,
  }
};

const CurrencyInput = ({
  style,
  props,
  value,
  onChange
}) => (
  <NumberFormat
    thousandSeparator="."
    decimalSeparator=","
    className="form-control"
    placeholder="R$"
    prefix="R$ "
    style={{ ...styles.field, ...style }}
    decimalScale={2}
    fixedDecimalScale
    onValueChange={({ value: rawValue }) => onChange(parseFloat(rawValue))}
    value={value}
    {...props}
  />
);

export default CurrencyInput;
