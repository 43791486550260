import React from "react";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const Title = styled.h3`
  font-family: "Rubik";
  color: #2E384D;
  font-size: 15px;
  font-weight: 500;
`;

const SubTitle = styled.div`
  margin-left: 10px;
  font-family: "Rubik";
  font-size: 28px;
  font-weight: 500;
  color: #1D84C6;
`;

const InfoHumidity = ({ sandType, value, }) => (
  <Container>
    <Title>{`Última medição de umidade de ${sandType.toLowerCase()}:`}</Title>
    <SubTitle>{value ? `${(value * 100).toFixed(1)}%` : "-"}</SubTitle>
  </Container>
);

export default InfoHumidity;
