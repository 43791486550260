import React from "react";
import styled from "styled-components";
import { Button as BSButton } from "reactstrap";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h3`
  font-family: 'Rubik';
  color: #2E384D;
  font-size: 20px;
  margin-left: 32px;
`;

const Button = styled(BSButton)`
  padding-top: 10px 50px 10px 50px !important;
  margin-right: 32px !important;
`;

const HeaderTable = ({ title, buttonLabel, onClick }) => (
  <Container>
    <Title>{title}</Title>
    <Button color="info" outline onClick={onClick}>
      {buttonLabel}
    </Button>
  </Container>
);

export default HeaderTable;
