import React from "react";

const ConcreteFormulaIdsContext = React.createContext({});

export const ConcreteFormulaIdsProvider = ConcreteFormulaIdsContext.Provider;

export const withConcreteFormulaIdsConsumer = (WrappredComponent) => {
  const Consumer = props => (
    <ConcreteFormulaIdsContext.Consumer>
      { context => <WrappredComponent concreteFormulaIdsContext={context} {...props} /> }
    </ConcreteFormulaIdsContext.Consumer>
  );

  return Consumer;
};
