import React from "react";
import {
  Modal as ModalReactstrap,
} from "reactstrap";
import styled from "styled-components";

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  color: #2E384D;
`;

const Modal = props => (
  <ModalReactstrap
    isOpen={props.isOpen}
    toggle={props.toggleModal}
    size={props.size || "lg"}
  >
    <div className="modal-header justify-content-center" style={{ display: "block", border: "none", }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Title>{props.title}</Title>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={props.toggleModal}
        >
          <i className="nc-icon nc-simple-remove" />
        </button>
      </div>
    </div>
    <div className="modal-body" style={{ paddingLeft: 32, paddingRight: 32, paddingBottom: 0, }}>
      {props.children}
    </div>
    <div className="modal-footer" style={{ paddingBottom: "32px", paddingLeft: "32px", paddingRight: "32px", paddingTop: "32px", border: "none" }}>
      {props.footer}
    </div>
  </ModalReactstrap>
);

export default Modal;
