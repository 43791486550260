import React from "react";

class ScheduleNavbar extends React.Component {

  render() {
    return (
      <React.Fragment>
      </React.Fragment>
    );
  }
}

export default ScheduleNavbar;
