import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #F4F5FC;
  padding: 20px 32px 20px 32px;
`;

const Title = styled.h2`
  font-family: "Rubik";
  font-size: 20px;
  font-weight: 500;
  color: #2E384D;
  width: 100%;
`;

const CardHeader = ({ title }) => (
  <Container>
    <Title>{title}</Title>
  </Container>
);

export default CardHeader;
