import React, { useState, useEffect } from "react";
import styled from "styled-components";


import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  Container,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
} from 'reactstrap';

import {
  post,
  endpoints,
} from "utils/axios";


export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;


export const Title = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: #2E384D;
`;

export default function ConfigModal({
  title,
  isOpen,
  setIsOpen,
  estimator,
  selectedSetpoint,
}) {
  const [newEstimate, setNewEstimate] = useState(
    {
      data: {
        ...estimator,
        setpoint: selectedSetpoint,
        measurements: 6,
        value: 0,
        standard_deviation: 0,
        clear_old_values: false,
      }
    }
  );

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleUpdate = async () => {
    await post(endpoints.estimators.create, newEstimate);
    setIsOpen(false);
  };

  useEffect(() => {
    setNewEstimate({ ...newEstimate, data: { ...newEstimate.data, setpoint: selectedSetpoint }});
  }, [selectedSetpoint]);

  return (
    <Container fluid>
      <Modal
        isOpen={isOpen}
        size="md"
        style={{ maxWidth: "800px", width: "80%" }}
      >
        <ModalHeader style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "5px" }}>
          <Title>
            {title}
          </Title>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => handleCancel()}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
        </ModalHeader>
        <ModalBody style={{ paddingLeft: "20px", paddingRight: "20px"}}>
          <Card>
            <CardBody>
              <Row>
                <Col sm={4} md={4}>
                  <FormGroup>
                    <Label for="setpoint">Quantidade alvo</Label>
                    <Input
                      type="number"
                      name="setpoint"
                      id="setpoint"
                      value={newEstimate.data.setpoint}
                      onChange={e => setNewEstimate({ ...newEstimate, data: { ...newEstimate.data, setpoint: e.target.value }})}
                    />
                  </FormGroup>
                </Col>
                <Col sm={4} md={4}>
                  <FormGroup>
                    <Label for="value">Valor</Label>
                    <Input
                      type="number"
                      name="value"
                      id="value"
                      value={newEstimate.data.value}
                      onChange={e => setNewEstimate({ ...newEstimate, data: { ...newEstimate.data, value: e.target.value }})}
                    />
                  </FormGroup>
                </Col>
                <Col sm={4} md={4}>
                  <FormGroup>
                    <Label for="standard_deviation">Desvio padrão</Label>
                    <Input
                      type="number"
                      name="standard_deviation"
                      id="standard_deviation"
                      value={newEstimate.data.standard_deviation}
                      onChange={e => setNewEstimate({ ...newEstimate, data: { ...newEstimate.data, standard_deviation: e.target.value }})}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={newEstimate.data.clear_old_values}
                        onChange={e => setNewEstimate({ ...newEstimate, data: { ...newEstimate.data, clear_old_values: e.target.checked }})}
                      />
                      <span className="form-check-sign" /> Ignorar valores antigos
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter style={{ paddingLeft: "20px", paddingRight: "20px"}}>
          <Button
            style={{ paddingLeft: "100px", paddingRight: "100px", margin: 0 }}
            color="info"
            data-dismiss="modal"
            type="button"
            onClick={() => handleUpdate()}
          >
            Salvar
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};
