import React from "react";
import styled from "styled-components";
import { withApollo } from "react-apollo";
import StatusIcon from "../../AutomationStatus/StatusIcon";

export const CardContent = styled.div`
  max-width: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;


const AutomationStatus = ({ automationStatus }) => (
  <div className="content">
    <CardContent>
      <StatusIcon size="md" type={automationStatus && automationStatus.currentAutomationStatus.status} />
      <h3 style={{ marginTop: "20px", textAlign: "center" }}>
        {automationStatus && automationStatus.currentAutomationStatus.description}
      </h3>
    </CardContent>
  </div>
);

export default withApollo(AutomationStatus);
