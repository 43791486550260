/* eslint-disable no-useless-escape */
import React from "react";
import { Col, Row } from "reactstrap";

import {
  InfoContainer,
  FamilyContainer,
  MaterialsContainer,
} from "./ConcreteTracesModalStyle.jsx";
import GenericInput from "./GenericInput.jsx";
import { getFamilyMetadata, getForceMetadata, getMaterialsMetadata } from "./inputFields.js";

const ConcreteFormulaBody = ({
  isEditable,
  use,
  concreteFamily,
  concreteFamiliesOptions,
  fck,
  fcj,
  fctmk,
  consumption,
  mortarFactor,
  wcRatio,
  aggressivenessIndex,
  incorporatedAir,
  observations,
  onChangeData,
  code,
}) => (
  <React.Fragment>
    <FamilyContainer className="modal-body">
      <Row>
        <Col xs={6}>
          <InfoContainer>
            <GenericInput
              title="Uso do traço"
              id="use"
              value={use}
              isEditable={isEditable}
              onChange={onChangeData}
            />
          </InfoContainer>
        </Col>

        <Col xs={6}>
          <InfoContainer>
            <GenericInput
              id="code"
              title="Código do traço"
              value={code}
              isEditable={isEditable}
              onChange={onChangeData}
            />
          </InfoContainer>
        </Col>

        {
          getFamilyMetadata(concreteFamily, concreteFamiliesOptions).map(item => (
            <Col xs={6} md={3} key={item.id}>
              <InfoContainer>
                <GenericInput
                  title={item.title}
                  id={item.id}
                  value={item.value}
                  isEditable={isEditable && item.isEditable}
                  onChange={onChangeData}
                  type={item.type}
                  options={item.options}
                />
              </InfoContainer>
            </Col>
          ))
        }
      </Row>
    </FamilyContainer>
    <MaterialsContainer className="modal-body">
      <Row>
        {
          getForceMetadata(fck, fcj, fctmk, consumption).map(item => (
            <Col xs={6} md={3} key={item.id}>
              <InfoContainer>
                <GenericInput
                  title={item.title}
                  id={item.id}
                  value={item.value}
                  isEditable={isEditable && item.isEditable}
                  onChange={onChangeData}
                  isValid={item.isValid}
                  formatter={item.formatter}
                />
              </InfoContainer>
            </Col>
          ))
        }
      </Row>
      <Row>
        {
          getMaterialsMetadata(mortarFactor, wcRatio, aggressivenessIndex, incorporatedAir).map(item => (
            <Col xs={6} md={3} key={item.id}>
              <InfoContainer>
                <GenericInput
                  title={item.title}
                  id={item.id}
                  value={item.value}
                  isEditable={isEditable && item.isEditable}
                  onChange={onChangeData}
                  formatter={item.formatter}
                />
              </InfoContainer>
            </Col>
          ))
        }
        <Col xs={12}>
          <InfoContainer>
            <GenericInput
              title="Observações"
              id="observations"
              value={observations}
              isEditable={isEditable}
              onChange={onChangeData}
            />
          </InfoContainer>
        </Col>
      </Row>
    </MaterialsContainer>
  </React.Fragment>
);

export default ConcreteFormulaBody;
