import gql from "graphql-tag";

export const CREATE_SLUMP_MUTATION = gql`
  mutation($params: SlumpParams!) {
    createSlump(params: $params) {
      id
    }
  }
`;

export const UPDATE_SLUMP_MUTATION = gql`
  mutation($id: Int!, $params: SlumpParams!) {
    updateSlump(id: $id, params: $params) {
      id
    }
  }
`;

export const CREATE_CEMENT_TYPE_MUTATION = gql`
  mutation($params: CementTypeParams!) {
    createCementType(params: $params) {
      id
    }
  }
`;

export const UPDATE_CEMENT_TYPE_MUTATION = gql`
  mutation($id: Int!, $params: CementTypeParams!) {
    updateCementType(id: $id, params: $params) {
      id
    }
  }
`;

export const CREATE_INVENTORY_ITEM_MUTATION = gql`
  mutation($params: CreateInventoryItemParams!) {
    createInventoryItem(params: $params) {
      id
    }
  }
`;

export const UPDATE_INVENTORY_ITEM_MUTATION = gql`
  mutation($id: Int!, $params: UpdateInventoryItemParams!) {
    updateInventoryItem(id: $id, params: $params) {
      id
    }
  }
`;

export const CREATE_SUPPLIER_MUTATION = gql`
  mutation($params: CreateSupplierParams!) {
    createSupplier(params: $params) {
      id
    }
  }
`;

export const UPDATE_SUPPLIER_MUTATION = gql`
  mutation($id: Int!, $params: UpdateSupplierParams!) {
    updateSupplier(id: $id, params: $params) {
      id
    }
  }
`;

export const CREATE_LOCATION_MUTATION = gql`
  mutation($params: CreateInventoryLocationParams!) {
    createInventoryLocation(params: $params) {
      id
    }
  }
`;

export const UPDATE_LOCATION_MUTATION = gql`
  mutation($id: Int!, $params: UpdateInventoryLocationParams!) {
    updateInventoryLocation(id: $id, params: $params) {
      id
    }
  }
`;
