import React from "react";
import styled, { css } from "styled-components";
import { Button } from "reactstrap";

import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import LoadSlider from "components/LoadSlider/LoadSlider";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";

import Select from "./FormulaSelect";

const TRANSITION_EFFECT = "all 0.3s ease-in";
const CONFIRM_MODAL = "confirm_modal";
const CONTROL_MENU = "control_menu";
const INITIAL_STATE = {
  open: null,
  quantity: 5,
  formula: null,
};

const Overlay = styled.div`
  display: ${props => (props.open !== null && props.show ? "block" : "none")};
  position: fixed;
  top: 0ox;
  left: 0px;
  height: 100vh;
  width: 100vw;
  z-index: ${props => (props.open === CONTROL_MENU ? 2010 : 2030)};
  background-color: rgba(47, 64, 80, 0.74);
`;

const SharedStyle = css`
  background-color: #2F4050;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.8);
  border-radius: 10px 0 0 10px;
`;

const MenuContainer = styled.div`
  top: 55px;
  right: -5px;
  position: fixed;
  height: 300px;
  width: 400px;
  z-index: 2011;

  transition: ${TRANSITION_EFFECT};
  transform: translate(${({ open, show }) => (show ? (open ? "0%" : "calc(100% - 50px)") : "calc(100% + 10px)")});
`;

const IconContainer = styled.div`
  ${SharedStyle}
  top: 0px;
  position: absolute;
  width: 50px;
  height: 50px;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 2012;

  &::before {
    top: 0px;
    left: 50px;
    position: absolute;
    content: "";
    background-color: #2f4050;
    height: calc(100% + 12px);
    width: 15px;
  }
`;

const Menu = styled.div`
  ${SharedStyle}
  top: 0px;
  right: 0px;
  position: absolute;
  display: inline-block;
  width: 350px;
  height: 300px;
`;

const Icon = styled.i`
  color: #fff;
  font-weight: 100;
  margin-left: 10px;
`;

const Title = styled.p`
  color: #fff;
  text-align: center;
  margin: 20px;
  font-size: 15px;
  font-family: 'Rubik';
`;

const Hr = styled.hr`
  border-color: #ddd !important;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  margin-top: 25px;
`;

class ControlMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.iconToggle = React.createRef();
  }

  toggle = () => {
    this.props.handleControlMenuOpen(this.state.open !== CONTROL_MENU);
    this.setState(state => ({ open: state.open !== CONTROL_MENU ? CONTROL_MENU : null }));
  }

  toggleConfirmModal = () => {
    this.props.handleControlMenuOpen(false);
    this.setState(state => {
      if (state.open === CONFIRM_MODAL) {
        return INITIAL_STATE;
      }

      return { open: CONFIRM_MODAL };
    });
  }

  startShipment = () => {
    if (this.state.formula !== null) {
      this.toggleConfirmModal();
    } else {
      this.props.snackbarContext.setNotificationTimeOut(
        Color.warning,
        "Para iniciar um carregamento, é necessário selecionar uma fórmula."
      );
    }
  }

  onChangeSlider = quantity => this.setState({ quantity });

  onChangeSelect = formula => this.setState({ formula });

  render() {
    const {
      open,
      quantity,
      formula,
    } = this.state;

    const modalData = {
      formulaCode: formula && formula.code,
      operatorsSelected: [],
      teamSelected: []
    };

    const isControlMenuOpen = open === CONTROL_MENU;
    const isConfirmModalOpen = open === CONFIRM_MODAL;

    return (
      <React.Fragment>
        <MenuContainer open={isControlMenuOpen} show={this.props.show}>
          <IconContainer onClick={this.toggle}>
            <Icon className={`fa fa-arrow-${isControlMenuOpen ? "right" : "left"}`} />
          </IconContainer>

          <Menu>
            <Title>MENU DE CONTROLE</Title>

            <Hr />

            <Form>
              <Select
                value={formula}
                onChange={this.onChangeSelect}
              />

              <LoadSlider value={quantity} onChange={this.onChangeSlider} lightText />

              <div style={{ width: 300 }}>
                <Button color="info" onClick={this.startShipment} className="btn-block">
                  Iniciar carregamento
                </Button>
              </div>
            </Form>
          </Menu>
        </MenuContainer>

        {isConfirmModalOpen && (
          <ConfirmModal
            isOpen={isConfirmModalOpen}
            toggleModal={this.toggleConfirmModal}
            data={modalData}
            quantityValue={quantity}
            formulaCode={formula.code}
            concreteFormulaId={formula.value}
            onRefetch={this.props.onRefetch}
          />
        )}

        <Overlay open={open} show={this.props.show} />
      </React.Fragment>
    );
  }
}

export default withSnackbarConsumer(ControlMenu);
