import React from "react";

import { withServiceOrdersFilterConsumer } from "contexts/ServiceOrdersFilterContext";
import { NavbarButton } from "./navbarComponents";

const buttonFilters = [
  { status: "CURRENT", text: "Andamento" },
  { status: "PENDING", text: "Próximas" },
  { status: "FINISHED", text: "Concluídas" }
];

class ServiceOrdersNavbar extends React.Component {
  state = {
    isSelected: "CURRENT"
  }

  componentDidMount() {
    this.onStatusSelect("CURRENT");
  }

  onStatusSelect = isSelected => {
    this.props.serviceOrdersFilterContext.setServiceOrder(isSelected);
    this.setState({ isSelected });
  }

  render() {
    return (
      <React.Fragment>
        {buttonFilters.map((btn) => (
          <NavbarButton
            breakpoint={550}
            key={btn.status}
            color="link"
            size="sm"
            onClick={() => this.onStatusSelect(btn.status)}
            active={this.state.isSelected === btn.status}
            style={{ margin: 0 }}
          >
            {btn.text}
          </NavbarButton>
        ))}
      </React.Fragment>
    );
  }
}

export default withServiceOrdersFilterConsumer(ServiceOrdersNavbar);
