import React from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { httpClient } from "settings/apolloClient";
import { SUPPLIERS_QUERY } from "./familiesAndFormulasQueries";
import ConcreteFormulaCreate from "views/FamiliesAndFormulas/ConcreteTracesModal/ConcreteFormulaCreate";
import FamilyCreate from "./FamilyCreate";
import { NavbarDropdownToggle } from "../navbarComponents";


class FamiliesAndFormulasNavbar extends React.Component {
  state = {
    dropdownOpen: false,
    modalOpened: "closed",
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
    httpClient.query({
      query: SUPPLIERS_QUERY,
    }).then(({ data }) => {
      this.setState({ suppliers: data.suppliers });
    });
  }

  closeModal = () => {
    this.setState({ modalOpened: "closed" });
  }

  render() {
    const { dropdownOpen, modalOpened } = this.state;
    return (
      <React.Fragment>
        <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown}>
          <NavbarDropdownToggle color="info" caret>
            Novo cadastro
          </NavbarDropdownToggle>

          <DropdownMenu>
            <DropdownItem onClick={() => this.setState({ modalOpened: "family" })}>
              Família
            </DropdownItem>
            <DropdownItem onClick={() => this.setState({ modalOpened: "formula" })}>
              Traço do Concreto
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        {modalOpened === "family" && (
          <FamilyCreate isOpen={modalOpened === "family"} toggleModal={this.closeModal} />
        )}

        {modalOpened === "formula" && (
          <ConcreteFormulaCreate modalOpen={modalOpened === "formula"} closeModalHandler={this.closeModal} suppliers={this.state.suppliers} />
        )}
      </React.Fragment>
    );
  }
}

export default FamiliesAndFormulasNavbar;
