import React from "react";

import {
  TableContainer,
  Table,
  Header,
  Row,
  Data
} from "./tableComponents";

export default ({
  tableName,
  headers,
  items,
  matchHeaders,
  maxHeight,
  center = true,
}) => (
  <TableContainer maxHeight={maxHeight}>
    <Table>
      <thead>
        <tr>
          {headers.map((prop) => (
            <Header key={prop.key} className={prop.className} center={center}>
              <h5>{matchHeaders ? prop.label : prop}</h5>
            </Header>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map(({ __typename, ...item }, index) => (
          <Row key={item.id || index}>
            {
              matchHeaders ? (
                headers.map(({ valueFormatter, ...header }) => (
                  <Data key={`${tableName}-${item.id}-${header.key}`} center={center}>
                    {
                      valueFormatter ? valueFormatter(item[header.key]) : item[header.key]
                    }
                  </Data>
                ))
              ) : (
                Object.keys(item).map((key) => (
                  <Data key={item.id}>
                    {item[key]}
                  </Data>
                ))
              )
            }
          </Row>
        ))}
      </tbody>
    </Table>
  </TableContainer>
);
