import React from "react";
import { Line } from "rc-progress";
import "./MaterialProgress.scss";

const materialProgressProps = {
  done: {
    color: "#1AB394",
    icon: "fas fa-check"
  },
  loading: {
    color: "#1D84C6",
    icon: "fa fa-sync"
  },
  waiting: {
    color: "#FFAA00",
    icon: "fa fa-hourglass"
  },
  danger: {
    color: "#FB404B",
    icon: "fa fa-exclamation-triangle"
  },
};

const calculateProgressStatus = (value, total) => {
  const valueFloat = parseFloat(value);
  const totalFloat = parseFloat(total);
  if (valueFloat > (totalFloat * 1.03)) return "danger";
  if ((valueFloat > 0) && (valueFloat >= (totalFloat * 0.97))) return "done";
  if ((valueFloat > 0) && (valueFloat < totalFloat)) return "loading";
  return "waiting";
};

const MaterialProgress = ({
  title,
  value,
  total,
  big
}) => (
  <div className="material-progress-content">
    {title && (
      <h3 className={`${big ? "small" : ""}`}>{title}</h3>
    )}
    <div
      style={{
        display: "flex",
        marginTop: "6px",
      }}
    >
      <div
        style={{ width: "135px" }}
      >
        <Line
          percent={`${value * 100 / total}`}
          strokeWidth="4"

          strokeColor={materialProgressProps[calculateProgressStatus(value, total)].color}
        />
      </div>
      <i
        className={materialProgressProps[calculateProgressStatus(value, total)].icon}
        style={{
          color: materialProgressProps[calculateProgressStatus(value, total)].color,
          marginLeft: "10px",
          marginTop: "2px"
        }}
      />
    </div>
    <p
      style={{fontSize: "18px"}}
    >
      {`${parseFloat(value).toFixed(1)} | ${parseFloat(total).toFixed(1)}`}
    </p>
  </div>
);

export default MaterialProgress;
