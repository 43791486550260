import React from "react";
import { Route, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { compose } from "react-apollo";
import Spinner from "react-spinkit";
import styled from "styled-components";
import { TOKEN_KEY } from "./auth";


const SpinnerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 15px;
  padding: 20px;
`;

class PrivateRoute extends React.Component {
  render() {
    const {
      component: Component,
      currentUser,
      userClient
    } = this.props;

    const isAuthenticated = this.props.cookies.get(TOKEN_KEY, { path: "/" }) && currentUser && userClient;

    if (!isAuthenticated) {
      window.location = "/login";
    }

    return isAuthenticated
      ? <Route render={props => <Component currentUser={currentUser} {...props} />} />
      : (
        <SpinnerContainer>
          <Spinner name="line-spin-fade-loader" color="green" />
        </SpinnerContainer>
      );
  }
}
export default compose(
  withCookies,
  withRouter
)(PrivateRoute);
