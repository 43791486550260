import gql from "graphql-tag";

export default gql`
  mutation requestActuatorMaintenanceMode(
    $actuatorId: Int!,
    $status: Boolean!
    ) {
      requestActuatorMaintenanceMode(
        actuatorId: $actuatorId,
        status: $status
      )
    }
`;
