import React from "react";
import {
  Input as BSInput,
} from "reactstrap";

class Input extends React.Component {
  onChange = e => {
    const { name, onChange } = this.props;
    onChange(name, e.target.value);
  }

  render () {
    const { value, style, type } = this.props;
    return (
      <BSInput
        value={value}
        onChange={this.onChange}
        style={style}
        type={type}
      />
    );
  }
}

export default Input;
