import React from "react";
import {
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import styled from "styled-components";

const Description = styled.span`
  font-family: "Rubik";
  font-size: 15px;
  color: #2E384D;
  font-weight: 500;

  margin-right: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Checkbox = ({ label, value, onChange }) => (
  <Container>
    <Description>{label}</Description>

    <FormGroup style={{ marginTop: "-15px" }} check>
      <Label check>
        <Input
          value={value}
          onChange={onChange}
          type="checkbox"
          defaultChecked
        />
        <span className="form-check-sign" />
      </Label>
    </FormGroup>
  </Container>
);

export default Checkbox;
