import React, { useEffect, useRef, useState } from "react";
import "./CustomLoadSlider.scss";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Input } from "reactstrap";
import PencilImg from "../../assets/img/pencil-edit-button.svg";
import { useMobileDevice } from "../../utils/getDeviceType";

const MIN_VALUE = 0.1;
const MAX_VALUE = 11;
const STEP = 0.5;

const LoadSlider = ({
  setQuantity,
  quantity,
  lightText,
  title = "Quantidade",
}) => {
  const isMobile = useMobileDevice();
  const inputRef = useRef(null);
  const color = lightText ? "#fff" : "#2E384D";

  const formatToPattern = (number) => {
    const parsedNumber = parseFloat(number);
    if (Number.isNaN(parsedNumber)) return "00.0";
    const formatted = parsedNumber.toFixed(1);
    const [integerPartRaw, decimalPart] = formatted.split(".");
    const integerPart = integerPartRaw.padStart(2, "0");
    return `${integerPart}.${decimalPart}`;
  };

  const [inputColor, setInputColor] = useState("#D3D3D3");
  const [messageError, setMessageError] = useState("");
  const [quantityText, setQuantityText] = useState(quantity ? formatToPattern(quantity) : ["05.0"]);
  const [sliderQuantity, setSliderQuantity] = useState(5);

  const handleFocusCursor = () => {
    // Always keep the cursor at the last character
    if (inputRef.current) {
      const { current: { value: { length } } } = inputRef;
      inputRef.current.setSelectionRange(length, length);
    }
  };
  const handleKeyUp = (e) => {
    if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      // Ensure the cursor will always remain at the last character
      handleFocusCursor();
    } else if (e.key === "Backspace") {
      // Clear all
      setQuantityText("00.0");
    }
  };

  const handleInputChange = (e) => {
    setMessageError("");
    setInputColor("#D3D3D3");
    // Remove the first character and add a new one at the end to maintain the XX.X format
    const inputValue = e.target.value.replace(/\D/g, "");
    if (inputValue.length > 1) {
      let formattedValue = `${inputValue.slice(1, -1)}.${inputValue.slice(-1)}`;
      formattedValue = formatToPattern(formattedValue);
      setQuantityText(formattedValue);
      return formattedValue;
    }
    setQuantityText(inputValue);
    return inputValue;
  };

  const handleSliderChange = (sliderValue) => {
    setMessageError("");
    setInputColor("#D3D3D3");
    const adjustedValue = Math.round(sliderValue * 2) / 2;
    const formattedSliderValue = adjustedValue.toFixed(1);
    const formatted = formatToPattern(adjustedValue);
    setQuantityText(formatted);
    setSliderQuantity(adjustedValue);
    setQuantity(formattedSliderValue);
    return formattedSliderValue;
  };


  const handleBlur = (e) => {
    // When leaving the input field, check if the value is within the allowed bounds
    let value = Number(e.target.value);
    if (value > MAX_VALUE) {
      value = MAX_VALUE;
      setInputColor("red");
      setMessageError("Valor ultrapassa a quantidade máxima permitida.");
    } else if (value < MIN_VALUE) {
      value = MIN_VALUE;
      setInputColor("red");
      setMessageError("Valor menor que a quantidade mínima permitida.");
    }
    setQuantityText(formatToPattern(value));
    setSliderQuantity(value);
    setQuantity(value);
  };

  return (
    <div className="load-slider-container-custom">
      <div>
        <p style={{ color: "red" }}>
          {messageError}
        </p>
      </div>
      <div className="load-slider-info-custom">
        <h3 className="title" style={{ color }}>
          {title}
        </h3>
        <div
          onClick={() => inputRef.current && inputRef.current.focus()}
          style={{
            cursor: "default",
            display: "flex",
            alignItems: "center",
            padding: "0 !important",
            flexGrow: 1,
            paddingLeft: isMobile ? "0px" : "30px",
            maxWidth: "140px"
          }}
        >
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "right",
              color: inputColor,
              borderBottom: "1px solid #D3D3D3",
              borderRadius: "5px",
              padding: "0 !important",
              flexGrow: 1
            }}
          >
            <Input
              value={quantityText}
              className="number-input"
              type="text"
              inputMode="decimal"
              onChange={handleInputChange}
              onFocus={handleFocusCursor}
              onClick={handleFocusCursor}
              onBlur={handleBlur}
              onKeyUp={handleKeyUp}
              innerRef={inputRef}
              style={{
                width: "60px",
                height: "40px",
                display: "block",
                textAlign: "right",
                backgroundColor: "transparent",
                color: inputColor,
                fontSize: "14px",
                fontWeight: "bold",
                border: "none",
                padding: "0",
              }}
            />
            <b>m³</b>
          </div>
          <img src={PencilImg} alt="edit" style={{ height: "0.7rem" }} />
        </div>
      </div>

      <Slider
        onChange={handleSliderChange}
        value={sliderQuantity}
        min={0.1}
        max={MAX_VALUE}
        step={STEP}
        style={{ marginTop: "10px", color: "#1D84C6" }}
      />
    </div>
  );
};

export default LoadSlider;
