import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";
import ReactSVG from "react-svg";
import AlertIcon from "assets/img/alert-icon.svg";

const Alert = styled.div`
  position: relative;
  background-color: red;
  border-radius: 0 10px 10px 0;
  width: auto;
  min-height: 50px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0 20px;

  transform: translate(${({ open }) => (open ? "0%" : "calc(-100% - 80px - 25px)")});
  transition: all 0.3s ease-in;
`;

const Message = styled.p`
  text-transform: uppercase;
  margin-left: 15px;
  margin-right: 15px;
  font-weight: 500;
  color: #fff;
  font-size: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Divider = styled.div`
  border-right: 2px solid #FFF;
  height: 18px;
  margin-right: 15px;
`;

const StyledButton = styled(Button)`
  margin: 10px;
  color: red !important;
  background-color: #fff !important;
`;

const Counter = styled.div`
  border-radius: 25px;
  background-color: #F6DE02;
  display: ${props => (props.show ? "flex" : "none")};
  position: absolute;
  top: -10px;
  right: -10px;
  height: 25px;
  min-width: 25px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #000;
  padding: 5px
`;

const ManualOperationAlert = ({
  message,
  amount,
  stack,
  nextOperation,
  onResolve,
}) => (
  <Alert
    open={amount > 0}
  >
    <ReactSVG src={AlertIcon} />
    <Message>
      {message}
    </Message>
    <ButtonContainer>
      <Divider />
      <StyledButton onClick={onResolve(nextOperation)} className="btn-sm">
        RESOLVER JÁ
      </StyledButton>
    </ButtonContainer>
    <Counter show={stack}>
      {amount}
    </Counter>
  </Alert>
);

export default ManualOperationAlert;
