import React from "react";

import Spinner from "components/Spinner/Spinner";

const QueryHandlerWrapper = ({
  children,
  loading,
  error,
}) => {
  if (loading) {
    return (
      <Spinner
        name="line-spin-fade-loader"
        color="green"
      />
    );
  }

  if (error) {
    if (error.networkError) {
      return (
        <div className="message-container">
          <h3 className="mb-3">O banco de dados demorou muito para responder</h3>
          <p>Verifique se o código está correto, atualize a página e tente novamente</p>
        </div>
      )
    }

    return (
      <div className="message-container">
        <h3 className="mb-3">Um ou mais erros foram encontrados ao buscar informações</h3>
        <ul>
          {error.graphQLErrors.map(({ message }, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      </div>
    )
  }

  return children;
};

export default QueryHandlerWrapper;
