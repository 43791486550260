import React from "react";
import { NavbarButton } from "../navbarComponents";
import StockInputCreate from "./StockInputCreate";

class StockMaterialsNavbar extends React.Component {
  state = {
    isOpen: false,
  };

  toggleModal = () => this.setState(({ isOpen }) => ({ isOpen: !isOpen }))

  render() {
    const { isOpen } = this.state;
    return (
      <React.Fragment>
        <NavbarButton onClick={this.toggleModal} color="info" breakpoint={444}>
          Entrada de Estoque
        </NavbarButton>

        <StockInputCreate isOpen={isOpen} toggleModal={this.toggleModal} />
      </React.Fragment>
    );
  }
}

export default StockMaterialsNavbar;
