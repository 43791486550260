import gql from "graphql-tag";

export default gql`
  query loadingConcreteShipmentQuery {
    manualOperations(resolved: false) {
      id
      operatorId
      corporationId
      reason
      resolvedAt
      concreteShipment {
        id
      }
      manualSensorVariations {
        id
        sensor {
          id
          name
          unit
        }
        quantity
      }

    }
    loadingConcreteShipment {
      id
      code
      status
      shipmentType
      quantity
      controllerFinishedAt
      concreteFormula {
        id
        code
        fck
        concreteFamily {
          id
          slump {
            id
            nominal
            deviation
          }
        }
      }
    }
  }
`;
