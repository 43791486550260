import React, { useState } from 'react';
import FileCopyIcon from 'assets/img/copy.svg';

export const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    // Create a textarea element to temporarily hold the text
    const textarea = document.createElement('textarea');
    textarea.value = text;

    // Append the textarea to the DOM
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    try {
      // Execute the copy command
      document.execCommand('copy');
      setCopied(true);
    } catch (err) {
      console.error('Unable to copy text: ', err);
    } finally {
      // Remove the textarea from the DOM
      document.body.removeChild(textarea);

      // Reset the "copied" notification after a short delay
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  };

  return (
    <div>
      <img src={FileCopyIcon} alt="Copy" height="20px" width="20px" onClick={handleCopyClick}/>
    </div>
  );
};

