import React, { } from "react";
import classnames from "classnames";

import AlertModal from "components/AlertModal/AlertModal";

const ActionButton = ({
  label,
  icon,
  classes,
  action,
  onConfirm,
  active = false,
  disabled = false,
  isMaintenanceButton = false,
}) => {
  const titleSuffix = isMaintenanceButton ? `${action}?` : `${action} este carregamento?`;
  const handleClick = () => {
    if (!disabled) {
      AlertModal.fire({
        title: `Você tem certeza que deseja ${titleSuffix}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1D84C6",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Voltar",
        reverseButtons: true,
      })
        .then(result => {
          if (result.value) {
            onConfirm();
          }
        });
    }
  };

  const buttonClasses = classnames("action-button", classes, {
    active,
    disabled,
  });

  return (
    <div className={buttonClasses} onClick={handleClick}>
      <img src={icon} alt="img" />

      <span>{label}</span>
    </div>
  );
};

export default ActionButton;
