import React from "react";
import { compose, graphql } from "react-apollo";
import { withSnackbarConsumer, Color } from "contexts/SnackbarContext/SnackbarContext.jsx";
import translateErrorMessage from "utils/translateErrorMessage";
import { CONCRETE_FAMILIES_QUERY } from "views/FamiliesAndFormulas/FamiliesAndFormulasQueries";
import { UPDATE_CONCRETE_FAMILY_MUTATION } from "./familiesAndFormulasMutation";
import FamilyModal from "./FamilyModal";

class FamilyEdit extends React.Component {
  onSubmit = params => {
    const { currentFamily } = this.props;

    this.props.updateConcreteFamily({
      variables: {
        id: currentFamily.id,
        params,
      },
      refetchQueries: [
        { query: CONCRETE_FAMILIES_QUERY },
      ]
    })
      .then(this.onSuccess)
      .catch(this.onError);
  };

  onSuccess = () => {
    const { snackbarContext, removeCurrentFamily } = this.props;
    snackbarContext.setNotificationTimeOut(Color.success, "Família atualizada.");
    removeCurrentFamily();
  }

  onError = error => {
    const { snackbarContext } = this.props;
    snackbarContext.setNotificationTimeOut(Color.danger, translateErrorMessage(error));
  }

  render() {
    const { currentFamily, removeCurrentFamily } = this.props;

    return (
      <FamilyModal
        title="Editar família"
        isOpen={currentFamily != null}
        toggleModal={removeCurrentFamily}
        onSave={this.onSubmit}
        family={currentFamily}
      />
    );
  }
}

export default compose(
  withSnackbarConsumer,
  graphql(UPDATE_CONCRETE_FAMILY_MUTATION, { name: "updateConcreteFamily" }),
)(FamilyEdit);
