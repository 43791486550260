import React from "react";
import {
  Button
} from "reactstrap";
import "./MachineStatus.scss";
// import { useQuery } from "@apollo/react-hooks";
import { httpClient } from "settings/apolloClient";
import { useMutation } from '@apollo/client';
import ACTUATOR_MAINTENANCE_MODE from "./maintenanceModeMutation";

const style = {
  ON: {
    backgroundColor: "#00B630",
    color: "white",
    borderRadius: "50%",
    fontSize: "10px",
  },
  OFF: {
    backgroundColor: "#FF3F3F",
    color: "white",
    fontSize: "10px",
    borderRadius: "50%",
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
  },
  textBox: {
    marginLeft: 5,
    fontSize: "15px"
  }
};

const typeIcons = {
  "pulse":"➿",
  "hold":"➰",
  "keep":"〰️",
}

const ActuatorStatus = ({
  automationStatus,
  status,
  actuatorName,
  id,
  type
}) => {
  let isMaintenance = automationStatus && automationStatus.currentAutomationStatus && automationStatus.currentAutomationStatus.status === "MAINTENANCE";  
  const [setMaintenanceMode, { data, loading, error }] = useMutation(ACTUATOR_MAINTENANCE_MODE, {client: httpClient});
  return (
  <div style={style.flexBox}>
    <div>
      {(type === 'hold') && <Button
          className="btn-icon btn-outline"
          style={status ? style.ON : style.OFF}
          onMouseLeave={() => setMaintenanceMode({ variables: { actuatorId: id, status: false } })}
          onMouseDown={() => setMaintenanceMode({ variables: { actuatorId: id, status: true } })}
          onMouseUp={() => setMaintenanceMode({ variables: { actuatorId: id, status: false } })}
        >
          {status ? "ON" : "OFF"}
      </Button>}
      {(type !== 'hold') && <Button
          className="btn-icon btn-outline"
          style={status ? style.ON : style.OFF}
          onClick={() => setMaintenanceMode({ variables: { actuatorId: id, status: true } })}
        >
          {status ? "ON" : "OFF"}
      </Button>}
    </div>
    <div>
      <h5 className="text-justify align-center" style={style.textBox}>
        {isMaintenance ? `${actuatorName} ${typeIcons[type]}` : actuatorName}
      </h5>
    </div>
  </div>
  );
};

export default ActuatorStatus;
