import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center,;
  border-top: 1px solid #8798AD;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 15px;
  padding: 20px;
`;

export const OperatorCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: #F6F8FF;
  border-radius: 10px;
  width: 210px;
  padding: 20px;
  margin-top: 40px;
`;

export const Title = styled.p`
  color: #283745;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
`;

export const Info = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Operator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Photo = styled.img`
  border-radius: 50%;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  border: 2px solid #0e77d2;
  padding: 1px;
`;

export const Name = styled.p`
  color: #283745;
  font-size: 14px;
  font-weight: 500;
`;
