import React from "react";
import styled from "styled-components";

const Title = styled.h3`
  font-family: "Rubik";
  font-size: 13px;
  font-weight: 500;
  color: #2E384D;
  margin-bottom: 17px;
`;

const Container = styled.div`
  width: ${props => props.width};
`;

class Field extends React.Component {
  render() {
    return (
      <Container width={this.props.width} {...this.props}>
        <Title>{this.props.title}</Title>
        {this.props.children}
      </Container>
    );
  }
}

export default Field;
