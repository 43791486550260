import React, { Component } from "react";
import Table from "components/Table/Table";
import { withQuery } from "containers/withQuery";
import PencilButton from "components/ActionButtons/PencilButton";
import { CEMENT_TYPES_QUERY } from "../generalRegisterQueries";
import CementTypeEdit from "./CementTypeEdit";

const headers = [
  {
    label: "Nome", key: "name",
  },
  {
    label: "", key: "action"
  }
];


class CementTypesTable extends Component {
  state = {
    currentEditId: null,
  }

  onFinishEdit = () => this.setState({ currentEditId: null });

  render() {
    const { currentEditId } = this.state;
    return (
      <React.Fragment>
        <Table
          maxHeight={400}
          headers={headers}
          matchHeaders
          items={this.props.cementTypes.map(cementType => ({
            ...cementType,
            action: (
              <PencilButton
                onPencilEditClick={() => {
                  this.setState({
                    currentEditId: cementType.id
                  });
                }}
              />
            )
          }))}
        />
        {currentEditId !== null && (
          <CementTypeEdit id={currentEditId} onFinishEdit={this.onFinishEdit} />
        )}
      </React.Fragment>

    );
  }
}

export default withQuery({ query: CEMENT_TYPES_QUERY })(CementTypesTable);
