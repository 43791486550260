
import { format, parse } from "date-fns";

export const dateMask = "DD/MM/YYYY";

export const timeMask = "HH:mm";

export const dateTimeMask = `${dateMask} ${timeMask}`;

/**
 * Safely formats a date with a mask.
 * @param date the date.
 * @param mask the mask.
 * @param fallback fallback return value if date is invalid.
 */
export const formatDate = (
  date,
  mask = dateTimeMask,
  fallback = "-",
) => {
  if (!date) {
    return fallback;
  }

  const parsedDate = (
    date && typeof date === "string"
      ? parse(date)
      : new Date(date)
  );

  return format(parsedDate, mask);
};
