import React from "react";
import { createBrowserHistory } from "history";
import {
  Router, Route, Switch, Redirect
} from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.jsx";
import Login from "views/Login/Login.jsx";
import PrivateRoute from "utils/PrivateRoute.jsx";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";
import TruckStateContainer from "contexts/TruckContext/TruckStateContainer.jsx";
import SnackbarContainer from "contexts/SnackbarContext/SnackbarContainer.jsx";
import { hot } from "react-hot-loader";
import { withERP } from "utils/withERP";
import withAuthentication from "./utils/withAuthentication.jsx";

const hist = createBrowserHistory();

const Layouts = withERP((props) => (
  <Switch>
    <Route path="/admin" render={renderProps => <AdminLayout {...renderProps} />} />
    <Route
      path="/"
      render={() => (
        props.isERPAvaiable
          ? <Redirect to="/admin/dashboard" />
          : <Redirect to="/admin/instruments_panel" />
      )}
    />
  </Switch>
));

const AuthenticatedRoute = withAuthentication(props => (
  <Switch>
    <Route path="/login" component={Login} />
    <PrivateRoute path="/" component={Layouts} {...props} />
  </Switch>
));

const App = () => (
  <SnackbarContainer>
    <Router history={hist}>
      <TruckStateContainer>
        <AuthenticatedRoute />
      </TruckStateContainer>
    </Router>
  </SnackbarContainer>
);

export default hot(module)(App);
