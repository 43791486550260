export const getFamilyMetadata = (concreteFamily, concreteFamiliesOptions) => ([
  {
    id: "concreteFamily",
    title: "Família (Slump)",
    value: concreteFamily,
    isEditable: true,
    type: "select",
    options: concreteFamiliesOptions
  },
  {
    id: "familyUse",
    title: "Uso",
    value: concreteFamily.use || "-",
    isEditable: false
  },
  {
    id: "cementName",
    title: "Cimento",
    value: concreteFamily.cementType ? concreteFamily.cementType.name : "-",
    isEditable: false,
  },
  {
    id: "gravelType",
    title: "Brita",
    value: concreteFamily.gravelType || "-",
    isEditable: false
  }
]);

export const getForceMetadata = (fck, fcj, fctmk, consumption) => ([
  {
    id: "fck",
    title: "FCK",
    value: fck,
    isEditable: true,
    isValid: value => value >= 0 && value <= 200
  },
  {
    id: "fcj",
    title: "FCJ",
    value: fcj,
    isEditable: true,
    isValid: value => value >= 0 && value <= 1000
  },
  {
    id: "fctmk",
    title: "FCTMK",
    value: fctmk,
    isEditable: true,
    isValid: value => value >= 0 && value <= 200
  },
  {
    id: "consumption",
    title: "Consumo",
    value: consumption,
    isEditable: false,
  }
]);

export const getMaterialsMetadata = (
  mortarFactor,
  wcRatio,
  aggressivenessIndex,
  incorporatedAir
) => ([
  {
    id: "mortarFactor",
    title: "Teor de argamassa",
    value: mortarFactor,
    isEditable: false,
    formatter: (value) => parseFloat(value).toFixed(2),
  },
  {
    id: "wcRatio",
    title: "Fator A/C",
    value: wcRatio,
    isEditable: false,
    formatter: (value) => parseFloat(value).toFixed(2),
  },
  {
    id: "aggressivenessIndex",
    title: "Agressividade",
    value: aggressivenessIndex,
    isEditable: true,
  },
  {
    id: "incorporatedAir",
    title: "Ar Incorporado",
    value: incorporatedAir,
    isEditable: true,
  }
]);
