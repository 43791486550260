/**
 * Returns whether a value is blank or not.
 *
 * Values are considered as blank if they are one of:
 *  - null
 *  - undefined
 *  - ""
 *
 * `false` and `0` should be considered valid values.
 *
 * @param {*} value
 */

const blankValues = [null, undefined, ""];

const isBlank = (value) => {
  const parsedValue = typeof value === "string" ? value.trim() : value;

  return blankValues.includes(parsedValue);
};

export default isBlank;
