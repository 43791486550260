import React from "react";
import Table from "components/Table/Table";
import { withQuery } from "containers/withQuery";
import styled from "styled-components";
import { compose } from "react-apollo";
import { httpClient } from "settings/apolloClient";
import { withConcreteFormulaIdsConsumer } from "contexts/ConcreateFormulaIdsContext/ConcreteFormulaIdsContext";
import { CONCRETE_FORMULAS_QUERY } from "./FamiliesAndFormulasQueries";
import { SUPPLIERS_QUERY } from "./ConcreteTracesModal/concreteFormulaQueries.js";
import CardHeader from "./CardHeader";
import ConcreteTracesModal from "./ConcreteTracesModal/ConcreteTracesModal.jsx";

const Icon = styled.div`
  cursor: pointer;
  font-size: 30px;
  &:onhover {
    text-shadow: 2px 2px red;
  }
`;


const headers = [
  {
    label: "Código", key: "code",
  },
  {
    label: "FCK", key: "fck",
  },
  {
    label: "Uso", key: "use",
  },
  {
    label: "Consumo", key: "consumption",
  },
  {
    label: "Fator A/C", key: "wcRatio",
  },
  {
    label: "Versão", key: "version",
  },
  {
    label: "Preço / m³", key: "price",
  },
  {
    label: "", key: "button",
  }
];


export const renderTag = ({
  id,
  name,
}) => (
  <div
    key={id}
    style={{
      backgroundColor: "#1D84C6",
      color: "white",
      borderRadius: "20px",
      marginRight: "15px",
      marginLeft: "10px",
      padding: "5px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "80px"
    }}
  >
    {name}
  </div>
);

class FormulasTable extends React.Component {
  state = {
    filterText: "",
    modalOpen: false,
    selected: null,
  }

  componentDidMount () {
    const { refetch } = this.props;
    this.props.concreteFormulaIdsContext.setRefetch(refetch);
    httpClient.query({
      query: SUPPLIERS_QUERY,
    }).then(({ data }) => {
      this.setState({ suppliers: data.suppliers });
    });
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.concreteFamiliesSelected !== this.props.concreteFamiliesSelected) {
      this.props.concreteFormulaIdsContext.setRefetch(nextProps.refetch);
    }
  }

  componentWillUnmount () {
    this.props.concreteFormulaIdsContext.resetState();
  }

  mapDataToTable = concreteFormulas => concreteFormulas.map(({
    id, code, fck, use, consumption, wcRatio, version, price
  }) => ({
    id,
    code,
    fck,
    use,
    consumption,
    wcRatio: parseFloat(wcRatio).toFixed(2),
    version,
    price: parseFloat(price).toFixed(2),
    button: (
      <Icon className="nc-icon nc-single-copy-04" onClick={() => this.selectModalTrace(id)} />
    )
  }));

  onChangeFamilyValue = e => this.setState(({ filterText: e.target.value || "" }));

  closeModalHandler = () => this.setState({ modalOpen: false, selected: null });

  selectModalTrace = id => this.setState({ modalOpen: true, selected: id })

  render() {
    const { filterText, selected, modalOpen } = this.state;
    const { concreteFormulas, concreteFamiliesSelected } = this.props;

    const dataTable = this.mapDataToTable(concreteFormulas);
    const items = dataTable
      .filter((row) => (
        Object.values(row).some(data => data.toString().match(RegExp(filterText, "i")))
      ));
    const tags = concreteFamiliesSelected.map(renderTag);

    return (
      <React.Fragment>
        {
          (selected) && (
            <ConcreteTracesModal
              id={selected}
              concreteFamilyIds={concreteFamiliesSelected.map(({ id }) => id)}
              closeModalHandler={this.closeModalHandler}
              modalOpen={modalOpen}
              suppliers={this.state.suppliers}
            />
          )
        }
        <CardHeader title="Traços" tags={tags} onChange={this.onChangeFamilyValue} />
        <Table tableName="formulas" headers={headers} matchHeaders items={items} />
      </React.Fragment>
    );
  }
}

export default compose(
  withConcreteFormulaIdsConsumer,
  withQuery(({ concreteFamiliesSelected }) => ({
    query: CONCRETE_FORMULAS_QUERY,
    variables: {
      concreteFamilyIds: concreteFamiliesSelected.map(({ id }) => id)
    },
    fetchPolicy: "network-only",
  })),
)(FormulasTable);
