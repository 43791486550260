import React from "react";

import styled from "styled-components";
import WaterSlider from "./WaterSlider";
import Info from "./Info";
import InfoHumidity from "./InfoHumidity";

const SliderAndInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
`;

const Title = styled.h3`
  font-family: "Rubik";
  font-size: 20px;
  font-weight: 500;
`;

const WaterQuantity = ({
  coefficient, onChangeSlider, waterQuantity, sands
}) => (
  <div style={{ padding: "30px", borderTop: "2px solid #F6F8FF", borderBottom: "2px solid #F6F8FF" }}>
    { waterQuantity > 0 &&(
      <React.Fragment>
        <Title>Quantidade de Água</Title>

        <SliderAndInfoContainer>
          <WaterSlider waterValue={coefficient} onChangeSlider={onChangeSlider} />
          <Info sliderValue={coefficient} waterQuantity={waterQuantity} />
        </SliderAndInfoContainer>
      </React.Fragment>
    )}

    {(Boolean(sands) && sands.length > 0) && sands.map(({ inventoryItem }) => (
      <InfoHumidity
        key={inventoryItem.id}
        sandType={inventoryItem.name}
        value={inventoryItem.lastHumidityMeasurement.value}
      />
    ))}
  </div>
);

export default WaterQuantity;
