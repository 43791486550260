import gql from "graphql-tag";

export const CREATE_CONCRETE_SHIPMENT_MUTATION = gql`
  mutation(
    $params: ConcreteShipmentParams!,
    $operatorIds: [Int]
  ) {
    createConcreteShipment(
      params: $params,
      operatorIds: $operatorIds
    ) {
      concreteShipment {
        id
      }
      invoiceRequestError
    }
  }
`;

export const CREATE_MULTIPLE_CONCRETE_SHIPMENTS_MUTATION = gql`
  mutation(
    $params: MultipleConcreteShipmentParams!,
  ) {
    createMultipleConcreteShipments(
      params: $params
    ) {
      id
    }
  }
`;

export const UPDATE_CONCRETE_SHIPMENT_MUTATION = gql`
  mutation(
    $id: Int!,
    $params: ConcreteShipmentParams!
  ) {
    updateConcreteShipment(
      id: $id,
      params: $params
    ) {
      id
    }
  }
`;
