import React from "react";
import styled from "styled-components";
import {
  InputGroup, InputGroupAddon, InputGroupText, Input
} from "reactstrap";
import TagsContainer from "./TagsContainer";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #F4F5FC;
  padding: 20px 32px 20px 32px;
`;

const Title = styled.h2`
  font-family: "Rubik";
  font-size: 20px;
  font-weight: 500;
  color: #2E384D;
  width: 10%;
`;

const CardHeader = ({ title, tags, onChange }) => (
  <Container>
    <Title>{title}</Title>
    {tags && (
      <TagsContainer>
        {tags}
      </TagsContainer>
    )}
    <InputGroup className="no-border" style={{ width: "214px", marginTop: "auto", marginBottom: "auto" }}>
      <Input defaultValue="" onChange={onChange} type="text" />
      <InputGroupAddon addonType="append">
        <InputGroupText>
          <i className="nc-icon nc-zoom-split" />
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  </Container>
);

export default CardHeader;
