import React from "react";
import styled from "styled-components";
import Select from "react-select";
import { Input as BSInput } from "reactstrap";
import {
  InfoText,
} from "./ConcreteTracesModalStyle.jsx";

const Header = styled.h3`
  margin-bottom: 5px;
`;

const Input = styled(BSInput)`
  &.form-control {
    color: #8798AD;
    font-family: 'Rubik';
    min-height: 40px;
  }
  &.form-control:focus {
    border: 1px solid #1D84C6;
  }
`;

class GenericInput extends React.Component {
  onChangeValue = e => {
    const { value } = e.target;
    if (!this.props.isValid || this.props.isValid(value)) {
      this.props.onChange(this.props.id, value);
    }
  }

  render() {
    const {
      id, title, isEditable, value, formatter, type, options,
    } = this.props;
    return (
      <React.Fragment>
        <Header>{title}</Header>
        {isEditable ? (
          <React.Fragment>
            {type === "select" && (
              <Select
                styles={{
                  valueContainer: () => ({
                    width: 250,
                  }),
                }}
                className="react-select primary"
                classNamePrefix="react-select"
                name="itemSelected"
                value={value}
                onChange={item => this.props.onChange(id, item)
                }
                options={[
                  {
                    value: "",
                    isDisabled: true
                  },
                  ...options
                ]}
                placeholder=""
              />
            )}
            {type === undefined && (
              <Input value={value} onChange={isEditable ? this.onChangeValue : undefined} />
            )}
          </React.Fragment>
        ) : (
          <InfoText>
            {formatter ? (
              formatter(value)
            ) : (
              <React.Fragment>
                {type === "select" ? value.label : value}
              </React.Fragment>
            )}
          </InfoText>
        )}
      </React.Fragment>
    );
  }
}

export default GenericInput;
