import React, { Component } from "react";
import MaterialProgress from "components/MaterialProgress/MaterialProgress";
import { Subscription } from "react-apollo";

import { PlantItem } from "components/StyledComponents";

import SUBSCRIBE_TO_ITEM_SHIPMENT_QUANTITY from "../graphql/subscriptions/subscribeToItemShipmentQuantity.js";

class MultipleProgressSubscriptionWrapper extends Component {
  render() {
    const {
      concreteShipmentId,
      onSubscriptionData,
      inventoryItems,
    } = this.props;
    return (
      <React.Fragment>
        {
          inventoryItems.map(item => (
            <React.Fragment key={item.id}>
              <Subscription
                fetchPolicy="no-cache"
                onSubscriptionData={({ subscriptionData: { data } }) => onSubscriptionData(item.id, data)}
                subscription={SUBSCRIBE_TO_ITEM_SHIPMENT_QUANTITY}
                variables={{
                  concreteShipmentId,
                  inventoryItemId: item.id,
                }}
              >
                {({ data }) => (
                  <PlantItem>
                    <MaterialProgress
                      small
                      title={item.name}
                      value={
                        data
                          ? data.subscribeToItemShipmentQuantity
                          : item
                            ? item.itemShipmentQuantity
                            : 0
                      }
                      total={item.shipmentMaterial ? item.shipmentMaterial.formulaWeight : 0}
                    />
                  </PlantItem>
                )}
              </Subscription>
            </React.Fragment>
          ))
        }
      </React.Fragment>
    );
  }
}

export default MultipleProgressSubscriptionWrapper;
