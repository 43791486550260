import React from "react";
import styled from "styled-components";
import {
  Card, Row, Col, Container
} from "reactstrap";
import SlumpsTable from "./Slump/SlumpsTable";
import HeaderTable from "./modalComponents/HeaderTable";
import CementTypesTable from "./Cement/CementTypesTable";
import SuppliersTable from "./Supplier/SuppliersTable";
import SupplierCreate from "./Supplier/SupplierCreate";
import InventoryItemsTable from "./InventoryItem/InventoryItemsTable";
import InventoryItemCreate from "./InventoryItem/InventoryItemCreate";
import CementTypeCreate from "./Cement/CementTypeCreate";
import SlumpCreate from "./Slump/SlumpCreate";
import LocationCreate from "./Location/LocationCreate";
import LocationTable from "./Location/LocationTable";

const CardContainer = styled(Card)`
  width: 100%;
`;

const SLUMP_MODAL = "slump";
const CEMENT_MODAL = "cement";
const SUPPLIER_MODAL = "supplier";
const LOCATION_MODAL = "location";
const INVENTORY_ITEM_MODAL = "inventoryItem";

const modalComponent = {
  [SLUMP_MODAL]: SlumpCreate,
  [CEMENT_MODAL]: CementTypeCreate,
  [SUPPLIER_MODAL]: SupplierCreate,
  [LOCATION_MODAL]: LocationCreate,
  [INVENTORY_ITEM_MODAL]: InventoryItemCreate,
};

class GeneralRegister extends React.Component {
  state = {
    modal: null
  }

  openModal = modal => () => this.setState({ modal })

  closeModal = () => this.setState({ modal: null })

  render() {
    const Modal = modalComponent[this.state.modal];

    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col sm={12} md={6}>
              <CardContainer>
                <HeaderTable title="Slump" buttonLabel="Cadastrar" onClick={this.openModal(SLUMP_MODAL)} />
                <SlumpsTable />
              </CardContainer>
            </Col>
            <Col sm={12} md={6}>
              <CardContainer>
                <HeaderTable title="Tipos de Cimento" buttonLabel="Cadastrar" onClick={this.openModal(CEMENT_MODAL)} />
                <CementTypesTable />
              </CardContainer>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <CardContainer>
                <HeaderTable title="Fornecedores" buttonLabel="Cadastrar" onClick={this.openModal(SUPPLIER_MODAL)} />
                <SuppliersTable onEdit={() => this.openModal(SUPPLIER_MODAL)} />
              </CardContainer>
            </Col>
            <Col sm={12} md={6}>
              <CardContainer>
                <HeaderTable title="Locais de estoque" buttonLabel="Cadastrar" onClick={this.openModal(LOCATION_MODAL)} />
                <LocationTable onEdit={() => this.openModal(LOCATION_MODAL)} />
              </CardContainer>
            </Col>
            <Col sm={12} md={12}>
              <CardContainer>
                <HeaderTable title="Itens de Inventário" buttonLabel="Cadastrar" onClick={this.openModal(INVENTORY_ITEM_MODAL)} />
                <InventoryItemsTable />
              </CardContainer>
            </Col>
          </Row>
          {this.state.modal && (
            <Modal isOpen toggleModal={this.closeModal} />
          )}
        </Container>
      </div>
    );
  }
}

export default GeneralRegister;
