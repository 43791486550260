const firstDayOfMonth = () => {
  const dateToday = new Date();
  return new Date(dateToday.getFullYear(), dateToday.getMonth(), 1);
};

const firstDayOfNextMonth = () => {
  const dateToday = new Date();
  return new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 1);
};

export {
  firstDayOfMonth,
  firstDayOfNextMonth
};
