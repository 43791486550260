import React from "react";
import styled from "styled-components";
import SpinnerSpinkit from "react-spinkit";

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  padding: 20px;
  height: 100%;
`;

const Spinner = () => (
  <SpinnerContainer>
    <SpinnerSpinkit name="line-spin-fade-loader" color="green" />
  </SpinnerContainer>
);

export default Spinner;
