import gql from "graphql-tag";

export const INVENTORY_ITEMS_QUERY = gql`
  query {
    inventoryItems {
      id
      name
      unit
      inventoryQuantity
      materialType
      inventoryLocations{
        id
        name
        inventoryQuantity
      }
    }
  }
`;

export const INVENTORY_ENTRIES_QUERY = gql`
  query {
    inventoryEntries {
      id
      humidity
      supplier {
        id
        name
      }
      nfNumber
      competenceDate
      inventoryChange {
        quantity
        inventoryLocation {
          id
          name
        }
      }
      inventoryItem {
        id
        name
        unit
        materialType
      }
      productValue
      shipmentValue
      totalValue
    }
  }
`;

export const DELETE_INVENTORY_ENTRY_MUTATION = gql`
  mutation($id: Int!) {
    deleteInventoryEntry(id: $id) {
      id
    }
  }
`;
