import gql from "graphql-tag";

export default gql`
query PlantLocationsDevice($concreteShipmentId: Int!) {
    devices(active: true) {
    id
    plantImagePath
    plantLocations{
      id
      locationType
      name
      positionX
      positionY
      numberOfColumns
      isContainerVertical
      arrowPosition
      actuators {
        id
        name
        status
        type
      }
      inventoryItems {
        id
        materialType
        name
        itemShipmentQuantity(concreteShipmentId: $concreteShipmentId)
        shipmentMaterial(concreteShipmentId: $concreteShipmentId) {
          formulaWeight
        }
      }
    }
  }
}
`;
