import React, { Component } from "react";
import MaterialProgress from "components/MaterialProgress/MaterialProgress";
import { Subscription, compose } from "react-apollo";
import { withQuery } from "containers/withQuery.jsx";
import { PlantItem } from "components/StyledComponents";
import SUBSCRIBE_TO_ITEM_SHIPMENT_QUANTITY from "../graphql/subscriptions/subscribeToItemShipmentQuantity.js";
import INVENTORY_ITEM_CONCRETE_SHIPMENT_QUERY from "../graphql/queries/inventoryItemConcreteShipmentQuery.js";


class ProgressSubscriptionWrapper extends Component {
  render() {
    const {
      concreteShipmentId,
      inventoryItem,
    } = this.props;

    return (
      <Subscription
        subscription={SUBSCRIBE_TO_ITEM_SHIPMENT_QUANTITY}
        fetchPolicy="no-cache"
        variables={{
          concreteShipmentId,
          inventoryItemId: inventoryItem.id,
        }}
      >
        {({ data }) => (
          <PlantItem>
            <MaterialProgress
              title={inventoryItem.name}
              value={
                data
                  ? data.subscribeToItemShipmentQuantity
                  : inventoryItem
                    ? inventoryItem.itemShipmentQuantity
                    : 0
              }
              total={inventoryItem.shipmentMaterial ? inventoryItem.shipmentMaterial.formulaWeight : 0}
            />
          </PlantItem>
        )}
      </Subscription>
    );
  }
}

export default compose(
  withQuery(({ materials, concreteShipmentId }) => ({
    query: INVENTORY_ITEM_CONCRETE_SHIPMENT_QUERY,
    variables: {
      id: materials && materials.length && materials[0].inventoryItemId,
      concreteShipmentId
    },
    fetchPolicy: "no-cache",
    skip: !(materials && materials.length > 0)
  })),
)(ProgressSubscriptionWrapper);
