import React from "react";
import { Col, Row } from "reactstrap";

import { withQuery } from "containers/withQuery";

import { INVENTORY_ITEMS_QUERY } from "./stockMaterialsQueries";
import InventoryItem from "./InventoryItem";

class InventoryItems extends React.Component {
  renderInventoryItem = (inventoryItem) => (
    <Col
      key={inventoryItem.id}
      xl="3"
      lg="4"
      md="6"
      sm="12"
      align="center"
    >
      <InventoryItem {...inventoryItem} />
    </Col>
  );

  render() {
    const { inventoryItems } = this.props;
    return (
      <Row>
        {inventoryItems
          .filter(({ materialType }) => materialType !== "WATER")
          .map(this.renderInventoryItem)}
      </Row>
    );
  }
}

export default withQuery({
  query: INVENTORY_ITEMS_QUERY,
})(InventoryItems);
