import React from "react";
import "./Touchable.css";

class Touchable extends React.Component {
  state = {
    touched: false
  }

  toggleTouched = () => {
    this.setState(prevState => ({
      touched: !prevState.touched
    }));
  }

  handleMouseUp = () => {
    setTimeout(() => {
      this.setState({ touched: false });
    }, 150);
  }

  render () {
    const { touched } = this.state;
    const className = touched ? "touchable touched" : "touchable";
    return (
      <div
        className={className}
        onMouseDown={this.toggleTouched}
        onMouseUp={this.handleMouseUp}
        {...this.props}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Touchable;
